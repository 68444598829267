import React from "react";
import styled from "styled-components";
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendCrmData, sendEnquiryUrl } from '../../../axios/apiFunctions';
import CircularProgress from "@mui/material/CircularProgress";


import home from "../../../assets/images/icons/home-icon-contact.svg";
import call from "../../../assets/images/icons/call-icon-contact.svg";
import msg from "../../../assets/images/icons/msg-icon-contact.svg";
import { quickEnquiry } from "../../../axios/api";
import ReCAPTCHA from "react-google-recaptcha";

function ContactInfo({ data }) {
  const currentUrl = window.location.href;

  const [captchaToken, setCaptchaToken] = useState(null);
  const [formdata, setformdata] = useState({url:currentUrl})
  const [error, seterror] = useState(false);
  const [loader, setloader] = useState(false);
  const navigate = useNavigate()

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };
  const handleChange = (e) => {
    seterror(false)
    const { name, value } = e.target
    setformdata({
      ...formdata,
      [name]: value
    })
  };

  const handleSubmit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneNumberRegex = /^\d/;

    if (!formdata?.name || formdata?.name.length < 3) {
      seterror('Enter a vaild name')
    } else if (!formdata?.email || !emailRegex.test(formdata?.email)) {
      seterror('Enter a vaild email')
    } else if (!formdata?.phone || !phoneNumberRegex.test(formdata?.phone)) {
      seterror('Enter a vaild phone number')
    }
    else if (!formdata?.message|| formdata?.message.length < 10) {
      seterror('message must be at least 10 characters')
    } else if (captchaToken === "" || captchaToken === null) {
      seterror('Please verify your captcha')
    }
    else {
      setloader(true)
      sendCrmData(formdata)
      axios.post(quickEnquiry, formdata)
        .then((response) => {
          setloader(false);
          const { StatusCode, data } = response.data.app_data;
          if (StatusCode === 6000) {
            setformdata({})
            navigate('/thank-you')
          } else {
            seterror(data.message);
            setTimeout(() => {
              seterror(false)
            }, 2000);
          }
        })
    }
  };

  return (
    <Section>
      <Container>
        <SideHeading>
          <h4>CONTACT</h4>
        </SideHeading>
        <InfoSection>
          <ContactDetails>
            <Heading>
              {data?.title}
            </Heading>
            <Subpara>
              {data?.content}
            </Subpara>
            <OtherDetail>
              <PhoneNumber>
                <Icon>
                  <img src={call} alt="" />
                </Icon>
                <p>+91 {data?.phone} |{data?.phone2}</p>
              </PhoneNumber>
              <Email>
                <Icon>
                  <img src={msg} alt="" />
                </Icon>
                <a>{data?.email}</a>
              </Email>
              <Address>
                <Icon>
                  <img src={home} alt="" />
                </Icon>
                <p>{data?.address}</p>
              </Address>
            </OtherDetail>
          </ContactDetails>
          <ContactForm>
            {
              error && <Error>{error}</Error>
            }
            <Feilds>
              <FirstRow>
                <input value={formdata?.phone} name="phone" onChange={(e) => handleChange(e)} type="text" placeholder="Mobile" />
              </FirstRow>
              <SecondRow>
                <FirstSide>
                  <input type="text" placeholder="Name" value={formdata?.name} name="name" onChange={(e) => handleChange(e)} />
                  <input type="text" placeholder="Email" value={formdata?.email} name="email" onChange={(e) => handleChange(e)} />
                </FirstSide>
                <SecondSide>
                  <textarea
                    placeholder="Message"
                    name="message"
                    id=""
                    rows={3.7}
                    onChange={(e) => handleChange(e)}
                  ></textarea>
                </SecondSide>
              </SecondRow>
              <Capta>
                  <ReCAPTCHA
                    sitekey='6LeDwAIqAAAAAMjjFFFklc_7bXcUBPJIkIQEvUSM'
                    onChange={handleCaptchaChange}
                  />
                </Capta>
              <SubmitButtonContainer>
                <button onClick={handleSubmit}> {loader ? <CircularProgress /> : "Submit"}</button>
              </SubmitButtonContainer>
            </Feilds>
          </ContactForm>
        </InfoSection>
      </Container>
    </Section>
  );
}

export default ContactInfo;

const Section = styled.div`
  padding: 20px 0px;
`;

const Container = styled.div`
  display: flex;
  width: var(--section-width-lg);
  margin: 0 auto;
  gap:60px;
  @media (max-width: 968px){
    flex-direction: column;
    gap: 0;
  }
`;

const SideHeading = styled.div`
  width: 1%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  @media (max-width: 968px) {
    width: 100%;
    justify-content: flex-start;
  }
  h4 {
    transform: rotate(180deg);
    font-size: 14px;
    color: var(--secondary-cl);
    font-family: "popins_regular";
    writing-mode: vertical-lr;
    width: 25vh;
    position: relative;
    top: 15px;
    @media (min-width: 1024px) and (max-width: 1440px) {
      top: 40px;
    }
    @media (max-width: 968px) {
      transform: rotate(0deg);
      position: unset;
      width: 50%;
      writing-mode: horizontal-tb;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 1px;
      bottom: 80px;
      left: 50%;
      height: 80px;
      background-color: var(--secondary-cl);
      @media (max-width: 968px) {
        left: 80px;
        width: 100px;
        height: 1px;
        bottom: 50%;
      }
    }
  }
`;
const InfoSection = styled.div`
  padding: 20px 0px;
  width: 99%;
  display: flex;
  gap:20px;
  @media (max-width: 1400px) {
    padding: 0;
  }
  @media (max-width: 968px) {
    width: 100%;
    padding: 0px 0px;
  }
  @media (max-width: 800px) {
    flex-direction:column;
    gap: 15px;
  }
  @media (max-width: 580px) {
    padding: 20px 0px;
  }
`;

const ContactDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: 800px) {
    width:100%;
  }
`;

const ContactForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: 800px) {
    width:100%;
  }
`;

const Heading = styled.h3`
  font-family: "popins_semibold";
  color: var(--primary-cl);
  @media (max-width: 800px) {
    font-size: 24px;
  }
  @media (max-width: 425px) {
    width: 22ch;
  }
  @media (max-width: 375px) {
    width: 24ch;
    font-size: 18px;
  }
  @media (max-width: 315px) {
    width: 18ch;
    font-size: 21px;
  }
`;

const Subpara = styled.p`
  font-family: "popins_regular";
  margin: 25px auto 14px auto;
  @media (max-width: 580px) {
    text-align: justify;
    font-size: 14px;
    margin: 12px auto 12px auto;

  }
`;

const OtherDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media(max-width:1800px){
    margin-top: 10px;
  }
`;

const PhoneNumber = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  @media (max-width: 300px){
    flex-direction: column;
  }
  p {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: "popins_regular";
    @media (max-width: 968px){
    font-size: 15px;
    }
    @media (max-width: 375px){
      font-size: 14px;
    }
  }
`;

const Email = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  @media (max-width: 300px){
    flex-direction: column;
  }
  a {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: "popins_regular";
    @media (max-width: 968px){
    font-size: 15px;
    }
    @media (max-width: 375px){
      font-size: 14px;
    }
  }
`;

const Address = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  @media (max-width: 300px){
    flex-direction: column;
  }
  p {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: "popins_regular";

    @media (max-width: 968px){
      font-size: 15px;
    }

    @media (max-width: 375px){
      width: 21ch;
      font-size: 14px;
    }
    
    @media (max-width: 300px){
      width: 100%;
      font-size: 14px;
      text-align: center;
    }
  }
`;

const Icon = styled.div`
  width: 30px;
  img {
    width: 100%;
    display: block;
  }
`;

const Feilds = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  @media (max-width: 968px){
    width: 100%;
  }
  @media (max-width: 580px) {
    width: 100%;
    margin: 0 auto;
  }
`;

const SecondRow = styled.div`
  display: flex;
  flex-direction: column;
  div {
    margin: 0px 10px;
    @media (max-width: 800px) {
    margin: 0;
    }
  }
`;
const FirstRow = styled.div`
  display: flex;
  margin: 5px;
  padding: 5px;
  flex-direction: column;
  width: 102%;
  gap: 10px;
  @media(min-width:2250px){
      width:101%;
      gap: 15px;
  }
  @media (max-width: 800px) {
    width:100%;
    margin: 5px 0;
    padding: 5px 0;
  }
  select {
    width: 100%;
    border: 1px solid var(--secondary-cl);
    padding: 8px;
    outline: none;
    color: var(--secondary-cl);
    font-size: 13px;
    font-family: "popins_regular";
  }
  input {
    width: 100%;
    border: 1px solid var(--secondary-cl);
    padding: 8px;
    outline: none;
    &::placeholder {
      color: var(--secondary-cl);
      font-size: 13px;
      font-family: "popins_regular";
    }
  }
`;

const FirstSide = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  input {
    width: 100%;
    margin: 5px 0px;
    border: 1px solid var(--secondary-cl);
    padding: 8px;
    outline: none;
    &::placeholder {
      color: var(--secondary-cl);
      font-size: 13px;
      font-family: "popins_regular";
    }
  }
`;

const SecondSide = styled.div`
  width: 100%;
  textarea {
    width: 100%;
    border: 1px solid var(--secondary-cl);
    padding: 8px;
    outline: none;
    height: 100px;
    &::placeholder {
      color: var(--secondary-cl);
      font-size: 13px;
      font-family: "popins_regular";
    }
  }
`;

const Capta = styled.div`
margin: 0px 10px;
@media (max-width:800px ){
  margin: 0px 0px;
}
`

const SubmitButtonContainer = styled.div`
  padding: 10px;
  @media (max-width: 800px) {
    padding: 10px 0;
  }
  button {
    background-color: var(--tertiary-cl);
    color: #fff;
    border: none;
    padding: 5px 30px;
    font-family: "popins_light";
    font-size: 16px;
    span{
            width: 25px !important;
            height: 25px !important;
            color: white;
          }
    @media (max-width: 968px){
      width: 50%;
    }
  }
`;


const Error = styled.p`
    margin:7px 0px;
    width: 100%;
    text-align: center;
    color:red;
`;
import React, { useEffect, useRef, useState } from 'react';
import { Parallax, ParallaxBanner, useParallax } from 'react-scroll-parallax';
import styled from 'styled-components';
import useScrollPercentage from '../../utils/useScrollPercentage';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import ScrollMagic from 'scrollmagic';
// import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';



import searchIcon from "../../../assets/images/topbar/search_icon.svg";
import herobanner from '../../../assets/images/banner/banner.jpg';
import logo from "../../../assets/images/header/skyline-hectares-logo.png";

gsap.registerPlugin(ScrollTrigger);




function FirstView() {
    const scrollPercentage = useScrollPercentage();
    const [Progress, setProgress] = useState(0);
    const [Entered, setEntered] = useState("heloo");
    const [Speed, setSpeed] = useState(true);

    const logoRef = useRef(null);
    const backgroundRef = useRef(null);
    const headingRef = useRef(null);
    const subHeadingRef = useRef(null);
    const searchBarRef = useRef(null);

    const divRef = useRef(null);
    
    const handleScroll = () => {
      
      setProgress(divRef.current.scrollTop)
    };

    useEffect(() => {
      window.scroll(0, 0);
   }, [])

    useEffect(() => {
       gsap.to(logoRef.current,{
        x: -400,
        width:"65%",
        position:"fixed",
        top:0,
        animation:"ease",
        scrollTrigger:{
          trigger:backgroundRef.current,
          start:'20% top',
          end:"80% center",
          markers:true,
          scrub:true,
          onUpdate: (self) => {
            const progress = self.progress; // Current progress of the scroll animation
            const logoElement = logoRef.current;
             
            // Hide the element between 50% and 75% progress
            // if (progress >= 0.4 && progress <= .75) {
            //   logoElement.style.display = "none";
            // } else {
            //   logoElement.style.display = "flex"; // Show the element otherwise
            // }
          }
        }
       })
       gsap.to(backgroundRef.current,{
        width:"80%",
        borderRadius:'20px',
        height:'650px',
        animation:"ease",
        duration:4,
        top:"130px",
        scrollTrigger:{
          trigger:backgroundRef.current,
          start:'5% top',
          end:"50% 10%",
          markers:true,
          scrub:true,
          onUpdate: (self) => {
            const progress = self.progress; // Current progress of the scroll animation
            const logoElement = logoRef.current;
      
            // Hide the element between 50% and 75% progress
            if (progress === 1) {
              backgroundRef.current.style.position = "relative";
              logoRef.current.style.position = "relative";
            }
          }
        }
       })
       gsap.to(searchBarRef.current,{
        width:"60%",
        animation:"ease",
        y:200,
        left:"285px",
        scrollTrigger:{
          trigger:backgroundRef.current,
          start:'5% top',
          end:"50% 10%",
          markers:true,
          scrub:true,
        }
       })
       gsap.to(headingRef.current,{
        width:"18ch",
        animation:"ease",
        textAlign:'left',
        left:"150px",
        bottom:"-385px",
        scrollTrigger:{
          trigger:backgroundRef.current,
          start:'5% top',
          end:"50% 10%",
          markers:true,
          scrub:true,
        }
       })
       gsap.to(subHeadingRef.current,{
        width:"40ch",
        animation:"ease",
        bottom:"-385px",
        left:"150px",
        textAlign:'left',
        scrollTrigger:{
          trigger:backgroundRef.current,
          start:'5% top',
          end:"50% 10%",
          markers:true,
          scrub:true,
        }
       })
    }, [])



    // useEffect(() => {
    //   const parentWidth = backgroundRef.current.parentElement.clientWidth;
    //   const elementWidth = backgroundRef.current.clientWidth;
    //   const widthPercentage = (elementWidth / parentWidth) * 100;
    //   if(widthPercentage>85){
    //     backgroundRef.current.style.width=`${widthPercentage-10}%`;
    //     backgroundRef.current.style.margin="0px auto";
    //   }
    // }, [Progress])
  
    return (
      <>
        <MainDiv className='sectionDiv' ref={divRef} onScroll={handleScroll}>
        <MainContainer ref={backgroundRef} >
            <LogoDiv ref={logoRef}  >
               <img src={logo} alt="" />
            </LogoDiv>
            <Heading1 ref={headingRef}>
                Invest in a Plot,Build Your Heritage.
            </Heading1>
            <Heading2 ref={subHeadingRef}>
                 Build a home where cherished memories will be made for generations to come.
            </Heading2>

            <SearchBar ref={searchBarRef} >
                <SearchInput>
                   <input type="text" placeholder="Search" /> 
                </SearchInput>
                <LocationDropdown>
                    <select placeholder='Location' name="" id="">
                        <option value="1">Kollam</option>
                        <option value="2">Kochi</option>
                        <option value="3">Kottayam</option>
                    </select>
                </LocationDropdown>
                <SearchSubmitButton>
                   <span>Find Plots</span>
                </SearchSubmitButton>
           </SearchBar>
        </MainContainer>
        </MainDiv>
        <TestDiv>

        </TestDiv>
        </>
    );
}

export default FirstView;


const MainDiv = styled.div`
 overflow: auto;
 height:300vh;
`

const MainContainer = styled.div`
   position: fixed;
   inset: 0;
   width: 100%;
   height: 100vh;
   background-image: url(${herobanner});
   background-size: cover;
   background-repeat: no-repeat;
   transition: width 200ms ease ;
   margin:auto;
   &::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #6262ffd1;
    display: none;
   }
`
const Indicator = styled.div`
      z-index: 5;
      position: sticky;
      top: 0;
      left: 0;
      background-color: #6262ffd1;
`;
const LogoDiv = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: transform 2s ease;
  img{
    display: block;
    width: 13%;
  }
`
const Heading1 = styled.h1`
   text-align: center;
   position: relative;
   bottom: -225px;
   z-index: 3;
   font-family: 'popins_bold';
   color: #fff;
`
const Heading2 = styled.p`
    text-align: center;
    bottom: -225px;
    position: relative;
    z-index: 3;
    font-family: 'popins_regular';
    color: #fff;   
`

const SearchBar = styled.div`
    z-index: 3;
    background-color: rgb(255, 255, 255);
    bottom: 225px;
    position: absolute;
    width: 40%;
    margin: 0px auto;
    padding:8px;
    border: 2px solid var(--secondary-cl);
    display: flex;
    border-radius: 12px;
    left: 450px;
    `
const SearchInput = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &::after{
    content: "";
    display: block;
    position: absolute;
    width: 17px;
    top: 7px;
    left: 25px;
    height: 100%;
    background-image: url(${searchIcon});
    margin: 10px 0px;
    background-repeat: no-repeat;
    color: var(--secondary-cl);
  }
  input{
    border: none;
    outline: none;
    margin: 0 40px;
    width: 100%;
  }
`
const LocationDropdown = styled.div`
    width: 20%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    select{
        width: max-content;
        outline: none;
        border: none;
        color: var(--secondary-cl);
    }
`
const SearchSubmitButton = styled.button`
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  background-color: var(--primary-cl);
  color: #fff;
  border: none;
  font-family: 'popins_regular';
`

const TestDiv = styled.div`
height: 100vh;
background-color: teal;
width: 100%;
`;
import React from "react";
import plan from "../../../assets/images/plan/Master_Plan.png";
import styled from "styled-components";
import {Tooltip as Tooltip }from "react-tooltip";
import club from "../../../assets/images/tooltip/club2hqout.jpg";
import tree from "../../../assets/images/tooltip/treecourtseatinghqout(1).jpg";
import lilly from "../../../assets/images/tooltip/lillypoolhqout.jpg";
import path from "../../../assets/images/tooltip/PATHWAYhqout.jpg";
import gym from "../../../assets/images/tooltip/GYMhqout.jpg";
import basketball from "../../../assets/images/tooltip/basketballhqout.jpg";
import pargola from "../../../assets/images/tooltip/pargolahqout.jpg";
import gate from "../../../assets/images/tooltip/banner-open.jpg";
import securityCabin from "../../../assets/images/tooltip/security-cabin.jpg";
import playFullMound from "../../../assets/images/tooltip/playfull-mound.jpg";
import steppingStone from "../../../assets/images/tooltip/stepping-stone.jpg";
import electricalRoom from "../../../assets/images/tooltip/electrical-room.jpg";
import playArea from "../../../assets/images/tooltip/playareahqout.jpg";
import { useEffect } from "react";
import { listMasterPlanArrow, listMasterPlanPoints } from "../../../axios/api";
import { useState } from "react";

import axios from 'axios';


function Plan({data}) {
  const [points, setPoints] = useState([]);
  const [arrowPoints, setarrowPoints] = useState(null);

  useEffect(() => {
    console.log(data,"----------------")
    
    axios.get(listMasterPlanPoints+data?.id)
      .then((response)=>{
       const { StatusCode,data}  = response.data.app_data;
       
       if(StatusCode === 6000){
          setPoints(data)
       }else if(StatusCode === 6001){
          setPoints([])
       }
      }).catch((error)=>{ 
      })
      
      if(data?.id){
        axios.get(listMasterPlanArrow+data?.id)
        .then((response)=>{
        const { StatusCode,data}  = response.data.app_data;
        
        if(StatusCode === 6000){
            
            const item = data[0];

            const tempData = {
                desktop_points:JSON.parse(item.desktop_points),
                tab_points:JSON.parse(item.tab_points),
                mobile_points:JSON.parse(item.mobile_points)
            }
            
            setarrowPoints(tempData);
        }else if(StatusCode === 6001){
            setarrowPoints(null)
        }
        }).catch((error)=>{ 
        })
      }
      

  }, [data])
  
  return (
    <Container id="masterplan">
      <Wrapper>
      <SubTitle>Explore</SubTitle>
        <Content>
          <Title>The Master Plan</Title>
          {/* -----------------------desktop---------------- */}
          <div id="examples" className="container desk-hotspot">
            {/* <div className="card_div_desktop"></div> */}
            {
              arrowPoints!== null && 
              (
                <div className="arrow"  style={{top:`calc(0vh + ${arrowPoints?.desktop_points[0]}%)`,left:`calc(-1vw + ${arrowPoints?.desktop_points[1]}%)`,transform: `rotate(${arrowPoints?.desktop_points[2]}deg)`
              }}>
                <span></span>
                <span></span>
                <span></span>
              </div>
              )
            }
            
            <div class="row mb-4 pb-4">
              <div class="col">
                <div className="image-hotspots">
                  <img src={data?.master_plan} className="img-fluid" />
                   
                   {
                    points.map((obj)=>{
                      const [ top , left] = JSON.parse(obj.desktop_points);
                      let newTop = parseInt(top) + 2;
                      let newLeft = parseInt(left) -2;
                      return (
                        <>
                      <DeskPoint
                        style={{ position: "absolute", left: `calc(1vw + ${left}%)`, top: `calc(1vh + ${top}%)`}}
                        className="image-hotspot image-hotspot-primary points"
                        mediaSize={[top,left]}
                      >
                      <span class="ring"></span>
                      <span class="circle" data-tip data-tooltip-id={`${obj.name.replace(" ",'_')}`}>
                        <i class="ri-add-line"></i>
                      </span>
                      </DeskPoint>
                      <Tooltip id={`${obj.name.replace(" ",'_')}`} place="top" effect="solid">
                        {
                          obj.image != null ? (
                            <img src={obj.image} alt="image" className="toolimage" />
                          ):(
                            <img src={obj.amenities_image} alt="image" className="toolimage" />
                          )
                        }
                        
                        <h2 className="tooltitle">{obj.name}</h2>
                      </Tooltip>
                      </>
                      )
                    })
                   }
                  
                  {/* <a
                    style={{ position: "absolute", left: "37%", top: "68%" }}
                    className="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="entry">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>

                  <Tooltip id="entry" place="top" effect="solid">
                    <img src={gate} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Entry/Exit</h2>
                  </Tooltip>
                  <a
                    style={{ position: "absolute", left: "39%", top: "67%" }}
                    className="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="security">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="security" place="top" effect="solid">
                    <img src={securityCabin} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Security Cabin</h2>
                  </Tooltip>

                  <a
                    style={{ position: "absolute", left: "51%", top: "57%" }}
                    className="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="parking">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="parking" place="bottom" effect="solid">
                    <img src={club} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Parking</h2>
                  </Tooltip>

                  <a
                    style={{ position: "absolute", left: "58%", top: "55%" }}
                    className="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="recreation">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="recreation" place="top" effect="solid">
                    <img src={club} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Recreation Hall</h2>
                  </Tooltip>

                  <a
                    style={{ position: "absolute", left: "47%", top: "53%" }}
                    className="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="tree">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="tree" place="bottom" effect="solid">
                    <img src={tree} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Plaza With Tree Court Seating</h2>
                  </Tooltip>

                  <a
                    style={{ position: "absolute", left: "49%", top: "45%" }}
                    className="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="lilly">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="lilly" place="bottom" effect="solid">
                    <img src={lilly} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Lily Urn</h2>
                  </Tooltip>

                  <a
                    style={{ position: "absolute", left: "52%", top: "45%" }}
                    className="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="path">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="path" place="top" effect="solid">
                    <img src={path} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Pathway With Seating</h2>
                  </Tooltip>

                  <a
                    style={{ position: "absolute", left: "54%", top: "50%" }}
                    className="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="kids">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="kids" place="top" effect="solid">
                    <img src={playArea} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Kid's Play Equipment</h2>
                  </Tooltip>

                  <a
                    style={{ position: "absolute", left: "54%", top: "46%" }}
                    className="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="playful">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="playful" place="top" effect="solid">
                    <img src={playFullMound} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Playful Mound</h2>
                  </Tooltip>
                  <a
                    style={{ position: "absolute", left: "64%", top: "52%" }}
                    className="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="basketball">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="basketball" place="bottom" effect="solid">
                    <img src={basketball} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Half Basket court</h2>
                  </Tooltip>

                  <a
                    style={{ position: "absolute", left: "51%", top: "42%" }}
                    className="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="plantation">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="plantation" place="top" effect="solid">
                    <img src={path} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Plantation</h2>
                  </Tooltip>

                  <a
                    style={{ position: "absolute", left: "70%", top: "54%" }}
                    className="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="gym">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="gym" place="top" effect="solid">
                    <img src={gym} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Outdoor Gym</h2>
                  </Tooltip>

                  <a
                    style={{ position: "absolute", left: "72%", top: "52%" }}
                    className="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="pargola">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="pargola" place="bottom" effect="solid">
                    <img src={pargola} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Pergola</h2>
                  </Tooltip>

                  <a
                    style={{ position: "absolute", left: "84%", top: "60%" }}
                    className="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="stepping">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="stepping" place="top" effect="solid">
                    <img src={steppingStone} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Stepping Stone With Lawn</h2>
                  </Tooltip>

                  <a
                    style={{ position: "absolute", left: "82%", top: "82%" }}
                    className="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="electric">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="electric" place="top" effect="solid">
                    <img src={electricalRoom} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Electrical Room</h2>
                  </Tooltip>

                  <a
                    style={{ position: "absolute", left: "68%", top: "51%" }}
                    className="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="plantation">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="plantation" place="top" effect="solid">
                    <img src={path} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Plantation</h2>
                  </Tooltip>

                  <a
                    style={{ position: "absolute", left: "79%", top: "58%" }}
                    className="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="plantation">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="plantation" place="top" effect="solid">
                    <img src={path} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Plantation</h2>
                  </Tooltip> */}



                </div>
              </div>
            </div>
          </div>
          {/* -------------------tablet----------------------- */}
          <div id="examples" class="container tablet-hotspot">

            {
              arrowPoints!== null && 
              (
                <div class="arrow1" style={{top:`calc(-2vh + ${arrowPoints?.tab_points[0]}%)`,left:`calc(0vw + ${arrowPoints?.tab_points[1]}%)`,transform: `rotate(${arrowPoints?.tab_points[2]}deg)`}}>
                <span></span>
                <span></span>
                <span></span>
            </div>
              )
            }
            
            <div class="card_div_tablet"></div>
            <div class="row mb-4 pb-4">
              <div class="col">
                <div class="image-hotspots">
                  <img src={data?.master_plan} class="img-fluid" />
                  {
                    points.map((obj)=>{
                      const [ top , left] = JSON.parse(obj.tab_points);
                      let newTop = parseInt(top) + 2;
                      let newLeft = parseInt(left) -2;
                      return (
                        <>
                         <a
                          style={{ position: "absolute", left: `calc(2vw + ${left}%)`, top: `calc(2vh + ${top}%)` }}
                          class="image-hotspot image-hotspot-primary points"
                        >
                        <span class="ring"></span>
                        <span class="circle" data-tip data-tooltip-id={`${obj.name.replace(" ",'_')}`}>
                        <i class="ri-add-line"></i>
                        </span>
                        </a>
                      <Tooltip id={`${obj.name.replace(" ",'_')}`} place="top" effect="solid">
                        {
                          obj.image != null ? (
                            <img src={obj.image} alt="image" className="toolimage" />
                          ):(
                            <img src={obj.amenities_image} alt="image" className="toolimage" />
                          )
                        }
                        <h2 className="tooltitle">{obj.name}</h2>
                      </Tooltip>
                      </>
                      )
                    })
                   }

                  {/* <a
                    style={{ position: "absolute", left: "41%", top: "67%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="entry">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="entry" place="top" effect="solid">
                    <img src={gate} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Entry/Exit</h2>
                  </Tooltip>
                  <a
                    style={{ position: "absolute", left: "44%", top: "67%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="security">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="security" place="top" effect="solid">
                    <img src={securityCabin} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Security Cabin</h2>
                  </Tooltip>

                  <a
                    style={{ position: "absolute", left: "55%", top: "57%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="parking">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="parking" place="bottom" effect="solid">
                    <img src={club} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Parking</h2>
                  </Tooltip>

                  <a
                    style={{ position: "absolute", left: "62%", top: "55%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="recreation">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="recreation" place="top" effect="solid">
                    <img src={club} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Recreation Hall</h2>
                  </Tooltip>
                  <a
                    style={{ position: "absolute", left: "50%", top: "53%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="tree">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="tree" place="bottom" effect="solid">
                    <img src={tree} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Plaza With Tree Court Seating</h2>
                  </Tooltip>

                  <a
                    style={{ position: "absolute", left: "51%", top: "44%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="lilly">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="lilly" place="bottom" effect="solid">
                    <img src={lilly} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Lily Urn</h2>
                  </Tooltip>
                  <a
                    style={{ postion: "absolute", left: "54%", top: "46%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="path">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="path" place="top" effect="solid">
                    <img src={path} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Pathway With Seating</h2>
                  </Tooltip>
                  <a
                    style={{ position: "absolute", left: "57%", top: "50%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="kids">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="kids" place="top" effect="solid">
                    <img src={playArea} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Kid's Play Equipment</h2>
                  </Tooltip>
                  <a
                    style={{ position: "absolute", left: "58%", top: "46%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="playful">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="playful" place="top" effect="solid">
                    <img src={playFullMound} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Playful Mound</h2>
                  </Tooltip>
                  <a
                    style={{ position: "absolute", left: "68%", top: "52%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="basketball">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="basketball" place="bottom" effect="solid">
                    <img src={basketball} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Half Basket court</h2>
                  </Tooltip>
                  <a
                    style={{ position: "absolute", left: "54%", top: "41%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="plantation">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="plantation" place="top" effect="solid">
                    <img src={path} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Plantation</h2>
                  </Tooltip>
                  <a
                    style={{ position: "absolute", left: "74%", top: "54%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="gym">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="gym" place="top" effect="solid">
                    <img src={gym} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Outdoor Gym</h2>
                  </Tooltip>
                  <a
                    style={{ position: "absolute", left: "77%", top: "52%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="pargola">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="pargola" place="bottom" effect="solid">
                    <img src={pargola} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Pergola</h2>
                  </Tooltip>
                  <a
                    style={{ position: "absolute", left: "89%", top: "60%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="stepping">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="stepping" place="top" effect="solid">
                    <img src={steppingStone} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Stepping Stone With Lawn</h2>
                  </Tooltip>
                  <a
                    style={{ position: "absolute", left: "87%", top: "82%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="electric">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="electric" place="top" effect="solid">
                    <img src={electricalRoom} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Electrical Room</h2>
                  </Tooltip>
                  <a
                    style={{ position: "absolute", left: "72%", top: "50%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="plantation">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="plantation" place="top" effect="solid">
                    <img src={path} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Plantation</h2>
                  </Tooltip>
                  <a
                    style={{ position: "absolute", left: "84%", top: "58%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="plantation">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="plantation" place="top" effect="solid">
                    <img src={path} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Plantation</h2>
                  </Tooltip> */}
                  

                </div>
              </div>
            </div>
          </div>
          {/* -----------------------mobile------------------- */}
          <div id="examples" class="container mob-hotspot">
            <div class="card_div_mob"></div>
            {
              arrowPoints!== null &&  (
                <div class="arrow2" style={{top:`calc(-5vh + ${arrowPoints?.mobile_points[0]}%)`,left:`calc(2vw + ${arrowPoints?.mobile_points[1]}%)`,transform: `rotate(${arrowPoints?.mobile_points[2]}deg)`}}>
                <span></span>
                <span></span>
                <span></span>
                </div>
              )
              
            }
            
            <div class="row mb-4 pb-4">
              <div class="col">
                <div class="image-hotspots">
                  <img src={data?.master_plan} class="img-fluid" />
                  {
                    points.map((obj)=>{
                      const [ top , left] = JSON.parse(obj.mobile_points);
                      let newTop = parseInt(top) + 2;
                      let newLeft = parseInt(left) -2;
                      return (
                        <>
                         <a
                          style={{ position: "absolute", left: `calc(3vw + ${left}%)`, top: `calc(3vh + ${top}%)` }}
                          class="image-hotspot image-hotspot-primary points"
                        >
                        <span class="ring"></span>
                        <span class="circle" data-tip data-tooltip-id={`${obj.name.replace(" ",'_')}`}>
                        <i class="ri-add-line"></i>
                        </span>
                        </a>
                      <Tooltip id={`${obj.name.replace(" ",'_')}`} place="top" effect="solid">
                        {
                          obj.image != null ? (
                            <img src={obj.image} alt="image" className="toolimage" />
                          ):(
                            <img src={obj.amenities_image} alt="image" className="toolimage" />
                          )
                        }
                        <h2 className="tooltitle">{obj.name}</h2>
                      </Tooltip>
                      </>
                      )
                    })
                   }

              {/* <a
                    style={{ position: "absolute", left: "40%", top: "70%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="entry">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>

                  <Tooltip id="entry" place="top" effect="solid">
                    <img src={gate} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Entry/Exit</h2>
                  </Tooltip>
                  <a
                    style={{ position: "absolute", left: "43%", top: "68%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="security">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="security" place="top" effect="solid">
                    <img src={securityCabin} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Security Cabin</h2>
                  </Tooltip>
                  <a
                    style={{ position: "absolute", left: "55%", top: "59%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="parking">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="parking" place="bottom" effect="solid">
                    <img src={club} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Parking</h2>
                  </Tooltip>
                  <a
                    style={{ position: "absolute", left: "62%", top: "57%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="recreation">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="recreation" place="top" effect="solid">
                    <img src={club} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Recreation Hall</h2>
                  </Tooltip>
                  <a
                    style={{ position: "absolute", left: "51%", top: "55%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="tree">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="tree" place="bottom" effect="solid">
                    <img src={tree} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Plaza With Tree Court Seating</h2>
                  </Tooltip>
                  <a
                    style={{ position: "absolute", left: "53%", top: "46%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="lilly">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="lilly" place="bottom" effect="solid">
                    <img src={lilly} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Lily Urn</h2>
                  </Tooltip>
                  <a
                    style={{ position: "absolute", left: "56%", top: "47%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="path">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="path" place="top" effect="solid">
                    <img src={path} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Pathway With Seating</h2>
                  </Tooltip>
                  <a
                    style={{ position: "absolute", left: "58%", top: "51%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="kids">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="kids" place="top" effect="solid">
                    <img src={playArea} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Kid's Play Equipment</h2>
                  </Tooltip>
                  <a
                    style={{ position: "absolute", left: "59%", top: "47%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="playful">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="playful" place="top" effect="solid">
                    <img src={playFullMound} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Playful Mound</h2>
                  </Tooltip>
                  <a
                    style={{ position: "absolute", left: "69%", top: "53%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="basketball">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="basketball" place="bottom" effect="solid">
                    <img src={basketball} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Half Basket court</h2>
                  </Tooltip>
                  <a
                    style={{ position: "absolute", left: "56%", top: "43%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="plantation">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="plantation" place="top" effect="solid">
                    <img src={path} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Plantation</h2>
                  </Tooltip>
                  <a
                    style={{ position: "absolute", left: "75%", top: "56%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="gym">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="gym" place="top" effect="solid">
                    <img src={gym} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Outdoor Gym</h2>
                  </Tooltip>
                  <a
                    style={{ position: "absolute", left: "77%", top: "53%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="pargola">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="pargola" place="bottom" effect="solid">
                    <img src={pargola} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Pergola</h2>
                  </Tooltip>
                  <a
                    style={{ position: "absolute", left: "90%", top: "61%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="stepping">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="stepping" place="top" effect="solid">
                    <img src={steppingStone} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Stepping Stone With Lawn</h2>
                  </Tooltip>
                  <a
                    style={{ position: "absolute", left: "88%", top: "83%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="electric">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="electric" place="top" effect="solid">
                    <img src={electricalRoom} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Electrical Room</h2>
                  </Tooltip>
                  <a
                    style={{ position: "absolute", left: "85%", top: "59%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="plantation">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="plantation" place="top" effect="solid">
                    <img src={path} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Plantation</h2>
                  </Tooltip>
                  <a
                    style={{ position: "absolute", left: "73%", top: "52%" }}
                    class="image-hotspot image-hotspot-primary points"
                  >
                    <span class="ring"></span>
                    <span class="circle" data-tip data-tooltip-id="plantation">
                      <i class="ri-add-line"></i>
                    </span>
                  </a>
                  <Tooltip id="plantation" place="top" effect="solid">
                    <img src={path} alt="image" className="toolimage" />
                    <h2 className="tooltitle">Plantation</h2>
                  </Tooltip> */}
                   
                </div>
              </div>
            </div>
          </div>
        </Content>
      </Wrapper>
    </Container>
  );
}

export default Plan;

const Container = styled.div`
  padding: 50px 0 0px 0;
  background-color: #f7f7f7;
`;
const Wrapper = styled.div`
    width: 80%;
    margin: 0 auto;
    display:flex;
    justify-content: space-between;
    margin-top:30px;

    @media(max-width:1400px){
      width:90%;
    }
`;
const Content = styled.div`
    width:90%;
`;
const SubTitle = styled.p`
  color: var(--secondary-cl) !important;
  text-align: end;
  font-family: "popins_semibold";
  font-size: 13px;
  writing-mode: vertical-lr;
  position: relative;
  transform: rotate(180deg);
  letter-spacing:.5px;
  margin-left:0;
  &::after{
    content:"";
    width:1px;
    height:150px;
    position: absolute;
    bottom:70px;
    left:50%;
    background-color:var(--secondary-cl);
  }
`;
const Title = styled.p`
  color: var(--primary-cl) !important;
  font-family: "popins_semibold";
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing:.5px;
  text-align: start;
  box-sizing: border-box;
  padding-left: 125px;
  position: relative;

  @media(max-width:580px){
      padding-left:25px;
  }

  &::after{
    content: "";
    position: absolute;
    width:50px;
    height:1px;
    background-color:var(--secondary-cl);
    left: 285px;
    top: 50%;
    @media(max-width:580px){
      left:185px;
    }
  }
`;

const Desktop = styled.div`
      position: relative;
      background-color:red;
      display: none;
      max-width: 1176px;
      height:auto;
      @media(min-width:1200px){
        display: flex;
      }
      img{
        width:100%;
        height:auto;
        display: block;
      }

`;

const Tablet = styled.div`
      background-color: blue;
      display: none;
      max-width: 1200px;
      min-height: 825px;
      @media(max-width:1200px){
        display: flex;
      }
      img{
        width:100%;
        height:100%;
        display: block;
      }
`;

const Mobile = styled.div`
      background-color: green;
      display: none;
      @media(max-width:576px){
        display: flex;
      }
`;

const DeskPoint = styled.a`
  ${(props)=>{
    if(props?.mediaSize){
      
      return `
          @media(min-width:1500px){
               top: ${props?.mediaSize[0]};
               left: ${props?.mediaSize[1]};
          }

      `;
    }
  }}
`;


import React from 'react'
import { useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { listFeatures } from '../../../axios/api';
import { useState } from 'react';

function WhyUs() {
    const [features, setfeatures] = useState([]);
    useEffect(() => {
      axios.get(listFeatures)
        .then((response)=>{
         const { StatusCode,data}  = response.data.app_data;
         
         if(StatusCode === 6000){
            if(data.length % 2 !==0){
              setfeatures(data)
            }else{
              setfeatures(data)
            }
         }else if(StatusCode === 6001){
            setfeatures([])
         }
        })
     }, [])
  return (
    <Section>
    <Container>
     <SideHeading>
        <h4>
            WHY US ?
        </h4>
     </SideHeading>
     <ContentArea>
      <Content>
      Skyline Hectares stands at the frontier of the world of real estate, offering a host of unique features that make us the ideal choice for your real estate investment.
      </Content>
      <Features>
        {
          features?.map((obj,indx)=>{
            return(
              <Feature>
              <Number>
                 0{indx+1}
              </Number>
              <Body>
                {obj.feature}
              </Body>
            </Feature>
          )
          })
        }
      </Features>
     </ContentArea>
    </Container>
    </Section>
  )
}

export default WhyUs;


 
const Section = styled.div`
    display: flex;
    padding: 15px 0px;
    width: var(--section-width-lg);
    margin: 25px auto;
    @media(max-width:1440px){
      width:90%;
    }
    @media(max-width:968px){
      width:100%;
    }
    @media(max-width:580px){
    width:100%;
    }
    @media(max-width:315px){
      padding: 5px;
    }
`;
const Container = styled.div`
    display: flex;
    gap:80px;

    @media(max-width:968px){
      width:90%;
    }
    @media(max-width:768px){
      gap:40px;
    }

    @media(max-width:580px){
      flex-direction: column;
      margin: 0 auto;
      gap:10px;
    }
`;

const SideHeading = styled.div`
width:1%;
writing-mode: vertical-lr;
display: flex;
align-items: center;

@media(max-width:580px){
  writing-mode: unset;
  width: 100%;
}

h4{
    font-family: 'popins_regular';
    font-size:14px;
    color: var(--secondary-cl);
    transform:rotate(180deg);
    position:relative;
    
    @media(max-width:580px){
      transform: rotate(0deg);
      width: 100%;
    }    

    &:after{
        content: "";
        width: 1.5px;
        height: 300%;
        background-color: #d2cccc;
        position: absolute;
        right: 50%;
        bottom: 125%;


        @media(max-width:580px){
           width: 100px;
           height: 1px;
           right: unset;
           left:80px;
           bottom: 50%;
        }
        
        @media(max-width:425px){
            right: 20%;
        }
        
    }
}
`;

const ContentArea = styled.div`
width:90%;

@media(max-width:425px){
    width:100%;
}
`;

const Heading = styled.h4`
color:var(--primary-cl);
font-family: 'popins_semibold';
margin-bottom: 20px;
`;

const Content = styled.p`
 font-family: 'popins_regular';
 font-size: 13.5px;
 text-align: justify;
 margin-bottom: 25px;

 @media(max-width:580px){
    width: 100%;
  }

`;


const Features = styled.div`
display: flex;
flex-wrap: wrap;
gap: 40px;

@media(max-width:580px){
    width: 100%;
}
`;

const Feature = styled.div`
display: flex;
align-items: center;
justify-content: flex-start;
position: relative;
padding-bottom: 10px;
width:45%;

@media(max-width:1400px){
  width:45%;
}

@media(max-width:651px){
  width: 98%;
}

&:after{
   content: '';
   width: 105%;
   height: 1px;
   position: absolute;
   bottom: -3px;
   left: 2px;
   background-color: var(--secondary-cl);

   @media(max-width:651px){
      width: 100%;
      height: 1px;
  }
}
`;

const Number = styled.h3`
color: var(--primary-cl);
font-family: 'popins_semibold';
margin-right: 10px;
margin-bottom: 0;
`;

const Body  =  styled.p`
  font-family: 'popins_regular';
  font-size: 13.5px;
  text-align: justify;
  margin-bottom: 0;
`;
import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import axios from 'axios';

import testimonialPic1 from "../../../assets/images/testimonial/testimonial-1.png";
import testimonialPic2 from "../../../assets/images/testimonial/testimonial-2.png";
import testimonialPic3 from "../../../assets/images/testimonial/testimonial-3.png";
import { useState } from "react";
import { listTestimonials } from "../../../axios/api";
import { useEffect } from "react";

function TestmonialSection() {
  const [testimonials, settestimonials] = useState([]);
  const arr = [1, 2, 3, 4, 5];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnHover: true,
    arrows:false,
    dots: false,
    responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };

  useEffect(() => {
    axios.get(listTestimonials)
      .then((response)=>{
       const { StatusCode,data}  = response.data.app_data;
       
       if(StatusCode === 6000){
          settestimonials(data)
       }else if(StatusCode === 6001){
          settestimonials([])
       }
      })
   }, [])

  return (
    <Section>
      <Container>
        <SideHeading>
          <h4>TESTIMONIAL</h4>
        </SideHeading>
        <Testimonials>
          <Slider {...settings}>
            {testimonials?.map((obj) => {
              return (
                <TestimonialContainer>
                <Testimonial>
                  <TestimonialPic>
                    <img src={obj.photo} alt="testimonial-pic" />
                  </TestimonialPic>
                  <TestimonialContent>
                    <TestimonialText>
                      <p>
                        {obj.description}
                      </p>
                    </TestimonialText>
                    <TestimonialHeading>{obj.name}</TestimonialHeading>
                  </TestimonialContent>
                </Testimonial>
                </TestimonialContainer>
              );
            })}
          </Slider>
        </Testimonials>
      </Container>
    </Section>
  );
}

export default TestmonialSection;

const Section = styled.div`
  display: flex;
  background-color: #ebebeb;
  padding: 115px 0;
  @media(max-width:1200px){
      padding: 60px 0 30px;
  }
`;

const Container = styled.div`
  display: flex;
  width: var(--section-width-lg);
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
  @media (max-width: 968px) {
    flex-direction: column;
  }
`;

const SideHeading = styled.div`
  width: 5%;
  display: flex;
  align-items: start;
  height: 100%;
  @media (max-width: 968px) {
    width: 100%;
  }
  h4 {
    font-size: 14px;
    font-family: 'popins_regular';
    color:var(--secondary-cl);
    transform: rotate(180deg);
    writing-mode:vertical-lr;
    position: relative;
    top: 15px;
    left: 0px;
    text-align: center;
    margin-left:0;
    @media (max-width: 968px) {
    width: 100%;
    position: unset;
    transform: rotate(0deg);
    text-align: start;
    writing-mode: horizontal-tb;
    }
    &::after {
      content: "";
      position: absolute;
      width: 1px;
      height: 100px;
      background-color: var(--secondary-cl);
      bottom: 100px;
      left: 8px;
      @media(max-width:968px){
        height: 1px;
        width: 100px;
        top:50%;
        left:100px;
      }
    }
  }
`;



const Testimonials = styled.div`
  display: block;
  /* grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;*/
  width: 90%;
  @media(max-width:968px){
      width: 100%;
  }

  @media(max-width:315px){
      padding: 40px 0px;
      .slick-next,.slick-prev{
        display: none !important;
      }
    }
`;

const TestimonialContainer = styled.div`
display: flex;
padding: 80px 10px;
@media(max-width:580px){
  padding: 80px 5px 30px;
}
`;


const Testimonial = styled.div`
  padding: 60px 0px 30px 25px;
  display: flex !important;
  flex-direction: column;
  max-height: 400px !important;
  position: relative;
  border: 1.5px solid var(--secondary-cl);
  width: 90% !important;
  @media(max-width:580px){
    width: 100% !important;
  }
`;

const TestimonialPic = styled.div`
  width: 115px;
  position: absolute;
  top: -61px;
  img {
    width: 100%;
    display: block;
  }
`;

const TestimonialContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const TestimonialText = styled.p`
  font-family: "popins_light";
  font-size: 14px;
`;

const TestimonialHeading = styled.h3`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  font-family: "popins_semibold";
  color: var(--primary-cl);
  position: relative;
  width:200px;
  &::after {
    content: "";
    position: absolute;
    width: 45%;
    height: 2px;
    background-color: var(--secondary-cl);
    bottom: 8px;
    right:-35px;
  }
`;

import React from 'react'
import { useState } from 'react';
import styled from 'styled-components';
import { listProjectNames } from '../../../axios/api';
import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendCrmData, sendEnquiryUrl } from '../../../axios/apiFunctions';
import CircularProgress from "@mui/material/CircularProgress";
import ReCAPTCHA from 'react-google-recaptcha';


function ProjectEnquiry() {
  const currentUrl = window.location.href;

  const [projects, setprojects] = useState([]);
  const [formdata, setformdata] = useState({url:currentUrl})
  const [error, seterror] = useState(false);
  const [loader, setloader] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const navigate = useNavigate()
  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };
  useEffect(() => {
    axios.get(listProjectNames)
      .then((response) => {
        const { StatusCode, data } = response.data.app_data;

        if (StatusCode === 6000) {
          setprojects(data)
        } else if (StatusCode === 6001) {
          setprojects([])
        }
      })
  }, [])

  const handleChange = (e) => {
    seterror(false)
    const { name, value } = e.target
    setformdata({
      ...formdata,
      [name]: value
    })
  };

  const handleSubmit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneNumberRegex = /^\d+$/;
    if (!formdata?.id) {
      seterror("select a project")
    } else if (!formdata?.name || formdata?.name.length < 3) {
      seterror('Enter a vaild name')
    } else if (!formdata?.email || !emailRegex.test(formdata?.email)) {
      seterror('Enter a vaild email')
    } else if (!formdata?.phone || !phoneNumberRegex.test(formdata?.phone)) {
      seterror('Enter a vaild phone number')
    }else if (captchaToken==="" || captchaToken===null){
      seterror('Please verify your captcha')
    }
    else {
      setloader(true)
      sendCrmData(formdata)
      axios.post(sendEnquiryUrl, formdata)
        .then((response) => {
          setloader(false);
          const { StatusCode, data } = response.data.app_data;
          if (StatusCode === 6000) {
            let projectName = projects?.map((project) => {
              if (formdata.id === project.project_id) {
                return project.project_name.replace(" ", "-")
              }
            })
            setformdata({})
            navigate('/' + projectName + '/thank-you', {
              state: {
                access: true
              }
            })
          } else {
            seterror(data.message);
            setTimeout(() => {
              seterror(false)
            }, 2000);
          }
        })
    }
  };
  return (
    <Section>
      {
        error && <Error>{error}</Error>
      }
      <Container>
        <SideHeading>
          <h4>PROJECT ENQUIRY</h4>
        </SideHeading>
        <Feilds>
          <FirstRow>
            <select name="id" id="" placeholder='' onChange={(e) => handleChange(e)}>
              <option selected disabled value="1">Select Project</option>
              {
                projects?.map((obj) => {
                  return (
                    <option value={obj.project_id}>{obj.project_name}</option>
                  )
                })
              }
            </select>
            <input name='phone' value={formdata?.phone} type="text" placeholder='Mobile' onChange={(e) => handleChange(e)} />
          </FirstRow>
          <SecondRow>
            <FirstSide>
              <input name='name' value={formdata?.name} type="text" placeholder='Name' onChange={(e) => handleChange(e)} />
              <input type="text" value={formdata?.email} name='email' placeholder='Email' onChange={(e) => handleChange(e)} />
            </FirstSide>
            <SecondSide>
              <textarea placeholder='Message' name="" id="" rows={3.7}></textarea>
            </SecondSide>
          </SecondRow>
          <Capcha>
          <ReCAPTCHA
            sitekey='6LeDwAIqAAAAAMjjFFFklc_7bXcUBPJIkIQEvUSM'
          onChange={handleCaptchaChange}
          />
          </Capcha>
        
          <SubmitButtonContainer>
            <button onClick={handleSubmit}>
              {loader ? <CircularProgress /> : "Submit"}
            </button>
          </SubmitButtonContainer>
        </Feilds>
      </Container>
    </Section>
  )
}

export default ProjectEnquiry;


const Section = styled.div`
    padding:50px 0px;
    background-color: #fff;
    @media(max-width:1200px){
      padding:50px 0px;
    }
    `;

const Container = styled.div`
    width: var(--section-width-lg);
    display: flex;
    margin: 0px auto;
    @media (max-width: 968px) {
    flex-direction: column;
    }
    @media(max-width:580px){
      width: 95%;
    }
    @media(max-width:425px){
      width: 100%; 
    }
    `;

const SideHeading = styled.div`
    width: 5%;
    display: flex;
    align-items: start;
    @media(max-width:1200px){
      width:14%;
    }
    @media (max-width: 968px) {
    width: 100%;
    }
    h4{
        font-size: 14px;
        color: var(--secondary-cl);
        font-family: 'popins_regular';
        transform: rotate(180deg);
        writing-mode: vertical-lr;
        position: relative;
        top:10px;
        text-align: center;
        margin-left: 0;
        @media (max-width: 968px) {
        width: 100%;
        transform: rotate(0deg);
        text-align: start;
        position: unset;
        margin-left: 10px;
        writing-mode: horizontal-tb;
        }
        &::after{
            content: "";
            position: absolute;
            width: 1px;
            height: 75px;
            background-color: var(--secondary-cl);
            left:9px;
            bottom:130px;
            @media(max-width:968px){
              height: 1px;
              width: 100px;
              top:50%;
              left:130px;
            }
            }
    }
`
const Capcha = styled.div`
margin: 0px 10px;
   /* padding: 5px;
  @media (max-width: 425px){
    .recapha{
    margin: 0px -30px !important;
  } */
/* } */
`
const SecondRow = styled.div`
      display: flex;
      div{
        margin: 0px 10px;
      }
    `;

const FirstRow = styled.div`
      display : flex;
      margin:5px;
      padding:5px;
      select{
        width:50%;
        margin-right: 10px;
        border:1px solid var(--secondary-cl);
        padding:8px;
        outline: none;
        color: var(--secondary-cl);
        font-size: 13px;
        font-family:'popins_regular';
      }
      input{
        width:50%;
        margin-left: 10px;
        border:1px solid var(--secondary-cl);
        padding:8px;
        outline: none;
        &::placeholder{
            color: var(--secondary-cl);
            font-size: 13px;
            font-family:'popins_regular';
        }
      }
    `;

const FirstSide = styled.div`
      width:50%;
      display: flex;
      flex-direction: column;
      input{
        width:100%;
        margin: 5px 0px;
        border:1px solid var(--secondary-cl);
        padding: 8px;
        outline: none;
        &::placeholder{
            color: var(--secondary-cl);
            font-size: 13px;
            font-family:'popins_regular';
        }
      }
    `;

const SecondSide = styled.div`
     width:50%;
     textarea{
        width:100%;
        border:1px solid var(--secondary-cl);
        padding: 8px;
        outline: none;
        height: 100px;
        &::placeholder{
            color: var(--secondary-cl);
            font-size: 13px;
            font-family:'popins_regular';
        }
     }
    `;

const Feilds = styled.div`
      width:90%;
      display: flex;
      flex-direction: column;
      @media(max-width:580px){
      width: 100%;
      margin: 0 auto;
      }
    `;

const SubmitButtonContainer = styled.div`
     padding:10px;
     button{
        background-color: var(--tertiary-cl);
        color: #fff;
        border:none;
        padding: 5px 30px;
        font-family: 'popins_light';
        font-size: 16px;
          span{
            width: 25px !important;
            height: 25px !important;
            color: white;
          }
     }
 `;


const Error = styled.p`
    margin:7px 0px;
    width: 100%;
    text-align: center;
    color:red;
`;

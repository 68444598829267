import axios from 'axios';
import instance, { BaseUrl } from '../axios/axios'

const adminLoginUrl = BaseUrl + "token/"
const adminLoginUrlVerify = BaseUrl + "general/login/"
const refreshToken = BaseUrl + "token/refresh/"
export const sendEnquiryUrl = BaseUrl + "projects/add-enquiry/"
// const downloadBrochure =BaseUrl+"enquiry/brochure-download/c8b2f485-7f1b-492d-852d-d8c0c51dbf94"
const downloadBrochure = BaseUrl + "projects/brochure-download/"
const brochureDownloadUrl = BaseUrl + "enquiry/brochure/2b4c4606-6148-40cb-897d-ad13e551d24e"
const viewEnquiryUrl = BaseUrl + "enquiry/viewenquiry/"
const enquiryStatusUpdateUrl = BaseUrl + "enquiry/enquiryread/"
export const csvDownloadUrl = BaseUrl + "enquiry/downloadenquiry/"

const testUrl = BaseUrl + "enquiry/brochure-download/3417fc38-4260-4fb9-a930-2123d7ef087f"




export const sendEnquiryApi = (data, setEnquiryStatus) => {
  return new Promise((resolve, reject) => {
    axios.post(sendEnquiryUrl, data)
      .then((response) => {
        const data = response.data.app_data.data;
        switch (data.title) {
          case 'Success':
            setEnquiryStatus({
              'status': true,
              'message': 'Enquiry send successfully.'
            });
            resolve(true);
            break;
          case 'Failed':
            setEnquiryStatus({
              'status': false,
              'message': data.message
            });
            resolve(true);
            break;
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const sendCrmData = (data) => {
  let srd = ''
  const Central_Avenue = '669504dd735dafb8fce9b194'
  const Sanctuary = '64a3acba398c9e09d6492ac9'
  if (data.id==='89dfb05c-62c6-4333-8547-956bf867d0a2'){
    srd = Central_Avenue
  }else if (data.id==='d9f1af87-52ce-4f71-9cb2-88a358a8f4bb'){
    srd = Sanctuary
  }  
  axios.get(`https://app.sell.do/api/leads/create?api_key=76f911da3b901009b62fe87a40dc6619&sell_do[form][lead][name]=${data?.name}&sell_do[form][lead][email]=${data?.email}&sell_do[form]lead][phone]=${data?.phone}&sell_do[campaign][srd]=${srd}&sell_do[form][note][content]=test&sell_do[analytics][utm_source]=Google&sell_do[analytics][utm_campaign]=Online Campaign&sell_do[analytics][campaign_name]=text&sell_do[analytics][utm_medium]=chatbot&sell_do[analytics][utm_term]=*&sell_do[analytics][utm_content]=*`)
  // axios.get(`https://app.sell.do/api/leads/create?sell_do[form][lead][name]=${data.name}&sell_do[form][lead][email]=${data.email}&sell_do[form][lead][phone]=${data.phone}&api_key=76f911da3b901009b62fe87a40dc6619&sell_do[campaign][srd]=64a3acba398c9e09d6492ac9`)
};

export const downloadBrochureApi = (form) => {

  return new Promise((resolve, reject) => {
    axios.post(downloadBrochure, form)
      .then((response) => {
        const responseData = response.data.app_data;
        if (responseData.StatusCode === 6000) {
          const data = responseData.data;
          const url = data.brochure;
          const link = document.createElement('a');
          link.style.display = 'none';
          link.href = url;
          link.target = '_blank';
          link.download = 'brochure.pdf';
          document.body.appendChild(link);

          // Trigger a click event on the link element to initiate the download
          link.click();

          // Remove the link from the DOM after the download is initiated
          document.body.removeChild(link);

          const responseObj = {
            status: 200
          }
          resolve(responseObj);
        } else {
          const responseObj = {
            status: 500
          }
          resolve(responseObj);
        }
      })
  });

};

export const downloadBrochureApi1 = () => {
  // window.location.href = brochureDownloadUrl;
  axios.post(testUrl).then((response) => {
    if (response.status === 200) {

      const blob = new Blob([response.data], { type: 'application/pdf' })

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'brochure.pdf';  // Specify the filename
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } else {

    }
  })
};

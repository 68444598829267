import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

import logo from "../../assets/images/header/skyline-hectares-logo.png";
import logo2 from "../../assets/images/header/logo.svg";
import instagramIcon from "../../assets/images/topbar/instagram_icon.svg";
import facebookIcon from "../../assets/images/topbar/facebook_icon.svg";
import youtubeIcon from "../../assets/images/topbar/youtube_icon.svg";
import instagramIconActive from "../../assets/images/topbar/instagram_icon_active.svg";
import facebookIconActive from "../../assets/images/topbar/facebook_icon_active.svg";
import youtubeIconActive from "../../assets/images/topbar/youtube_icon_active.svg";
import searchIcon from "../../assets/images/topbar/search_icon.svg";
import MobileNavBar from "./MobileNavBar";

// floating chat options icons
import whatsapp_svg from "../../assets/images/float_icons/whatsapp-svg.svg";
import call_svg from "../../assets/images/float_icons/phone-svg.svg";
import mail_svg from "../../assets/images/float_icons/mail-svg.svg";

import chat from "../../assets/images/header/chat-logo.svg";
import chatbanner from "../../assets/images/header/hectares-chat.png";
import { useDispatch, useSelector } from "react-redux";
import { toggle3DView } from "../../redux/actions/general";
import { viewContactDetails } from "../../axios/api";

const colors = {
  primary: "#4BA647",
  secondary: "#A0A0A0",
  tertiary: "#E53228",
  black: "#1D1C1A",
};

function TopBar() {
  const [active, setactive] = useState(false);
  const [selected, setselected] = useState("Home");
  const [searchBar, setsearchBar] = useState(false);
  const [width, setwidth] = useState(0);
  // const [isShow, setShow] = useState(false);
  const [openChat, setopenChat] = useState(false);
  const [data, setdata] = useState(false);

  const currentMenu = useSelector((state) => state.general.active_menu);
  const active3D = useSelector((state) => state.general.active_3D);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const path = useLocation();

  useEffect(() => {
    axios.get(viewContactDetails).then((response) => {
      const { StatusCode, data } = response.data.app_data;

      if (StatusCode === 6000) {
        setdata(data);
      } else if (StatusCode === 6001) {
        setdata({});
      }
    });
  }, []);

  const handleMenu = () => {
    setactive(true);
  };

  useEffect(() => {
    if (active) {
      document.querySelector("html").classList.add("menu-bar-enabled");
    } else {
      document.querySelector("html").classList.remove("menu-bar-enabled");
    }
  }, [active]);

  useEffect(() => {
    switch (currentMenu) {
      case "Home":
        setselected("Home");
        break;
      case "Project":
        setselected("Projects");
        break;
      case "Contact":
        setselected("Contact");
        break;
      case "Blog":
        setselected("Blogs");
        break;
      case "About":
        setselected("About");

      default:
        break;
    }
  }, [currentMenu]);

  const handleChatOpen = () => {
    setopenChat(!openChat);
  };

  const toggleActive3d = () => {
    dispatch(toggle3DView());
  };

  const handleSelectItems = (itemSelected) => {
    switch (itemSelected) {
      case "Home":
        navigate("/");
        break;
      case "Projects":
        navigate("/projects");
        break;
      case "Contact":
        navigate("/contact");
        break;
      case "Blogs":
        navigate("/blogs");
        break;
      case "About":
        navigate("/about");
        break;

      default:
        break;
    }
  };

  const handleClick = (url) => {
    window.location.href = url;
  };
  
  return (
    <MainContainer>
      <Logo>
        <Link to={"/"}>
          <img className="img-logo" src={logo2} alt="" />
        </Link>
      </Logo>
      {active ? (
        <MobileNavBar setActive={setactive} />
      ) : (
        <MenuPortion>
          <TopLayer>
            <SocialMedia>
              <Icon onClick={() => handleClick(data?.instagram_url)}>
                {active3D ? (
                  <img src={instagramIconActive} alt="" />
                ) : (
                  <img src={instagramIcon} alt="" />
                )}
              </Icon>
              <Icon onClick={() => handleClick(data?.facebook_url)}>
                {active3D ? (
                  <img src={facebookIconActive} alt="" />
                ) : (
                  <img src={facebookIcon} alt="" />
                )}
              </Icon>
              <Icon onClick={() => handleClick(data?.youtube_url)}>
                {active3D ? (
                  <img src={youtubeIconActive} alt="" />
                ) : (
                  <img src={youtubeIcon} alt="" />
                )}
              </Icon>
            </SocialMedia>
            {/* {searchBar?(<ActiveSearchTab onClick={handleSearchBar}>
            <InputContainer>
              <input  type="search"  />
            </InputContainer>
            <ImgDiv>
               <img src={searchIcon} alt="" />
            </ImgDiv>
         </ActiveSearchTab>):(<SearchTab onClick={handleSearchBar}>
            <InputContainer>
              <input  type="search"  />
            </InputContainer>
            <ImgDiv>
               <img src={searchIcon} alt="" />
            </ImgDiv>
         </SearchTab>)} */}
          </TopLayer>
          {/* setting the backbutton only if the 3d is open  */}
          {active3D ? (
            <BackButtonContainer>
              <BackButton onClick={toggleActive3d}>Back</BackButton>
            </BackButtonContainer>
          ) : (
            <DownLayer>
              <Item
                className={selected === "Home" ? "active" : ""}
                onClick={() => handleSelectItems("Home")}
              >
                Home
              </Item>
              <Item
                className={selected === "Projects" ? "active" : ""}
                onClick={() => handleSelectItems("Projects")}
              >
                Projects
              </Item>
              <Item
                className={selected === "About" ? "active" : ""}
                onClick={() => handleSelectItems("About")}
              >
                About
              </Item>
              <Item
                className={selected === "Blogs" ? "active" : ""}
                onClick={() => handleSelectItems("Blogs")}
              >
                Blogs
              </Item>
              <Item
                className={selected === "Contact" ? "active" : ""}
                onClick={() => handleSelectItems("Contact")}
              >
                Contact
              </Item>
              <MenuIconHolder onClick={handleMenu}>
                <MenuIcon />
              </MenuIconHolder>
            </DownLayer>
          )}

          {/* <ChatWindow status={openChat}>
            <TopArea banner={chatbanner}>
              <div>
                <CloseIcon onClick={handleChatOpen} />
              </div>
            </TopArea>
            <InnerChatArea id="ls_chatFrameDiv"></InnerChatArea>
          </ChatWindow> */}
          {/* {!openChat && (
            <CoverChat>
            
              <ChatIcon onClick={handleChatOpen}>
                <img src={chat} alt="chat icon" />
              </ChatIcon>
            </CoverChat>
          )} */}

          {/* <Phone status={openChat} href={`tel:+91 ${data?.phone}`}>
            <i class="ri-phone-fill"></i>
          </Phone> */}

          {/* Floating icons section */}
          <FloatingChatIcons>
            <WhatsappFloat href={`https://wa.me/+91${data?.whatsapp_url}`}>
              <Img>
                <img src={whatsapp_svg} alt="" />
              </Img>
              <ToolTip className="preview">WhatsApp</ToolTip>
            </WhatsappFloat>
            <CallFloat href={`tel:+91${data?.phone}`}>
              <Img>
                <img src={call_svg} alt="" />
              </Img>
              <ToolTip className="preview" w={200} l={230}>
                +91 {data?.phone}
              </ToolTip>
            </CallFloat>
            <EmailFloat href={`mailto:${data?.email}`}>
              <Img>
                <img src={mail_svg} alt="" />
              </Img>
              <ToolTip className="preview" w={250} l={270}>
                {data?.email}
              </ToolTip>
            </EmailFloat>
          </FloatingChatIcons>
        </MenuPortion>
      )}
    </MainContainer>
  );
}

export default TopBar;

const MainContainer = styled.div`
  font-family: "popins_regular";
  display: grid;
  grid-template-columns: 53% 47%;
  width: var(--section-width-lg);
  margin: 0 auto;
  padding: 15px 0;
  z-index: 200;
  position: relative;

  @media (max-width: 968px) {
    grid-template-columns: 40% 60%;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 40% 60%;
  }
  @media (max-width: 600px) {
    width: 100%;
    padding: 15px 0;
  }
  @media (max-width: 580px) {
    padding: 10px 0px 0px 0px;
  }
`;

const Logo = styled.div`
  @media (max-width: 580px) {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 21px;
  }
  .img-logo {
    width: 100px;
    /* @media(max-width:1024px){
        width:35%;
      }
      @media(max-width:650px){
          width: 85% !important;
      }*/
    @media (max-width: 500px) {
      width: 75px;
    }
    /* @media(max-width:376px){
          width: 100% !important;
      }
      @media(max-width:326px){
        width: 110% !important;
      }  */
  }
`;

const MenuPortion = styled.div``;

const TopLayer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 5px 0px;
`;

const SocialMedia = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

const Icon = styled.div`
  width: 25px;
  margin: 0px 3px;
  &:hover {
    transform: scale(1.1);
  }
  img {
    display: block;
    width: 100%;
  }
`;

const SearchTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-radius: 8px;
  border: 1px solid black;
  overflow: hidden;
  width: 20%;
  margin-right: 10px;

  @media (min-width: 2000px) {
    width: 15% !important;
    padding: 4px;
  }
  @media (min-width: 1700px) {
    width: 30%;
    padding: 4px;
  }
  @media screen and (max-width: 1439px) {
    margin-right: 0px;
    width: 35%;
  }
  @media screen and (max-width: 1024px) {
    margin-right: 0px;
    width: 40%;
  }
  @media screen and (max-width: 968px) {
    margin-right: 0px;
    width: 50%;
  }
  @media screen and (max-width: 768px) {
    margin-right: 0px;
    width: 60%;
  }
  @media (max-width: 500px) {
    width: 145%;
  }
  @media (max-width: 475px) {
    width: 300%;
  }
`;

const ActiveSearchTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-radius: 8px;
  border: 1px solid black;
  overflow: hidden;
  width: 40%;
  margin-right: 10px;
`;

const InputContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  input {
    width: 55%;
    height: 25px;
    padding: 12px;
    position: relative;
    border: none;
  }
`;

const ImgDiv = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 2px;
    height: 16px;
    border-radius: 8px;
    background-color: rgb(29, 28, 26);
    transition: all 0.3s ease-in-out 0s;
    opacity: 0.6;
    pointer-events: none;
    @media (min-width: 1700px) {
      height: 28px;
    }
    @media screen and (min-width: 1200px) and (max-width: 1439px) {
      top: 1px;
    }
  }
  img {
    display: block;
    width: 50%;
    @media (max-width: 968px) {
      width: 40%;
    }
  }
  @media screen and (max-width: 1100px) {
    width: 65%;
  }
`;

const DownLayer = styled.div`
  display: flex;
  .active {
    background-color: ${colors.primary};
    color: white;
  }
  @media screen and (max-width: 768px) {
    padding: 8px;
    justify-content: end;
    padding-right: 15px;
  }
`;

const Item = styled.div`
  padding: 5px;
  flex: 1;
  margin: 3px 5px;
  display: flex;
  align-self: center;
  justify-content: center;
  border-radius: 2px;
  cursor: pointer;
  &:not(.active):hover {
    color: var(--primary-cl);
    transform: scale(1.1);
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const MenuIconHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;

  @media screen and (min-width: 769px) {
    display: none;
  }
`;

const ChatIcon = styled.div`
  color: #25d366;
  z-index: 10000;
  padding: 15px;
  cursor: pointer;
  border-radius: 50%;
  @media all and (max-width: 480px) {
    /* right: 30px; */
  }
  img {
    display: block;
    width: 100%;
  }
`;
const CoverChat = styled.div`
  background-color: #fff;
  position: fixed;
  right: 30px;
  bottom: 50px;
  border-radius: 50px;
  animation: pulse 2s infinite;
  -webkit-animation: pulse 2s infinite;
  @media all and (max-width: 480px) {
    bottom: 20px;
    right: 13px;
  }
  @media (max-width: 375px) {
    right: 15px;
  }
  @keyframes pulse {
    0% {
      /* box-shadow: 0 0 0 0 #fbb32f; */
      box-shadow: 0 0 0 0 var(--primary-cl);
    }
    100% {
      box-shadow: 0 0 0 17px #fbb32f01;
    }
  }
`;

const Phone = styled.a`
  color: #fff;
  text-decoration: none;
  left: 30px;
  bottom: 50px;
  position: fixed;
  z-index: 10000;
  font-size: 26px;
  background: #ee3c35;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: ${(props) => {
    if (props.status) {
      if (window.innerWidth < 580) {
        return "none";
      }
    }
    return "flex";
  }};
  justify-content: center;
  align-items: center;
  @media all and (max-width: 480px) {
    left: 5px;
    bottom: 20px;
  }
`;

const Whatsapp = styled.a`
  color: #25d366;
  font-size: 26px;
  /* background: #fff; */
  z-index: 10000;
  padding: 10px;
  text-decoration: none;
  /* position: fixed; */
  cursor: pointer;
  border-radius: 50%;
  bottom: 50px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 50px;
  @media all and (max-width: 480px) {
    right: 30px;
  }
`;

const ChatWindow = styled.div`
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 200;
  width: 320px;
  height: 375px;
  border-radius: 10px;
  padding: 10px;
  background-color: #fff;
  z-index: 2500;
  visibility: ${(props) => {
    return props.status ? "visible" : "hidden";
  }};
`;

const InnerChatArea = styled.div`
  width: 300px;
  height: 75%;
  text-align: center;
  vertical-align: middle;
`;

const TopArea = styled.div`
  ${(props) =>
    props.banner &&
    `
    background-image: url(${props.banner});
   `}
  background-repeat:no-repeat;
  background-size: cover;
  height: 90px;
  display: flex;
  padding: 5px;
  justify-content: flex-end;
  align-items: start;
  div {
    cursor: pointer;
  }
`;

const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const BackButton = styled.button`
  padding: 10px 15px;
  border: none;
  background-color: var(--primary-cl);
  color: #fff;
  border-radius: 10px;
  margin-top: 10px;
  margin-right: 10px;
`;

const FloatingChatIcons = styled.div`
  position: fixed;
  top: 37vh;
  right: -3px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 150px;
  background-color: var(--primary-cl);
  height: 180px;
  border-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  .preview {
    display: none;
  }
  @media (max-width: 580px) {
    width: 40px !important;
  }
`;

const WhatsappFloat = styled.a`
  width: 50px;
  display: flex;
  background-color: var(--grey-white-cl);
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 0px 5px;
  transition: width 200ms ease-in;
  height: 60px;
  position: relative;
  &:hover {
    .preview {
      display: flex !important;
    }
  }
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 10%;
    width: 70%;
    height: 1px;
    background-color: #fff;
  }
  img {
    width: 25px !important;
    @media (max-width: 580px) {
      width: 17px !important;
    }
  }
`;

const EmailFloat = styled.a`
  width: 50px;
  display: flex;
  background-color: var(--grey-white-cl);
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 0px 5px;
  transition: width 200ms ease-in;
  height: 60px;
  position: relative;
  &:hover {
    .preview {
      display: flex !important;
    }
  }
  img {
    width: 25px !important;
    @media (max-width: 580px) {
      width: 17px !important;
    }
  }
`;

const CallFloat = styled.a`
  width: 50px;
  display: flex;
  background-color: var(--grey-white-cl);
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 0px 5px;
  transition: width 200ms ease-in;
  height: 60px;
  position: relative;
  &:hover {
    .preview {
      display: flex !important;
    }
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 10%;
    width: 70%;
    height: 1px;
    background-color: #fff;
  }
  img {
    width: 22px !important;
    @media (max-width: 580px) {
      width: 17px !important;
    }
  }
`;

const Img = styled.div`
  img {
    width: 40px;
    display: block;
  }
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 580px) {
    width: 75% !important;
  }
`;

const ToolTip = styled.div`
  background-color: var(--primary-cl);
  color: white;
  position: absolute;
  left: -200px;
  border-radius: 5px;
  padding: 15px;
  width: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "popins_regular";
  border: none;
  font-size: 14px;
  ${(props) => props.w && `width:${props.w}px;left:-${props.l}px;  `}
  @media(max-width:580px) {
    padding: 8px;
  }
  &::after {
    content: "";
    position: absolute;
    top: 7px;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    rotate: 270deg;
    right: -19px;
    border-left: 10px solid transparent; /* Adjust the size as needed */
    border-right: 10px solid transparent; /* Adjust the size as needed */
    border-top: 20px solid var(--primary-cl); /* Adjust the size and color as needed */
    @media (max-width: 580px) {
      top: 2px;
    }
  }
`;

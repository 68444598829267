import React from 'react'
import styled from 'styled-components';

import logo from '../../assets/images/header/logo.svg'
import { useEffect } from 'react';

function Loader2(){
    useEffect(() => {
        document.querySelector('html').classList.add('loader-active')
        return () => {
            document.querySelector('html').classList.remove('loader-active')
        };
    }, [])
  return (
    <Section  className='loader'>
        <Img>
            <img src={logo} alt="" />
        </Img>
    </Section>
  )
}

export default Loader2;

const Section = styled.div`
      position: absolute;
      background-color:rgb(240 240 240);;
      top: 0;
      bottom: 0;
      right: 0;
      left:0;
      height:100vh;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      z-index:500;
      display:flex;
`;

const Img = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;


  img {
    width: 100%;
    display: block;
    animation: bubble infinite 4s ease-in-out;

    @keyframes bubble {
        0%{
            transform: scale(1);
        }
        25%{
            transform: scale(1.15);
        }
        50%{
            transform: scale(1);
        }
        75%{
            transform: scale(1.15);
        }
        100%{
            transform: scale(1);
        }
    }
  }
`;



import React from 'react'
import styled from 'styled-components';

function LocationInfo({data}) {
  return (
   <Section>
    <Container dangerouslySetInnerHTML={{__html:data?.map}}/>
   </Section>
  )
}

export default LocationInfo;


const Section = styled.div`
margin:60px 0 100px;
`;

const Container = styled.div`
display: flex;
width:var(--section-width-lg);
margin:0 auto;
border-radius: 20px;
overflow: hidden;
`;

import React, { useEffect } from "react";
//package
import styled from "styled-components";
import $ from "jquery";

function ImageModal({ isShow, handleImageModal, selectedImage }) {
  useEffect(() => {
    if (isShow) {
      $("html").addClass("modal-enabled");
    } else {
      $("html").removeClass("modal-enabled");
    }
  }, [isShow]);
  return (
    <MainContainer>
      {isShow ? <Overlay onClick={() => handleImageModal()}></Overlay> : ""}
      <BackContainer style={{ transform: isShow && "scale(1,1)" }}>
        <Modal type="textarea">
          <Container>
            <Close
              onClick={() => {
                handleImageModal();
              }}
            >
              <i class="ri-close-circle-fill"></i>
            </Close>
            <Container>
              <ImageContainer>
                <img src={selectedImage} alt="image" />
              </ImageContainer>
            </Container>
          </Container>
        </Modal>
      </BackContainer>
    </MainContainer>
  );
}
export default ImageModal;

const BackContainer = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  margin: 0 auto;
  right: 0;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
`;

const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0px;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 90%;
  max-width: 700px;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  transition: 0.5s;
  z-index: 101;
  border-radius: 5px;
  /* background: #fff; */
  @media all and (max-width: 1280px) {
    width: 520px;
  }
  @media all and (max-width: 1080px) {
    width: 500px;
  }
  @media all and (max-width: 980px) {
    width: 650px;
  }
  @media all and (max-width: 768px) {
    width: 480px;
  }
  @media all and (max-width: 640px) {
    width: 400px;
  }
  @media all and (max-width: 480px) {
    width: 340px;
  }
  @media all and (max-width: 360px) {
    width: 300px;
  }
`;
const MainContainer = styled.div``;
const Close = styled.div`
  position: absolute;
  right: 10px;
  cursor: pointer;
  color: rgb(246 0 0);
  top: 0;
  z-index: 1500;
  font-size: 36px;
`;
const Container = styled.div``;
const ImageContainer = styled.div`
  img {
    display: block;
    width: 100%;
  }
`;

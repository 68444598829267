import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';


function MobileNavBar({active,setActive}) {
  const navigate = useNavigate();
  const handleClick = (id)=>{
    switch (id) {
      case 1:
        setActive(false)
        navigate('/');
        break;
      case 2:
        setActive(false)
        navigate('/projects');
        break;
      case 3:
        setActive(false)
        navigate('/about');
        break;
      case 4:
        setActive(false)
        navigate('/blogs');
        break;
      case 5:
        setActive(false)
        navigate('/contact');
        break;
  
      default:
        break;
    }
  }
  return (
    <MainDiv>
        <SideBar>
            <CloseBar onClick={()=>setActive(false)}>
            <CloseIcon/>
            </CloseBar>
            <ItemContainer>
                <Item onClick={()=>{
                  handleClick(1)
                }} >
                  Home
                </Item>
                <Item onClick={()=>{
                  handleClick(2)
                }}>
                  Projects
                </Item>
                <Item onClick={()=>{
                  handleClick(3)
                }}>
                  About
                </Item>
                <Item onClick={()=>{
                  handleClick(4)
                }}>
                  Blogs
                </Item>
                <Item onClick={()=>{
                  handleClick(5)
                }}>
                  Contact
                </Item>
            </ItemContainer>
        </SideBar>
    </MainDiv>
  )
}

const MainDiv = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-color:#414141a3;
    overflow: hidden;
    z-index: 1;
    `

const SideBar = styled.div`
    background-color:#fff;
    width:50%;
    position: fixed;
    top:0;
    right:0;
    height: 100%;
    z-index: 25;
    @media(max-width:475px){
      width: 90%;
    }
    `

const CloseBar = styled.div`
    padding: 15px;
    display: flex;
    justify-content: end;
`

const ItemContainer = styled.div`
  padding: 20px;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
`

const Item = styled.div`
    padding: 15px;
    font-family:'popins_bold';
    color: var(--primary-cl);
    font-size: 18px;
    margin:10px 0px;
    &:hover{
      transform: scale(1.1);
    }
`;


export default MobileNavBar;
import basketball from "../images/tooltip/latest/basket_ball.jpg";
import club1 from "../images/tooltip/latest/club.jpg";
import club2 from "../images/tooltip/club2hqout.jpg";
import corner from "../images/tooltip/latest/corner_view.jpg";
import gym from "../images/tooltip/latest/gym.jpg";
import lily from "../images/tooltip/lillypoolhqout.jpg";
import pargola from "../images/tooltip/latest/pargola.jpg";
import pathway from "../images/tooltip/latest/path_way.jpg";
import playarea from "../images/tooltip/latest/play_area.jpg";
import topview from "../images/tooltip/latest/top.png";
import tree from "../images/tooltip/latest/tree_court_seating.jpg";


// projects imgs

import project_1 from "../images/Projects/project-1.png";
import project_2 from "../images/Projects/project-2.png";
import project_3 from "../images/Projects/project-3.png";
import project_4 from "../images/Projects/project-4.png";
import project_5 from "../images/Projects/project-5.png";
import project_6 from "../images/Projects/project-6.png";

import banner1 from '../images/banner/banner.jpg';
import banner2 from '../images/banner/banner2.jpg';
import banner3 from '../images/banner/banner3.jpg';


export const amentiesImages = [
  {
    id: 1,
    image: basketball,
    title: "Basket Ball",
  },
  {
    id: 2,
    image: club1,
    title: "Recreation Hall",
  },
  {
    id: 3,
    image: corner,
    title: "Corner View",
  },
  {
    id: 4,
    image: gym,
    title: "Gym",
  },
  {
    id: 5,
    image: lily,
    title: "Lily Urn",
  },
  {
    id: 6,
    image: pargola,
    title: "Pargola",
  },
  {
    id: 7,
    image: pathway,
    title: "Path Way",
  },
  {
    id: 8,
    image: playarea,
    title: "Play Area",
  },
  {
    id: 9,
    image: topview,
    title: "Top View",
  },
  {
    id: 10,
    image: tree,
    title: "Tree Court Seating",
  },
];


export const  projectImgs = [
project_1,project_2,project_3,project_4,project_5,project_6

]


export const  BannerImgs = [
  {
    img:banner1,
    title:"Hello This Is the Heading",
    subtitle:"Hello This Is the Sub Title",
    url:"/project/2"
  },
  {
    img:banner2,
    title:"Invest in a Plot,Build Your Heritage.",
    subtitle:"Build a home where cherished memories will be made for generations to come.",
    url:"/project/3"
  },
  {
    img:banner3,
    title:"Hello This Is the Heading 2",
    subtitle:"Hello This Is the Sub Title 2",
    url:"/project/1"
  },
]
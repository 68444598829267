import React from 'react';

import threeDimentional from '../../../assets/images/360/360.png';
import threeDimentionalAnimation from '../../../assets/images/360/round-360.svg';
import styled from 'styled-components';
import { Tilt } from 'react-tilt'
import { projectImgs } from '../../../assets/js/collection';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { listProjects } from '../../../axios/api';
import { useNavigate } from 'react-router-dom';


function Project360Area() {
   const [projects, setprojects] = useState([]);
   const navigate = useNavigate();

   useEffect(() => {
    axios.get(listProjects)
      .then((response)=>{
       const { StatusCode,data}  = response.data.app_data;
       
       if(StatusCode === 6000){
          
          setprojects(data.results)
       }else if(StatusCode === 6001){
          setprojects([])
       }
      })
   }, [])

   const handleNavigate = (slug) => {
        navigate('/project/'+slug,{
          state:{
            open: true,
          }
        })
   };

  return (
    <Section>
       <Container>
        <ThreeDimensionalArea>
             <Title>360</Title>
            <ImgDiv>
             <Tilt>
              <img src={threeDimentional} alt="" />
             </Tilt>
            </ImgDiv>
            <Subpara>
            Step inside the future of living with our immersive 360-degree property models.
            Explore every angle of your dream plot within our gated communities from the comfort of your screen. 
            </Subpara>
        </ThreeDimensionalArea>
        <ProjectListArea>
            <Title1>Projects in  360</Title1>
            <CardArea>
                {projects.map(project=>{
                    return(
                     <Card onClick={()=>handleNavigate(project.slug)}>
                     <img src={project.plot_image} alt="" />
                     <ThreeDIcon>
                        <img src={threeDimentionalAnimation} alt="" />
                     </ThreeDIcon>
                     <OverLay>
                        <p>{project.projectname}</p>
                     </OverLay>
                     </Card>)
                })}
            </CardArea>
            {/*<ViewMore>
                View More
            </ViewMore> */}
        </ProjectListArea>
       </Container>
    </Section>
  )
}

export default Project360Area;


const Section = styled.div`
   background-color: #000;
   padding:75px 0;
   @media(max-width:315px){
     padding: 10px 0px;
   }
`

const Container = styled.div`
   display: flex;
   align-items:flex-start;
   justify-content:center;
   width: var(--section-width-lg);
   margin: 20px auto;
   height: max-content;
   @media(max-width:968px){
    flex-direction: column;
   }
   @media(max-width:580px){
    padding: 35px 0px;
   }
`
const OverLay = styled.div`
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 20%;
    background-color: #333333c8;
    color: #fff;
    z-index: 20;
    @media (max-width: 968px) {
    height: 25%;
    padding: 0;
    @media(max-width:968px){
      height: 40px;
    }
    }
    p{
        font-family: 'popins_light';
        padding: 0;
        margin: 0;
        font-size: 10px;
        @media(max-width:968px){
            font-size: 14px;
        }
        @media(max-width: 768px){
            font-size: 15px;
        }
        @media(max-width:475px){
            font-size: 12px;
        }
    }
`;

const ThreeDIcon = styled.div`
     position: absolute;
     width: 20%;
     top: 35%;
     left: 37%;
     z-index: 20;
     img{
        width: 100%;
        display: block;
     }
`

const ThreeDimensionalArea = styled.div`
      width: 75%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      position: relative;
      padding: 35px 0px;
      @media(max-width:968px){
      width: 100%;
      }
      @media(max-width:768px){
      width: 85%;
      margin: 0px 0;
      }
      @media(max-width:580px){
      width: 95%;
      padding: 25px 0px;
      }
`
const Subpara = styled.p`
       color:#fff;
       margin: 20px 0;
       font-family: 'popins_light';
       font-size: 15px;
       padding:15px 0px; 
       @media(max-width:1440px){
         padding:15px 5px;
       }
       @media(max-width:580px){
         font-size: 12px;
         margin: 10px auto;
       }
`

const ImgDiv = styled.div`
    width: 95%;
    display: flex;
    justify-content: flex-start;
    padding: 30px 0;
    margin: 25px 0;
    
    div{
      width:90%;
    }

    @media(max-width:580px){
      width: 90%;
      margin: 0 auto;
      padding: 20px 0;

    }
    img {
        width: 100%;
        display: block;
    }
    `

const ProjectListArea = styled.div`
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 25px;
      padding: 10px 0px;
      position: relative;
      height: 100%;
      @media(max-width:968px){
      width: 100%;
      }
      @media(max-width:768px){
        width: 85%;
      }
      @media(max-width:580px){
        width: 95%;
        margin: 10px auto;
      }
`;

const Title = styled.div`
    top: -5px;
    color: var(--secondary-cl);
    left: 3px;
    position: absolute;
    font-family: 'popins_light';
    margin-bottom: 25px;
    @media(max-width:1440px){
     left:10px;
    }
    /* @media(max-width:580px){
      position: unset;
      width: 100%;
      display: flex;
      align-items: start;
      justify-content: flex-start;
      margin-bottom: 25px;
    } */
    &::after{
        content: '';
        position: absolute;
        display: block;
        width: 600%;
        height: .5px;
        background-color: var(--secondary-cl);
        margin: 10px auto;
        top: 1px;
        left: 40px;
        @media(max-width:580px){
              width: 100px;
        };
    }
    &::before{
      content: "0";
      position: absolute;
      left: 31px;
      top: 1px;
      font-size: 9px;
    }
    `;
const Title1 = styled.div`
    color: var(--secondary-cl);
    position: absolute;
    top: -12px;
    font-size: 14px;
    left: 0px;
    font-family: 'popins_light';
    width:100%;
    &::after{
        content: '';
        position: absolute;
        display: block;
        width: 70%;
        height: 1px;
        background-color: var(--secondary-cl);
        margin: 10px auto;
        top: 0;
        left: 115px;
        @media(max-width:968px){
          width: 80%;
        }
        @media(max-width:580px){
          width: 100px;
        }
    }
    &::before{
      content: "0";
      position: absolute;
      top: -1px;
      font-size: 9px;
      left: 103px;
    }
    `
const CardArea = styled.div`
    display: flex;
    flex-wrap: wrap;
    width:100%;
`

const Card = styled.div`
 position: relative;
 width: 45%;
 margin: 5px;
 overflow: hidden;
 cursor: pointer;
 position: relative;
 &::after{
  content: "";
  inset: 0;
  position: absolute;
  background-color:#000;
  opacity: .4;
 }
 img{
    width: 100%;
    display: block;
 }
`
const ViewMore = styled.p`
    width: 100%;
    font-size: 12px;
    font-family: 'popins_semibold';
    text-align: end;
    color: var(--secondary-cl);
    padding: 20px 0;
    margin: 0;
    position: relative;
    letter-spacing: .8px;
    &::before{
      width: 75%;
      height: 1px;
      content: '';
      border: 1px dashed var(--secondary-cl);
      position: absolute;
      left: 0;
      top:50%;
    }
`;
import React from 'react'
import styled from 'styled-components';

function BlogContent({blog}) {
  return (
    <Section>
        <BlogDescription>
            <Heading>
              {blog?.title}
            </Heading>
            <Date>
              {blog?.created.split('T')[0]}
            </Date>
            <Content dangerouslySetInnerHTML={{__html:blog?.body}}>
              {/* {blog?.content} */}
            </Content>
        </BlogDescription>
        <RecentBlogs>
            <Card>
                <ImgDiv>
                <img src={blog?.image} alt="" />
                </ImgDiv>
                <CardBody>
                    <CardHeading>
                    {blog?.title}
                    </CardHeading>
                    <CardPara> 
                      {
                        blog?.body.length > 270 ? (
                          <BlogDescription dangerouslySetInnerHTML={{__html:blog?.body.slice(0,270)+ "..."}}/>
                        ):(
                          <BlogDescription dangerouslySetInnerHTML={{__html:blog?.body}}/>
                        )
                        
                      }
                    </CardPara>
                    <CardBottom>
                    <p className='date'>
                        {blog?.created.split('T')[0]}
                    </p>
                    <p className='seperator'>
                        <hr />
                    </p>
                    <p className='readmore'>
                        Read More
                    </p>
                    </CardBottom>
                </CardBody>
            </Card>
        </RecentBlogs>
    </Section>
  )
}

export default BlogContent;


const Section = styled.div`
margin: 25px auto;
display: flex;
gap: 35px;

@media(max-width:1400px){
  gap: 15px;
}

@media(max-width:968px){
   flex-direction: column;
}

`;

const BlogDescription = styled.div`
width:70%;
display: flex;
flex-direction:column;
padding:10px;

@media(max-width:968px){
   width: 100%;
}

`;

const RecentBlogs = styled.div`
width:30%;
display: flex;
flex-direction:column;
gap: 20px;

@media(max-width:968px){
   width: 100%;
   flex-direction: row;
}

@media(max-width:580px){
  flex-direction: column;
}

`;

const Heading = styled.h2`
font-family: 'popins_semibold';
color : var(--primary-cl);

@media(max-width:425px){
  margin-bottom: 15px;
}

`;

const Date = styled.p`
    font-family: 'popins_light';
    font-size:15px;
    color: var(--secondary-cl);
    width : 100%;
    position : relative;

    @media(min-width:2000px){
        font-size: 18px;
    }

    &::after{
        width:125px;
        content: '';
        position : absolute;
        height : 2px;
        background-color:var(--secondary-cl);
        left :110px;
        top :50%;

        @media(max-width:968px){
        left: 95px;
        } 
}
`;

const Content = styled.p`
 font-size: 16px;
 font-family: 'popins_regular';
 text-align: justify;

 @media(min-width:2000px){
  font-size: 20px;
}

`;

const Card = styled.div`
border: 1px solid rgb(160 160 160 / 60%);
padding: 20px;
cursor: pointer;

@media(max-width:1400px){
   padding: 20px 8px;
}
`;

const ImgDiv = styled.div`
width: 100%;
margin-bottom: 20px;
img{
    width: 100%;
    display: block;
}
`;


const CardBody = styled.div`
 padding: 10px;
`;

const CardHeading = styled.h3`
color: var(--primary-cl);
font-family: 'popins_semibold';
font-size: 18px;

@media(min-width:2250px){
  font-size: 22px;
}

`;

const CardPara = styled.p`
font-family: 'popins_light';
font-size: 14px;
line-height: 22px;

div{
  width:100%;
}

@media(min-width:2250px){
  font-size: 17px;
}

`;

const  CardBottom = styled.div`
display: flex;
justify-content:space-between;
align-items: center;
p{
    width: 100%;
}
.date{
  font-size: 15px;
  font-family: 'popins_regular';
  color: var(--secondary-cl);
  text-align: center;

  @media(min-width:2000px){
    text-align: left;
  }

  @media(max-width:1400px){
   font-size: 12px;
  }

  @media(max-width:768px){
   font-size: 16px;
   text-align: left;
  }

}

.seperator{
width: 100%;
display: flex;
align-items:center;
justify-content:center;


@media(max-width:580px){
   display:none;
}

hr{
    width: 96%;
    margin:0 auto;

    @media(min-width:2250px){
        width: 100%;
    }
}

}
.readmore{
  font-size: 15px;
  font-family: 'popins_regular';
  color: var(--secondary-cl);
  text-align: center;

  @media(max-width:1400px){
   font-size: 12px;
  }

  @media(max-width:768px){
   font-size: 16px;
  }
}
`;


import React, { useEffect, useState } from "react";
import Spotlight from "../components/Pages/ProjectDetail/Spotlight";
import Form from "../components/Pages/ProjectDetail/Form";
import ProjectWalkThrough from "../components/Pages/ProjectDetail/ProjectWalkThrough";
import Plan from "../components/Pages/ProjectDetail/Plan";
import Amenities from "../components/Pages/ProjectDetail/Amenities";
import Nearby from "../components/Pages/ProjectDetail/Nearby";
import Footer from "../components/includes/Footer";
import EnquiryForm from "../components/modals/EnquiryForm";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TopBar from "../components/includes/TopBar";
import { useDispatch } from "react-redux";
import { updateActiveMenu } from "../redux/actions/general";
import { useLocation, useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import { listSingleProject } from "../axios/api";
import Loader2 from "../components/common/Loader2";
import Helmet from "../components/common/Helmet";

function ProjectDetail() {
  const [isModal, setisModal] = useState(false);
  const [loader, setloader] = useState(true);
  const [data, setdata] = useState({});
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const status = location.state?.open || false;
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);

  
  useEffect(()=>{
   setTimeout(()=>{
   setloader(false);
   },1500)
  },[])


  useEffect(() => {
    dispatch(updateActiveMenu("Project"))
    if(id===undefined){
      navigate('/projects/')
    }
    axios.get(listSingleProject+id)
      .then((response)=>{
       const { StatusCode,data}  = response.data.app_data;
       
       if(StatusCode === 6000){
          setdata(data[0]);
       }else if(StatusCode === 6001){
          setdata([]);
       }
      }).catch((error)=>{
         
         if(error.response.status === 400){
          navigate('/projects')
         }
      })
  },[])



  return (
      <>
        <Helmet titleData={data?.meta_tag} descriptionData={data?.meta_description} />
        <TopBar/>
        <Spotlight status={status} data={data} />
        <Form data={data}  />
        <ProjectWalkThrough data={data} />
        <Plan data={data}  />
        <Amenities id={data?.id}  />
        <Nearby data={data} />
        <Footer />
        {
          loader ? <Loader2 /> : <EnquiryForm other={data?.projectname} slug={data?.slug} id={data?.id} isModal={isModal} type={"Enquiry"} setModal={setisModal} />
        }
      </>
    )
}

export default ProjectDetail;

import React from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

function SingleBlog({data,format=""}) {
  const navigate = useNavigate();
  const handleClick = (slug)=>{
        navigate(`/blog/${slug}`);
   };

  return (
    <Card format={format}  onClick={()=>handleClick(data.slug)}>
        <ImageSection>
             <ImgDiv bg={data?.image}>
              <img src={data?.image} alt="" />
             </ImgDiv>
        </ImageSection>
        <ContentSection>
            <Heading>
               {data?.title}
            </Heading>
            <Date>
               {data?.created.split('T')[0]}
            </Date>
            <Description dangerouslySetInnerHTML={{__html:data?.body.slice(0,350)}}>
             
            </Description>
            <ReadMore>
              Read More
            </ReadMore>
        </ContentSection>
       
    </Card>
  )
}

export default SingleBlog;

const Card = styled.div`
padding: 45px;
border: 1px solid var(--secondary-cl);
border-radius: 15px;
display: flex;
gap: 25px;
flex-direction:${(props)=>{
    if(props.format === "reverse"){
        return `row-reverse`
    }else{
        return `row`
    }
}};

@media(min-width:2250px){
    height:460px;
}

@media(max-width:1200px){
    padding:15px;
}

@media(max-width:968px){
    flex-direction:column;
    gap:20px;
}

@media(max-width:580px){
   gap: 0px;
}

`;

const ImageSection  = styled.div`
width:50%;
display: flex;
align-items: center;
justify-content: center;
@media(max-width:968px){
    width: 100%;
    height: 270px;
}

@media(max-width:400px){
  height: 200px;
}
`;

const ContentSection = styled.div`
width:50%;
display: flex;
flex-direction: column;
align-items: start;
justify-content: center;


@media(min-width:2250px){
    width: 65%;
}

@media(max-width:968px){
    width: 100%;
}

@media(max-width:580px){
  padding:  0;
}

p,h3{
    margin:8px 0;
    padding:0;


    @media(max-width:1200px){
        margin:4px 0;
    } 
}
`;

const ImgDiv = styled.div`
width: 100%;
height: 100%;
${(props)=>props.bg && `background-image: url(${props.bg})`};
background-repeat: no-repeat;
background-size:contain;
background-position:center center;

@media(max-width:580px){
    background-size:125%;
}

img{
    display: none;
    width: 100%;
    height: 100%;
}
`;


const Heading = styled.h3`
font-family: 'popins_semibold';
color : var(--primary-cl);
font-size: 20px;

@media(min-width:2250px){
    font-size: 35px;
}

@media(max-width:1200px){
    font-size: 18px;
}

`;

const Date = styled.p`
font-family: 'popins_light';
font-size:15px;
color: var(--secondary-cl);
width : 100%;
position : relative;

@media(min-width:2250px){
    font-size: 18px;
}



&::after{
    width:125px;
    content: '';
    position : absolute;
    height : 2px;
    background-color:var(--secondary-cl);
    left :110px;
    top :50%;

    @media(max-width:968px){
    left: 95px;
    } 
}
`;

const Description = styled.p`
font-family: 'popins_regular';
font-size:15px;
line-height: 25px;

@media(min-width:2250px){
    font-size: 18px;
}

@media(max-width:1200px){
    font-size: 14px;
    line-height:22px;
}

`;

const ReadMore = styled.p`
width:100%;
display:flex;
align-items:center;
justify-content:space-between;
font-family: "popins_medium";
font-size: 15px;
color: var(--secondary-cl);
font-weight: 550;
position: relative;
cursor: pointer;

&:hover{
  color: var(--primary-cl);
}

@media(min-width:2250px){
    font-size: 18px;
}

&::after{
    width:125px;
    content: '';
    position : absolute;
    height : 2px;
    background-color:var(--secondary-cl);
    left :108px;
    top :50%;
    
    @media(max-width:968px){
        left: 95px;
    } 
}
`;


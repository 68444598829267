import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import { useDispatch }  from 'react-redux';


import WhoWeAre from '../components/Pages/HomePage/WhoWeAre';
import TopBar from '../components/includes/TopBar';
import Footer from '../components/includes/Footer';
import HeroPortion from '../components/Pages/HomePage/HeroPortion';
import ProjectListView from '../components/Pages/HomePage/ProjectListView';
import EnquiryForm from '../components/Pages/HomePage/EnquiryForm';
import Project360Area from '../components/Pages/HomePage/Project360Area';
import FirstView from '../components/Pages/HomePage/FirstView';
import BlogDisplay from '../components/Pages/HomePage/BlogDisplay';
import TestmonialSection from '../components/Pages/HomePage/TestmonialSection';
import ProjectEnquiry from '../components/Pages/HomePage/ProjectEnquiry';
import { updateActiveMenu, updateLoader } from '../redux/actions/general';
import { listBanners } from '../axios/api';
import { useState } from 'react';
import Loader2 from '../components/common/Loader2';

function Home() {
  const dispatch = useDispatch();
  const [loader, setloader] = useState(true);

  useEffect(() => {
    dispatch(updateActiveMenu("Home"))
    setTimeout(() => {
      setloader(false);
    }, 2250);
  }, [])


  return (
   <MainContainer >
     <TopBar/>
     <HeroPortion />
     <WhoWeAre/>
     <ProjectListView/>
     <EnquiryForm/>
     <Project360Area/>
     <BlogDisplay/>
     {/* <TestmonialSection/> */}
     <ProjectEnquiry/>
     <Footer/>
     {
      loader && <Loader2/>
     }
   </MainContainer>
  )
}
const MainContainer = styled.div`
`
export default Home;
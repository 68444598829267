import React from 'react';
import "./assets/css/style.css";
import "remixicon/fonts/remixicon.css";
import "./assets/css/skin.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/demo-product-landing.css";
import "react-tooltip/dist/react-tooltip.css";
// import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import {Route, Routes} from 'react-router-dom';
import Home from "./Pages/Home";
import { ParallaxProvider } from 'react-scroll-parallax';
import FirstView from "./components/Pages/HomePage/FirstView";
import ProjectList from "./Pages/ProjectList";
import Contact from "./Pages/Contact";
import Blog from "./Pages/Blog";
import BlogDetail from "./Pages/BlogDetail";
import About from "./Pages/About";
import ProjectDetail from "./Pages/ProjectDetail";
import Disclaimer from "./Pages/Disclaimer";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Loader from "./components/common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { updateLoader } from "./redux/actions/general";
import ThankYou from "./Pages/ThankYou";
import ThankYouRouter from "./components/routes/ThankYouRouter";


function App() {
  const loader = useSelector(state=>state.general.loader)
  const dispatch = useDispatch();

   useEffect(() => {
    dispatch(updateLoader(true))
    setTimeout(() => {
      dispatch(updateLoader(false))
    }, 1300);
    
   }, [])

  return (
    <ParallaxProvider>
        <Routes>
        // User Routes
        <Route path="/" element={<Home/>} />
        <Route path="/projects" element={<ProjectList/>} />
        <Route path="/project/:id?" element={<ProjectDetail/>} />
        <Route path="/blogs" element={<Blog/>} />
        <Route path="/blog/:id?" element={<BlogDetail/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/disclaimer" element={<Disclaimer/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
        <Route path="/thank-you" element={<ThankYou/>} />
      </Routes>
      <ThankYouRouter/>
    </ParallaxProvider>
  );
}

export default App;

import React from 'react'
import { projectImgs } from '../../../assets/js/collection';
import styled from 'styled-components';
import axios from 'axios';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem1  from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import searchIcon from "../../../assets/images/topbar/search_icon.svg";
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { listLocations, listProjects, searchProject } from '../../../axios/api';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

function ProjectListSection() {
    const navigate = useNavigate();
    const [locations, setlocations] = useState([])
    const [projects, setprojects] = useState(null);
    const location = useLocation();
    let searchParams = location.state?.search || null;
    const [search, setsearch] = useState(searchParams);
    const [query, setquery] = useState(searchParams);
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
      setAge(event.target.value);
    };

    

    const handleClick = (slug) =>{
      navigate("/project/"+slug)
    }



    useEffect(() => {
      
      axios.get(listLocations)
      .then((response)=>{
       const { StatusCode,data}  = response.data.app_data;
       
       if(StatusCode === 6000){
          let newData = data.map((obj)=>{
           return obj.split(',')[1]
          })
          setlocations(newData)
       }else if(StatusCode === 6001){
          setlocations([])
       }
      })
       
      if(search!==null){
        const data = new FormData()
        data.append('q',search)
        axios.post(searchProject,data)
        .then((response)=>{
         const { StatusCode,Projects}  = response.data.app_data;
         
         if(StatusCode === 6000){
            setprojects(Projects)
         }else if(StatusCode === 6001){
            setprojects([])
         }
        })
      }else{
        axios.get(listProjects)
        .then((response)=>{
         const { StatusCode,data}  = response.data.app_data;
         
         if(StatusCode === 6000){
            
            setprojects(data.results)
         }else if(StatusCode === 6001){
            setprojects([])
         }
        })
      }
      
    },[search])

    const handlechange = (e)=>{
      if(e.target.value === ""){
        setquery(null);
      }else{
        setquery(e.target.value)
      }
    }

    const handleSubmit = ()=>{
      setsearch(query);
    }

    const resetSearch = ()=>{
      setsearch(null);
      setquery('');
    }

    const searchByLocation = (e)=>{
        setsearch(e.target.value);
        setquery('');
    };

  return (
    <Section>
        <Container>
            <MenuSection>
                <MenuList>
                    <MenuItem>
                    <select  placeholder='All location' name="location" id="" onChange={searchByLocation}>
                      <option selected disabled>Location</option>
                        {
                          locations?.map((obj)=>{
                            
                            if(searchParams === obj){
                              return (
                                <option selected value={obj}>{obj}</option>
                              )
                            }else{
                              return (

                                <option value={obj}>{obj}</option>
                              )
                            }
                          })
                        }
                    </select>
                    </MenuItem>
                </MenuList>
                <ProjectSearchBar>
                    <SearchInput>
                        <input value={query} onChange={handlechange} type="text" placeholder="Search" />
                    </SearchInput>
                    <SubmitButton onClick={handleSubmit} >
                        Find Plots
                    </SubmitButton>
                </ProjectSearchBar>
            </MenuSection>

            <ProjectListArea left={projects?.length < 3} >
              { 
                projects?.length === 0 ? (
                  <ProjectNotFound>
                  <TextArea>
                     NO MATCHES FOUND ...
                  </TextArea>
                  <ViewAllButton onClick={resetSearch} style={{marginTop:'20px'}}>
                   <button>
                     View All
                   </button>
                 </ViewAllButton>
          </ProjectNotFound>
                ):(
                  projects?.map((obj,indx)=>{
                    return (
                        <Card onClick={()=>handleClick(obj.slug)}>
                        <ImgDiv  src={obj.plot_image}>
                        </ImgDiv>
                         
                        <OverLay className='overLay' >
                          <h6>{obj.projectname}</h6>
                          <p>{obj.location.split(',')[1]}</p>
                         </OverLay>
                        
                        </Card>
                    )
                })
                )
              }
             
            </ProjectListArea>
            {
              projects?.length > 6 &&
              <ViewAllButton>
               <button>
                View All
               </button>
            </ViewAllButton>
            }
            
        </Container>
    </Section>
  )
}

export default ProjectListSection;


const Section = styled.div`
    padding: 25px 0px;
    background-color:#EBEBEB;
    @media(max-width:1200px){
      padding: 25px 0px;
    }
    @media(max-width:580px){
       margin: 25px auto;
    }
    .label{ 
        font-size: 16px;
        font-family: 'popins_semibold';
        color:var(--secondary-cl);
        margin:5px 10px;
    }
`
const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: var(--section-width-lg);
    margin: 0 auto;
    `

const MenuSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 25px 0px;
    padding: 0px;
    @media(max-width:580px){
    padding: 0;
    flex-direction: column;
    gap: 20px;   
    width:100%; 
    }
`

const MenuList = styled.div`
    display: flex;
    @media(max-width:425px){
      width: 80% !important;
      align-items: center;
      justify-content: center;
    }
    @media(max-width:375px){
      width: 100% !important;
    }
`
const MenuItem = styled.div`
    padding: 8px;
    display: flex;
    border-radius: 15px;
    font-family: 'popins_regular';
    color: var(--primary-cl);
    border: 1px solid var(--primary-cl);
   
    @media(max-width:425px){
      width: 100% !important;
      align-items: center;
      justify-content: center;
    }
    select{
        background-color: transparent;
        border: none;
        outline: none;
        font-family: 'popins_semibold';
        color: var(--primary-cl);
        font-size: 14px;
    }
`
const ProjectSearchBar = styled.div`
   height: 100%;
   display: flex;
   position: relative;
   @media(max-width:580px){
    width:100%;
    justify-content: center;
    width: 100%;
   }
    @media(max-width:375px){
      flex-direction:column;
      align-items: center;
      justify-content:center;
    }
   div{
    margin: 0px 5px;
    @media(max-width:580px){
    margin: 0;
    }
    @media(max-width:375px){
      height: 40px;
      margin: 4px 0px;
    }
   }
   /* &::after{
    content: "";
    display: block;
    position: absolute;
    width: 12px;
    top: 4px;
    left: 16px;
    height: 100%;
    background-image: url(${searchIcon});
    margin: 10px 0px;
    background-repeat: no-repeat;
    color: var(--secondary-cl);

    @media(max-width:580px){
        width: 13px;
        top: 3px;
        left: 12px;
    }
    @media(max-width:425px){
      left: 48px !important;
    }

    } */
`

const SearchInput = styled.div`
    @media(max-width:375px){
      width: 100%;
    }
    input{
        background-color: #fff;
        background-image: url(${searchIcon});
        background-repeat: no-repeat;
        background-position: 7px center;
        background-size: 18px;
        border: none;
        outline: none;
        height: 100%;
        width: 100%;
        padding-left: 35px;
        &::placeholder{
            font-size: 14px;
            font-family: 'popins_light';
        }
    }
`

const SubmitButton = styled.div`
    padding: 8px;
    background-color: var(--primary-cl);
    color: #fff;
    font-size: 16px;
    font-family: 'popins_light';
    display: flex;
    align-items: center;
    justify-content:center;
    cursor: pointer;
    @media(max-width:580px){
    font-size: 14px;
    }
    @media(max-width:375px){
      width: 100%;
      margin: 0 auto;
      height: 30px !important;
    }
`
const ProjectListArea = styled.div`
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  ${(props)=>props.left && 'justify-content: flex-start;'}
  gap:10px;
  @media(min-width:2000px){
  gap: 20px;
  }
  @media(max-width:1440px){
  gap: 10px;
  }
  @media(max-width:580px){
    justify-content: center;
  }
`
let Card = styled.div`
    border-radius: 10px;
    margin:10px 0px;
    position: relative;
    overflow: hidden;
    cursor:pointer;
`
if(projectImgs.length>2){
  Card = styled.div`
  border-radius: 15px;
  margin:10px 0px;
  position: relative;
  overflow: hidden;
  width: 250px;
  height: 320px;
  @media(max-width:1024px){
  width: 290px;
  height: 330px;
  }
  @media(max-width:580px){
    width: 340px;
    height: 360px;
  }
`
}else{
    Card = styled.div`
    border-radius: 10px;
    margin:10px 10px;
    flex:1;
    position: relative;
    overflow: hidden;
  `
}

const OverLay = styled.div`
    padding: 15px 25px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content:baseline;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 20%;
    color: #fff;
    @media(max-width:1200px){
      justify-content: center;
    }
    h6{
        font-family: 'popins_regular';
        padding: 0;
        font-weight:500;
        margin: 0;
        font-size: 14px;
    }
    p{
        font-family: 'popins_light';
        padding: 0;
        margin: 0;
        font-size: 12px;
        position: relative;
        &::after{
        position: absolute;
        content: '';
        width: 150%;
        height: 2px;
        background-color: #b3b3b3;
        top:50%;
        left:calc(100% + 10px);
        }
    }
`;


const ImgDiv = styled.div`
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${(props)=>(props.src)});
    height:100%;
    cursor: pointer;
`

const ViewAllButton = styled.div`
display: flex;
align-items:center;
justify-content: center;
width: 100%;
margin: 40px 0px 15px 0px;
padding: 0px 10px;
button{
    color: #fff;
    font-family: 'popins_light';
    background-color: var(--primary-cl);
    border-radius:30px;
    padding:5px 35px;
    text-transform: capitalize;
    border: none;
    outline: none;
    font-size:15px;
}
`;

const ProjectNotFound = styled.div`
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    padding: 40px;
`;

const TextArea= styled.p`
    text-align: center;
    color: var(--primary-cl);
    font-family: 'popins_bold';
`;


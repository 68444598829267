import React, { useState } from "react";
import styled from "styled-components";

import { toggle3DView } from "../../../redux/actions/general";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";


function Spotlight({data,status}) {
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  // const [active, setActive] = useState(false);
  const dispatch = useDispatch();
  const active = useSelector(state=>state.general.active_3D);

  useEffect(() => {
   
    if(active === false){
      if(data?.computer_videos ){
        if (!isIOS){
          document.getElementById("background-video").play();
          document.getElementById("hero-banner-ipad").play();
        }
        document.getElementById("hero-banner-mobile").play();
      }
     }
  }, [active,data]);
  
  useEffect(() => {
     if(status){
      dispatch(toggle3DView(true))
     }
     return ()=>{
      dispatch(toggle3DView(false));
     }
  }, [])

  const handle3dToggle = () =>{
    dispatch(toggle3DView());
  };
  
  return (
    <Container status={active} id="home">
        <div  class="hero-banner" onClick={() =>{
          handle3dToggle()
        }}>
          {
            active ? (
            <iframe
              className="iframe"
              src={data?.iframeurl}
              frameborder="0"
            ></iframe>

            ) : (
              <>
              {
                data?.computer_videos && (
                  <>
                  <video
                  id="background-video"
                  className="desktop-image"
                  autoplay
                  loop
                  muted
                  playsinline
                >
                  <source src={data?.computer_videos} type="video/mp4" />
                  <source src={data?.computer_videos} type="video/webm" />
                  <source src={data?.computer_videos} type="video/ogg" />
                </video>
                <video
              type="video/mp4"
              alt="iPad Banner Image"
              className="ipad-image"
              onclick="changeImage()"
              id="hero-banner-ipad"
              autoplay
              muted
              playsInline
            >
              <source src={data?.computer_videos} type="video/mp4" />
              <source src={data?.computer_videos} type="video/webm" />
              <source src={data?.computer_videos} type="video/ogg" />
            </video>
            {/* <!-- mobile --> */}
            <video
              type="video/mp4"
              alt="Mobile Banner Image"
              className="mobile-image"
              onclick="changeImage()"
              id="hero-banner-mobile"
              autoplay
              muted
              playsInline
            >
              <source src={data?.mobile_videos} type="video/mp4" />
              <source src={data?.mobile_videos} type="video/webm" />
              <source src={data?.mobile_videos} type="video/ogg" />
            </video>
                  </>

                )
                    

              }
            </>
            )
          }
        </div>
    </Container>
  );
}

export default Spotlight;

const Container = styled.div`
 /* height: 685px; */
 ${(props)=>{
    return props.status ? 'height:90vh;' : 'height:685px;'
  }}
 @media(max-width:768px){
  /* height: 280px; */
  ${(props)=>{
    return props.status ? 'height:90vh;' : 'height:280px;'
  }}
 }
 @media(min-width:1500px){
  /* height:813px;  */
  ${(props)=>{
    return props.status ? 'height:90vh;' : 'height:813px;'
  }}
  video{
    height: 950px;
  }
 }

`;

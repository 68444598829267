import React from 'react'
import styled from 'styled-components';

function CardSection({data}) {
  return (
   <Section>
    <Container>
        <Card b={'r'}>
          <Title>
             Mission
          </Title>
          <Body>
             {
              data?.mission
             }
          </Body>
        </Card>
        <hr />
        <Card>
          <Title>
             Vision
          </Title>
          <Body>
            {
              data?.vission
            }
          </Body>
        </Card>
    </Container>
   </Section>
  )
}

export default CardSection;

const Section = styled.div`
width: 100%;
background-color: #f1f1f1;
margin: 30px 0;
`;

const Container = styled.div`
width:var(--section-width-lg);
margin: 0 auto;
display: flex;
padding: 25px 0;
position: relative;

@media(max-width:1400px){
      width:90%;
}

@media(max-width:968px){
  width: 95%;
}

@media(max-width:651px){
  flex-direction: column;
}

&::after{
    content: '';
    width:2px;
    height: 80%;
    background-color: #d0cdcd;
    position: absolute;
    left: 50%;
    
    @media(max-width:651px){
     display: none;
    }

}
`;

const Card = styled.div`
width: 48%;
margin: 0 auto;
display: flex;
flex-direction: column;
padding: 25px 0px;

&:first-child{
  padding-right: 10px;
}
&:not(:first-child){
  padding-left: 10px;
}

@media(max-width:968px){
    padding: 10px;
}

@media(max-width:651px){
  width:98%;
  margin: 0 auto;
}

`;


const Title  = styled.h4`
color: var(--primary-cl);
font-family: 'popins_semibold';
margin-bottom: 25px;
`;

const Body = styled.p`
    font-family: 'popins_regular';
    font-size: 13.5px;
    text-align: justify;
`;



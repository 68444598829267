import { BaseUrl } from "./axios";

export const listBanners = BaseUrl + "home/list-mainbanner/";

export const viewAbout = BaseUrl + "home/view-about/";
export const coutersList = BaseUrl + "home/count-projects/";

export const listLocations = BaseUrl + "project/list-location/";
export const listProjects = BaseUrl + "project/list-project/";
export const searchProject = BaseUrl + "project/search-project/";

export const listBlogs = BaseUrl + "home/list-blog/";

export const listTestimonials = BaseUrl +"home/list-testimonials/";

export const listProjectNames = BaseUrl +"project/list-project-name/";
export const listSingleProject = BaseUrl +"project/list-single-project/";

export const listAwards = BaseUrl +"home/list-award/";

export const viewAboutPageContent = BaseUrl +"home/view-about-content/";

export const viewContactDetails = BaseUrl +"home/view-contactdetails/";

export const listFeatures = BaseUrl +"home/list-about-content-feature/";

export const listSingleBlog = BaseUrl +"home/view-single-blog/";
export const quickEnquiry = BaseUrl +"projects/quick-enquiry/";

export const listMasterPlanPoints = BaseUrl +"project/list-masterplan-points/";
export const listMasterPlanArrow = BaseUrl +"project/list-arrow-points/";

export const listAmenities = BaseUrl +"projects/list-amenities/";











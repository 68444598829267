import React, { useState } from 'react'
import styled from 'styled-components';
import axios from 'axios';

import searchIcon from "../../../assets/images/topbar/search_icon.svg";

import { projectImgs } from '../../../assets/js/collection';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { listLocations, listProjects } from '../../../axios/api';

function ProjectListView() {
    const [showOverlay, setshowOverlay] = useState(null);
    const [locations, setlocations] = useState([]);
    const [projects, setprojects] = useState([]);
    const [search, setsearch] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
      axios.get(listLocations)
      .then((response)=>{
       const { StatusCode,data}  = response.data.app_data;
       
       if(StatusCode === 6000){
          let newData = data.map((obj)=>{
           return obj.split(',')[1]
          })
          setlocations(newData)
       }else if(StatusCode === 6001){
          setlocations([])
       }
      })

      axios.get(listProjects)
      .then((response)=>{
       const { StatusCode,data}  = response.data.app_data;
       
       if(StatusCode === 6000){
          
          setprojects(data.results)
       }else if(StatusCode === 6001){
          setprojects([])
       }
      })
      
   }, [])


    const handleClick = (slug) =>{
        navigate('/project/'+slug)
    };

    const handleChange = (e)=>{
      setsearch(e.target.value);
    }
  
    const handleSearchSubmit = ()=>{
         
         if(search!==''){
            navigate('/projects/',{
              state:{
                search
              }
            })
         }
    };

    const handleSearchByLocation = (e)=>{
      const value = e.target.value;
      navigate('/projects/',{
        state:{
          search : value
        }
      })
 };

  return (
    <Section>
        <Container>
            <p class="label">PROJECT</p>
            <MenuSection>
                <MenuList>
                    <MenuItem>
                    <select placeholder='All location' name="location" id="" onChange={handleSearchByLocation}>
                    <option selected disabled>Location</option>
                        {
                          locations?.map((obj)=>{
                            return (
                              <option value={obj}>{obj}</option>
                            )
                          })
                        }
                    </select>
                    </MenuItem>
                </MenuList>
                <ProjectSearchBar>
                    <SearchInput>
                        <input value={search} onChange={handleChange} type="text" placeholder="Search" />
                    </SearchInput>
                    <SubmitButton onClick={handleSearchSubmit}>
                        Find Plots
                    </SubmitButton>
                </ProjectSearchBar>
            </MenuSection>

            <ProjectListSection left={projects?.length<3}>
                {projects?.map((project,indx)=>{
                    return (
                        <Card onClick={()=>{
                          handleClick(project.slug)
                        }}>
                        <ImgDiv>
                        <img src={project.plot_image} alt="" />
                        </ImgDiv>
                        {/* {showOverlay==indx && 
                         
                        } */}
                         <OverLay className='overLay' >
                          <h6>{project.projectname}</h6>
                          <p>{project.location}</p>
                         </OverLay>
                        </Card>
                    )
                })}
            </ProjectListSection>
            
        </Container>
    </Section>
  )
}

export default ProjectListView;

const Section = styled.div`
    padding: 25px 0px;
    background-color:#EBEBEB;
    @media(max-width:1200px){
        padding: 25px 0px;
    }
    .label{ 
        font-size: 14px;
        font-family: 'popins_regular';
        color:var(--secondary-cl);
        margin:5px 0px;
    }
`
const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: var(--section-width-lg);
    margin: 0 auto;
    margin-bottom: 50px;
    `

const MenuSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 25px 0px;
    padding: 0px;
    @media(max-width:580px){
    padding: 0;
    }
    @media(max-width:580px){
      flex-direction: column;
      gap: 20px;   
      width:100%; 
    }
`

const MenuList = styled.div`
    display: flex;
    @media(max-width:580px){
      width: 80% !important;
      align-items: center;
      justify-content: center;
    }
`
const MenuItem = styled.div`
    padding: 8px;
    border: 1px solid var(--primary-cl);
    display: flex;
    border-radius: 15px;
    font-family: 'popins_regular';
    color: var(--primary-cl);
    @media(max-width:580px){
      width: 100% !important;
      align-items: center;
      justify-content: center;
    }
    select{
        background-color: transparent;
        border: none;
        outline: none;
        font-family: 'popins_semibold';
        color: var(--primary-cl);
        font-size: 14px;
    }
`
const ProjectSearchBar = styled.div`
   height: 100%;
   display: flex;
   position: relative;
    @media(max-width:580px){
      justify-content: center;
      width: 100%;  
    }
    @media(max-width:315px){
      flex-direction:column;
      align-items: center;
      justify-content:center;
    }
   div{
    margin: 0px 5px;
    @media(max-width:580px){
    margin: 0;
    }
    @media(max-width:315px){
      height: 40px;
      margin: 4px 0px;
    }
   }
   /* &::after{
    content: "";
    display: block;
    position: absolute;
    width: 12px;
    top: 4px;
    left: 16px;
    height: 100%;
    background-image: url(${searchIcon});
    margin: 10px 0px;
    background-repeat: no-repeat;
    color: var(--secondary-cl);

    @media(max-width:580px){
        width: 13px;
        top: 3px;
        left: 12px;
    }
    @media(max-width:425px){
      left: 48px !important;
    }

    } */
`

const SearchInput = styled.div`
    @media(max-width:315px){
      width: 100%;
    }
    input{
        background-color: #fff;
        background-image: url(${searchIcon});
        background-repeat: no-repeat;
        background-position: 7px center;
        background-size: 18px;
        border: none;
        outline: none;
        height: 100%;
        width: 100%;
        padding-left: 35px;
        &::placeholder{
            font-size: 14px;
            font-family: 'popins_light';
        }
    }
`

const SubmitButton = styled.div`
    padding: 8px;
    margin: 0px !important;
    margin-left:5px;
    background-color: var(--primary-cl);
    color: #fff;
    font-size: 16px;
    font-family: 'popins_light';
    display: flex;
    align-items: center;
    justify-content:center;
    cursor: pointer;
    @media(max-width:580px){
    font-size: 14px;
    }
    @media(max-width:315px){
      width: 100%;
      margin: 0 auto;
      height: 30px !important;
    }
`
const ProjectListSection = styled.div`
  display:flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
  ${(props)=>props.left && 'justify-content: flex-start;'}
`
let Card = styled.div`
    border-radius: 10px;
    margin:10px 0;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &:hover .overLay{
      display: flex;
    }
    @media(max-width:580px){
    .overLay{
      display:flex;
    }
   }
`
if(projectImgs.length>2){
  Card = styled.div`
  border-radius: 10px;
  margin:10px 0;
  position: relative;
  overflow: hidden;
  width: 30%;

  &:hover .overLay{
      display: flex;
    }
  @media(max-width:968px){
    width:45%;
   }
  @media(max-width:580px){
    width:95%;
    .overLay{
      display:flex;
    }
   }
`
}else{
    Card = styled.div`
    border-radius: 10px;
    margin:10px 10px;
    flex:1;
    position: relative;
    overflow: hidden;

    &:hover .overLay{
      display: flex;
    }
    @media(max-width:968px){
    width:45%;
    }
    @media(max-width:580px){
       width:95%;
      .overLay{
      display:flex;
    }
   }
  `
}

const OverLay = styled.div`
    padding: 15px 25px;
    display: none;
    flex-direction: column;
    align-items: start;
    justify-content:baseline;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 20%;
    background-color: #33333383;
    color: #fff;
    @media(max-width:1200px){
      justify-content: center;
    }
    h6{
        font-family: 'popins_regular';
        padding: 0;
        font-weight:500;
        margin: 0;
        font-size: 14px;
    }
    p{
        font-family: 'popins_light';
        padding: 0;
        margin: 0;
        font-size: 12px;
    }
`;


const ImgDiv = styled.div`
    width: 100%;
    cursor: pointer;
    img{
        display: block;
        width: 100%;
    }  
`
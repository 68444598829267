import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import axios from 'axios';

import { updateActiveMenu } from '../redux/actions/general';

import TopBar from '../components/includes/TopBar';
import Breadcrumb from '../components/common/Breadcrumb';
import Footer from '../components/includes/Footer';

import about_bg from '../assets/images/breadcrumb/about-bg.jpg';
import AboutContent from '../components/Pages/About/AboutContent';
import DirectorReview from '../components/Pages/About/DirectorReview';
import CardSection from '../components/Pages/About/CardSection';
import WhyUs from '../components/Pages/About/WhyUs';
import { viewAboutPageContent } from '../axios/api';
import { useState } from 'react';




function About() {
  const dispatch = useDispatch();
  const [data, setdata] = useState({})

  useEffect(() => {
    dispatch(updateActiveMenu("About"))
  }, [])

  useEffect(() => {
    axios.get(viewAboutPageContent)
      .then((response)=>{
       const { StatusCode,data}  = response.data.app_data;
       
       if(StatusCode === 6000){
          setdata(data) 
       }else if(StatusCode === 6001){
          setdata({})
       }
      })
   }, [])
  
  return (
    <>
     <TopBar/>
     <Breadcrumb bg={about_bg} title={"About"} destination={"About"} />
     <AboutContent data={data}/>
     <DirectorReview/>
     <CardSection data={data}/>
     <WhyUs/>
     <Footer/>
    </>
  )
}

export default About;


import React, { useEffect } from 'react'
import styled from 'styled-components';
import TopBar from '../components/includes/TopBar';
import Footer from '../components/includes/Footer';
import Breadcrumb from '../components/common/Breadcrumb';
import BreifDetail from '../components/Pages/ProjectListPage/BreifDetail';
import ProjectListSection from '../components/Pages/ProjectListPage/ProjectListSection';

import Enquiry from '../components/Pages/ProjectListPage/Enquiry';

import project_bg from '../assets/images/breadcrumb/project-bg.jpg'
import { useDispatch } from 'react-redux';
import { updateActiveMenu } from '../redux/actions/general';
import { useLocation}  from 'react-router-dom';

function ProjectList() {
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(updateActiveMenu("Project"))
  }, [])
  
  return (
    <>
     <TopBar/>
      <Breadcrumb bg={project_bg} title={"Projects"} destination={"Projects"} />
      <BreifDetail/>
      <ProjectListSection />
      <Enquiry/>
     <Footer/>
    </>
  )
}

export default ProjectList;



import React from "react";
import styled from "styled-components";
import { Link } from 'react-router-dom';
import axios from "axios";

import india from "../../assets/images/footer/india.png";
import uae from "../../assets/images/footer/uae.png";
import logo from "../../assets/images/footer/skyline-logo.png";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { useState } from "react";
import { useEffect } from "react";
import { listAwards, viewContactDetails } from "../../axios/api";
import AccoladesLogo from '../../assets/images/footer/accolades_logo.png';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  height:'80%',
  transform: 'translate(-50%, -50%)',
  border:'none',
};


function Footer() {
  const [certificates, setcertificates] = useState('');
  const [awards, setawards] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [data, setdata] = useState({});

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const linkStyles = {
    textDecoration: 'none',
    color: '#fff',
    /* Set the same styles for hover to remove the hover effect */
    ':hover': {
      textDecoration: 'none',
      color: '#fff',
    },
  };

  useEffect(() => {
    axios.get(listAwards)
      .then((response)=>{
       const { StatusCode,data}  = response.data.app_data;
       
       if(StatusCode === 6000){
          setawards(data.results)
       }else if(StatusCode === 6001){
          setawards([])
       }
      })
    axios.get(viewContactDetails)
    .then((response)=>{
      const { StatusCode,data}  = response.data.app_data;
      
      if(StatusCode === 6000){
        setdata(data) 
      }else if(StatusCode === 6001){
        setdata({})
      }
    })
  }, [])

  const setModalImg = (img) => {
    setcertificates(img);
  }
  
  return (
    <>
        <div >
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <ImageDiv>
              <img src={certificates} alt="" />
            </ImageDiv>
          </Box>
        </Modal>
      </div>
      <Container id="contact">
        <Wrapper className="wrapper">
          <Content>
            <CoverLogo>
              <h4>REACH OUT TO US</h4>
            </CoverLogo>
            <ContactSection>
              <SectionCover>
                <SecTitle>Phone number</SecTitle>
                <Cover>
                  <Icon>
                    <img src={india} alt="" />
                  </Icon>
                  <Anchor>
                    <Tag href={`tel:+91${data?.phone?.replace(' ','')}`}>+91 {data?.phone} |</Tag>
                    <Tag href={`tel:+914844162222}`}>+91 484 416 2222 </Tag>
                  </Anchor>
                </Cover>
                <Cover>
                  <Icon>
                    <img src={uae} alt="" />
                  </Icon>
                  <Anchor>
                    <Tag href="tel:+971 554 552 975 ">+971 554 552 975</Tag>
                  </Anchor>
                </Cover>
              </SectionCover>
              <SectionCover>
                <SecTitle>Email Address</SecTitle>
                <CoverMail>
                  <Icon className="cou">
                    <i class="ri-mail-fill"></i>
                  </Icon>
                  <Tag href={`mailto:${data?.email}`}>
                      {data?.email}
                  </Tag>
                </CoverMail>
              </SectionCover>
              <SectionCovers className="loc">
                <SecTitle>Location</SecTitle>
                <CoverMail>
                  <Icon className="cou">
                    <i class="ri-map-pin-fill"></i>
                  </Icon>
                  <Tag className="address">
                    {data?.address}
                  </Tag>
                </CoverMail>
              </SectionCovers>
              <SectionCovers className="loc">
                <SecTitle>Social media</SecTitle>
                <Icons>
                  <a href={data?.facebook_url}>
                    <i class="ri-facebook-circle-fill"></i>
                  </a>
                  <a href={data?.instagram_url}>
                    <i class="ri-instagram-fill"></i>
                  </a>
                  <a href={`https://api.whatsapp.com/send/?phone=91${data?.whatsapp_url}&text&type=phone_number&app_absent=0`}>
                    <i class="ri-whatsapp-fill"></i>
                  </a>
                  <a href={data?.youtube_url}>
                    <i class="ri-youtube-fill"></i>
                  </a>
                </Icons>
              </SectionCovers>
            </ContactSection>
          </Content>
          <Under>
            <Logo href="https://www.skylinebuilders.com/">
              <span>Building Blocks of Trust</span>
              <ImageLogo>
                <img src={logo} alt="image" />
              </ImageLogo>
            </Logo>
            <Awards>
              <TextAward>AWARDS & AFFILIATIONS</TextAward>
              <CoverAward>
                {
                  awards.map((obj)=>{
                    if(obj.certificate){
                      return(
                        <AwardContainer onClick={()=>{
                          setModalImg(obj.certificate);
                          setOpen(true);
                        }}>
                          <img src={obj.image} alt="image" />
                        </AwardContainer>
                        )
                    }else{
                      return(
                        <AwardContainer> 
                        <img src={obj.image} alt="image" />
                        </AwardContainer>
                      )
                    }
                  })
                }
              </CoverAward>
            </Awards>
          </Under>
        </Wrapper>
      </Container>
      <Banner>
        <div className="wrapper flex">
        <Right>
          <p>Copyright © 2024 <span>Skyline Hectares</span> All Rights Reserved</p>
          <p className="second">Privacy Policy <span>|</span> Terms & Conditions</p>
          </Right>
        <Left>
          <div>
            <p>Design And Developed By </p>
            <p className="second"><a href="https://accoladesintegrated.com/" target="_blank">Accolades Integrated</a></p>
          </div>
          <img src={AccoladesLogo} alt="" />
        </Left>
        </div>
       
        {/* <LinkCover>
          <span> <Link style={linkStyles} to={'/disclaimer'}>Disclaimer</Link> | <Link style={linkStyles} to={'/privacy-policy'}>Privacy Policy</Link> </span>
        </LinkCover>
        <span>
          Skyline Builders © All Rights Reserved | Designed by <a target="_blank" style={{textDecoration:'none',color:'white'}} href="https://accoladesintegrated.com/">
            <CompanyLogo src={AccoladesLogo}/>
          </a>
        </span> */}
      </Banner>
    </>
  );
}

export default Footer;

const Container = styled.div`
  padding: 30px 0px;
  background: #f8fbfe !important;
  color: rgb(28 41 81);
`;
const Wrapper = styled.div`
  @media all and (max-width: 1080px) {
    width: 90%;
  }
  @media all and (max-width: 830px) {
  width: 98%;
  }
`;
const LinkCover = styled.div`
  margin-bottom: 10px;
`;
const Banner = styled.div`
  background-color: rgb(248, 251, 254) ;
  color: black;
  padding: 30px 0px 10px 0px;
  font-size: 14px;
  .flex{
    display: flex;
    justify-content: space-between;

  }
  span , a{
  color: #0C7479;
  text-decoration: none;
 }
 .second{
  margin-top: -10px;

 }
 @media (max-width:649px){
  .flex{
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  font-size: 10px;
 }
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width:649px){
  width: 100%;
 }
 
`
const Left = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
 img{
  width: 50px;
  margin-top: -14px;

 }
 div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
 }
 @media (max-width:649px){
  margin-top: -10px;
  width: 100%;
  flex-direction: column;
  div{
    flex-direction: row;
    gap: 10px;
  }
  img{
  width: 30px;
  margin-top: -14px;

 }
 }
`
const ImageLogo = styled.div`
  width: 75%;
  img {
    width: 100%;
    display: block;
  }
`;

const ImageDiv = styled.div`
  height :100%;
  img {
    height: 100%;
    display: block;
  }
`
const CoverLogo = styled.div`
  text-align: center;
  @media all and (max-width: 1080px) {
    margin-right: 50px;
  }
  @media all and (max-width: 640px) {
    margin-bottom: 50px;
  }
  @media all and (max-width: 480px) {
    margin-bottom: 20px;
    margin-right: 0;
  }
 
   h4 {
    font-size: 18px;
    font-weight: 500;
    @media all and (max-width: 1080px) {
      font-size: 15px;
    }
    @media all and (max-width: 975px) {
      font-size: 13px;
      width: 125px;
    }
    @media all and (max-width: 768px) {
    font-size: 15px;
    width: auto;
    margin-bottom: 27px;
  }
  @media all and (max-width: 480px){
    font-size: 18px;
  }
  }

`;
const Under = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  align-items: baseline;
  @media all and (max-width: 768px) {
    display:block
  }
  @media all and (max-width: 480px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;
const Logo = styled.a`
  text-decoration: none;
  width: 15%;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  span {
    color: rgb(28 41 81);
    font-size: 14px;
    margin-bottom: 15px;
  }
  &::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 130px;
    background: #b3adad;
    right: -100px;
    top: 0;
    @media all and (max-width: 640px) {
      right: -50px;
    }
    @media all and (max-width: 768px) {
    display:none;
   }
    @media all and (max-width: 480px) {
      display: none;
    }
    @media (max-width: 768px){
    display: none;
    }
    @media all and (max-width: 975px) {
    right: -60px;
    }
    @media all and (max-width: 830px) {
      right: -45px;
    }
  }
  @media all and (max-width: 830px) {
      width: 18%;
    }
  @media all and (max-width: 768px) {
      width: 30%;
  }
  @media all and (max-width: 580px) {
    width: 37%;
    margin-left: 20px;
  }
  @media all and (max-width: 480px) {
    width: 60%;
    margin:0 auto;
  }
`;
const AwardContainer = styled.div`
  width: 70px;
  margin-right: 60px;
  &:last-child {
    margin-right: 0;
  }
  @media all and (max-width: 975px) {
    margin-right:89px;
  }
  @media all and (max-width: 830px) {
    margin-right: 65px;
  }
  img {
    display: block;
    width: 100%;
  }
`;

const CoverAward = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media all and (max-width: 975px){
  justify-content: center;
  }
  @media all and (max-width: 768px) {
    justify-content: space-around;
  }
`;

const TextAward = styled.div`
  margin-bottom: 10px;
  text-align: left;
  font-size: 18px;

  @media all and (max-width: 768px) {
      margin-left: 30px;
    }
  @media all and (max-width: 580px) {
    font-size: 18px;
    text-align: center;
    margin-left: 3px;
  }
`;
const Awards = styled.div`
  width: 73%;
  @media all and (max-width: 1080px) {
    width: 66%;
  }
  @media all and (max-width: 975px) {
    width: 75%;
  }
  @media all and (max-width: 768px) {
    width:100%;
    margin-top: 25px;
  }
  @media all and (max-width: 480px) {
   width: 73%;
  }
  @media all and (max-width: 480px) {
    width: 95%;
    margin-left: 10px;
  }
`;

const CoverMail = styled.div`
  display: flex;
  align-items: baseline;
  @media all and (max-width: 768px) {
  align-items: center;
  }
`;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid grey;
  padding-bottom: 30px;
  @media all and (max-width: 768px) {
    display:block
  }
  @media all and (max-width: 640px) {
    flex-direction: column;
  }
  @media all and (max-width: 480px) {
    align-items: flex-start;
  }
`;
const Title = styled.h2`
  color: rgb(28 41 81) !important;
  font-size: 24px;
`;
const ContactSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
  width: 75%;
  @media all and (max-width: 1080px) {
    width: 79%;
    justify-content: center;
  }

  @media all and (max-width: 768px) {
    width: 94%;
    margin: 0px auto;
  }
`;
const SectionCovers = styled.div`
  width: 50%;
  &.loc {
    margin-bottom: 0;
    @media all and (max-width: 1080px) {
      margin-top: 12px;
    }
    @media all and (max-width: 480px) {
      margin-top: 0;
      margin-bottom: 30px;
    }
  }
  margin-bottom: 50px;
  @media all and (max-width: 1080px) {
    width: 50%;
  }
  @media all and (max-width: 580px) {
    width: 100%;
    margin-bottom: 30px;
  }
`;
const SectionCover = styled.div`
  width: 50%;
  &.loc {
    margin-bottom: 0;
    @media all and (max-width: 1080px) {
      margin-top: 50px;
    }
    @media all and (max-width: 480px) {
      margin-top: 0;
      margin-bottom: 30px;
    }
  }
  margin-bottom: 50px;
  @media all and (max-width: 1080px) {
    width: 50%;
    margin-bottom: 0px;
  }
  @media all and (max-width: 580px) {
    width: 100%;
    margin-bottom: 30px;
  }
`;
const SecTitle = styled.div`
  color: #777;
  margin-bottom: 10px;
  font-size: 14px;
`;
const Cover = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
`;
const Icon = styled.div`
  width: 30px;
  font-size: 20px;
  margin-right: 8px;
  &.cou {
    width: unset;
  }
  img {
    display: block;
    width: 100%;
  }
`;
const Anchor = styled.div`
  display: flex;
`;
const Tag = styled.a`
  margin-right: 5px;
  text-decoration: none;
  color: rgb(28 41 81);
  font-size: 16px;
  @media all and (max-width: 1080px) {
    font-size: 14px;
  }
  &.address {
    @media all and (max-width: 480px) {
      width: 70%;
    }
    @media all and (max-width: 1080px) {
      width: 80%;
    }
  }
  &:last-child {
    margin-right: 0;
  }
`;
const Icons = styled.div`
  display: flex;
  i {
    font-size: 28px;
  }
  a {
    text-decoration: none;
    color: rgb(28 41 81) !important;
    margin-right: 10px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    transition: 0.4s ease;
    &:hover {
      transform: scale(1.1);
      transition: 0.4s ease;
    }
  }
`;

// const CompanyLogo = styled.img`
//       width:110px;
//       height:auto;
// `;

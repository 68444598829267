import React, { useEffect } from 'react'
import TopBar from '../components/includes/TopBar';
import Breadcrumb from '../components/common/Breadcrumb';
import axios from 'axios';

import { useDispatch } from 'react-redux';
import { updateActiveMenu } from '../redux/actions/general';

import blog_bg from '../assets/images/breadcrumb/blog-bg.jpg'
import { blogDatas } from '../assets/js/blogsData';
import SingleBlog from '../components/Pages/Blog/SingleBlog';
import styled from 'styled-components';
import Footer from '../components/includes/Footer';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { listBlogs } from '../axios/api';
import Helmet from '../components/common/Helmet';


function Blog() {

  const dispatch = useDispatch();
  const [blogs, setblogs] = useState([]);
  const [pageData, setpageData] = useState({total_pages:2,current_page:1});
  const [state, setstate] = useState(false);
   
   useEffect(() => {
    axios.get(listBlogs,{
      params:{
        page:pageData?.current_page
      }
    })
      .then((response)=>{
       const { StatusCode,data}  = response.data.app_data;
       
       if(StatusCode === 6000){
          setblogs(data.results)
          setpageData((prev)=>(
            {
              total_pages:parseInt(data.num_pages),
              current_page:parseInt(data.page_number)
            }
          ))

       }else if(StatusCode === 6001){
          setblogs([]);
          setpageData((prev)=>(
            {
              total_pages:0,
              current_page:0
            }
          ))
       }
      })
   }, [state])

  useEffect(() => {
    dispatch(updateActiveMenu("Blog"))
  }, [])

  const loadMore = () =>{
    alert("")
    setpageData((prev)=>(
      {
        ...prev,
        current_page: prev.current_page +1
      }
    ))

    setstate(!state)
  };
  
  return (
    <>
    <TopBar/>
    <Breadcrumb bg={blog_bg} title={"BLOGS"} destination={"Blogs"} />
    <Section>
        <Container>
         {blogs?.map((obj,indx)=>{
          if(1===1){
            if(indx%2 != 0){
                return(
                    <SingleBlog format='reverse' data={obj}/>
                ) 
            }else{
                return(
                    <SingleBlog  data={obj}/>
                )
            }
          }
         })}
         {
          pageData?.current_page !== pageData?.total_pages && 
          <LoadMoreButton >
            <button onClick={()=>loadMore()}>Load More</button>
         </LoadMoreButton>
         }
         
        </Container>
    </Section>
    <Footer/>
    </>
  )
}

export default Blog;



const Section = styled.div`
padding:10px 0px ;
`;

const Container = styled.div`
width:var(--section-width-lg);
margin:0 auto;
display: flex;
gap:40px;
flex-direction: column;
margin-bottom: 15px;
`;

const LoadMoreButton = styled.div`
width:100%;
display: flex;
align-items: center;
justify-content: center;
margin:20px 0;

@media(max-width:1200px){
    margin: 0px 0px 20px;
}

button{
    border:1px solid var(--tertiary-cl);
    color : var(--tertiary-cl);
    border-radius:40px;
    padding:7px;
    width:14%;
    background-color:#fff;
    font-family: 'popins_medium';
    font-size:18px;

    @media(max-width:1200px){
      width:16%;
      font-size: 16px;
    }

    @media(max-width:580px){
      width:150px;
    }
}
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import logo from "../../../assets/images/about/skyline-avenue-logo.png";
import { sendCrmData, sendEnquiryApi } from "../../../axios/apiFunctions";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

import location_pic from '../../../assets/images/features/location.svg';
import cent_pic from '../../../assets/images/features/cent.svg';
import plot_pic from '../../../assets/images/features/plot.svg';
import rera_pic from '../../../assets/images/features/rera.svg';
import EnquiryForm from "../../modals/EnquiryForm";

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import EnquireNowButton from "../../button/EnquireNowButton";
import ReCAPTCHA from "react-google-recaptcha";




function Form({ data }) {
  const currentUrl = window.location.href;

  const navigate = useNavigate();
  const [formData, setfromData] = useState({url:currentUrl});
  const [enquiryStatus, setenquiryStatus] = useState('')
  const [loader, setloader] = useState(false)
  const [TimeOutId, setTimeOutId] = useState('');
  const [isModal, setisModal] = useState(false);

  const [captchaToken, setCaptchaToken] = useState(null);

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  useEffect(() => {
    const id = setTimeout(() => {
      setenquiryStatus('')
      clearTimeout(TimeOutId)
    }, 3000)
    setTimeOutId(id)
  }, [enquiryStatus])

  const handleEmail = (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneNumberRegex = /^\d+$/;

    if (!formData?.name || formData?.name.length < 3) {
      setenquiryStatus({ status: false, message: 'Enter a vaild name' })
    } else if (!formData?.email || !emailRegex.test(formData?.email)) {
      setenquiryStatus({ status: false, message: 'Enter a vaild email' })
    } else if (!formData?.phone || !phoneNumberRegex.test(formData?.phone)) {
      setenquiryStatus({ status: false, message: 'Enter a vaild phone number' })
    }else if (captchaToken==="" || captchaToken===null){
      setenquiryStatus({status: false, message:'Please verify your captcha'})
    }
    else {
      formData.id = data.id
      setloader(true)
      sendCrmData(formData)
      let projectName = data?.projectname.replace(" ", "-")
      let slug = data?.slug
      sendEnquiryApi(formData, setenquiryStatus).then((data) => {
        setloader(false)
        const id = setTimeout(() => {
          setenquiryStatus('')
        }, 3000)

        navigate('/' + projectName + '/thank-you', {
          state: {
            access: true,
            slug:slug
          }
        })
        setTimeOutId(id)
      })
      setfromData({})
    }
    setTimeout(() => {
      setenquiryStatus('')
    }, 3000)
  };

  const handleChange = (e) => {
    setfromData({ ...formData, [e.target.name]: e.target.value })
  }
  return (
    <Section>
      <Container>
        <FormSection>
          <FormContainer>
            <Title>Reach Out To Us</Title>
            {enquiryStatus?.status === true ? <p className="success">{enquiryStatus.message}</p> : enquiryStatus?.status === false ? <p className="error">{enquiryStatus.message}</p> : null}
            <Input
              type="text"
              value={formData?.name ? formData.name : ''}
              onChange={handleChange}
              name="name"
              placeholder="Name"
            />
            <Input
              type="email"
              className="email"
              value={formData?.email ? formData.email : ''}
              name="email"
              onChange={handleChange}
              placeholder="Email"
            />
            <Input
              value={formData?.phone ? formData.phone : ''}
              type="number"
              name="phone"
              onChange={handleChange}
              placeholder="Phone number"
            />
            <ReCAPTCHA
              sitekey='6LeDwAIqAAAAAMjjFFFklc_7bXcUBPJIkIQEvUSM'
              onChange={handleCaptchaChange}
            />
            <Button itemType="button" onClick={(e) => handleEmail(e)}>{loader ? <CircularProgress /> : 'Submit'}</Button>
          </FormContainer>
        </FormSection>
        <Wrapper className="wrapper">
          <Content>
            <Details>
              <LogoSection>
                <img src={data?.project_logo} alt="" />
              </LogoSection>
            </Details>
            <AboutSection id="about">
              <TitleAb>About</TitleAb>
              <Descr>
                {
                  data?.about
                }
              </Descr>
            </AboutSection>
          </Content>
        </Wrapper>
      </Container>
      <EnquireButtonArea>
        <EnquireNowButton name={data?.projectname} id={data?.id} bgColor={"--primary-cl"} />
      </EnquireButtonArea>
      <BottomContainer>
        <FeatureContainer>
          <FeatureList>
            <Heading>
              Features
            </Heading>
            <Cards>
              <Card>
                <Img className="first-img">
                  <img src={location_pic} />
                </Img>
                <FeatureTitle>
                  {data?.location}
                </FeatureTitle>
              </Card>
              <Card>
                <Img>
                  <img src={plot_pic} />
                </Img>
                <FeatureTitle>
                  {data?.feature}
                </FeatureTitle>
              </Card>
              <Card>
                <Img>
                  <img src={cent_pic} />
                </Img>
                <FeatureTitle>
                  {data?.cent}
                </FeatureTitle>
              </Card>
              <Card>
                <Img>
                  <img src={rera_pic} />
                </Img>
                <FeatureTitle>
                  {data?.rera_number}
                </FeatureTitle>
              </Card>
            </Cards>
          </FeatureList>
        </FeatureContainer>
        <FormSectionMob>
          <FormContainer>
            <Title>Reach Out Us</Title>
            {enquiryStatus?.status === true ? <p className="success">{enquiryStatus.message}</p> : enquiryStatus?.status === false ? <p className="error">{enquiryStatus.message}</p> : null}
            <Input
              type="text"
              value={formData?.name ? formData.name : ''}
              onChange={handleChange}
              name="name"
              placeholder="Name"
            />
            <Input
              type="email"
              className="email"
              value={formData?.email ? formData.email : ''}
              name="email"
              onChange={handleChange}
              placeholder="Email"
            />
            <Input
              value={formData?.phone ? formData.phone : ''}
              type="number"
              name="phone"
              onChange={handleChange}
              placeholder="Phone number"
            />
              <ReCAPTCHA
              sitekey='6LeDwAIqAAAAAMjjFFFklc_7bXcUBPJIkIQEvUSM'
              onChange={handleCaptchaChange}
            />
            <Button itemType="button" onClick={(e) => handleEmail(e)}>{loader ? <CircularProgress /> : 'Submit'}</Button>
          </FormContainer>
        </FormSectionMob>
        <BrochureInfo>
          <BrochureContainer>
            <ContentArea>
              Dive into the world of premium plots within gated communities and discover the future of luxurious living in Kerala. Download our brochure now to explore the endless possibilities that await you.
              Get a glimpse of meticulously designed layouts, stunning amenities, and the promise of a community that thrives on excellence.
            </ContentArea>
            <DownloadButton>
              <button onClick={() => setisModal(true)}>
                <CloudDownloadIcon />
                DOWNLOAD BROCHURE
              </button>
            </DownloadButton>
            <EnquiryForm isModal={isModal} setModal={setisModal} id={data?.id} project={data?.projectname} slug={data?.slug} type={"Brochure"} />
          </BrochureContainer>
        </BrochureInfo>
      </BottomContainer>
    </Section>
  );
}

export default Form;

const Section = styled.div`
      display: flex;
      flex-direction: column;
`;

const Container = styled.div`
  margin-top: 48px;
  padding: 0 0;
  width: var(--section-width-lg);
  margin: 0 auto;
  display:flex;
  flex-direction: row-reverse;
  gap: 15px;
  padding-top: 25px;
  .success{
    margin: 0;
    padding: 0;
    margin-top: 5px;
    text-align: center;
    color: green;

  }
  .error{
    margin: 0;
    padding: 0;
    text-align: center;
    margin-top: 5px;
    color: red;
  }
  @media(max-width:1400px){
   width:85%;
  }
  @media(max-width:1200px){
   width:90%;
  }
  @media(max-width:1000px){
   flex-direction: column-reverse;
  }
  @media all and (max-width: 680px) {
    padding: 0;
  }
`;
const Wrapper = styled.div`
    width:60%;
    @media(max-width:1000px){
     width:100%;
    }
`;
const Content = styled.div``;
const FormSection = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 1.5rem 5px;
  width: 40%;
  margin: 0 auto;
  @media(max-width:1300px){
    width:45%;
  }
   @media(max-width:1000px){
   width:80%;
   display: none;
  }
  @media(max-width:375px){
   width:95%;
  }
  /* @media all and (max-width: 1280px) {
    padding: 20px;
    flex-wrap: wrap;
    width: 95%;
  }
  @media all and (max-width: 640px) {
    padding: 10px;
    width: 100%;
    margin-top: 30px;
  }
  @media all and (max-width: 480px) {
    border: none;
  } */
`;
const FormSectionMob = styled.div`
  display: none;
  justify-content: space-around;
  padding: 1.5rem 5px;
  width: 40%;
  margin: 0 auto;
  @media(max-width:1300px){
    width:45%;
  }
   @media(max-width:1000px){
   width:85%;
   display: flex;
  }
  @media(max-width:375px){
      width:95%;
  }
  /* @media all and (max-width: 1280px) {
    padding: 20px;
    flex-wrap: wrap;
    width: 95%;
  }
  @media all and (max-width: 640px) {
    padding: 10px;
    width: 100%;
    margin-top: 30px;
  }
  @media all and (max-width: 480px) {
    border: none;
  } */
`
const FormContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border-radius: 26px;
  border: 1px solid #E9E9E9;
  background: #F2F2F2;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.15);
  gap: 25px;
  padding: 25px 10px; 
  @media(min-width:2350px){
    gap:27px;
  }
  .hide{
    display: none !important;
  }
  `
const Title = styled.h4`
    color: #000;
    text-align: center;
    font-family: 'popins_semibold';
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.02px;
`;
const Input = styled.input`
  /* border-radius: 10px !important;
  border: 1px solid rgb(183, 179, 179);
  height: 40px;
  outline: none;
  width: 24%;
  margin-right: 0px; */
  outline: none;
  padding-left: 20px;
  border-radius: 5px;
  border: 1px solid #D0D0D0;
  background: #FFF;
  width: 90%;
  height: 45px;
  @media(min-width:2350px){
    height: 52px;
  }
  &.email {
    @media all and (max-width: 480px) {
      margin-right: 0;
    }
  }
  @media all and (max-width: 1280px) {
    margin-right: 0;
  }
`;
const Button = styled.div`
  background-color:var(--primary-cl);
  color: #fff;
  height: 40px;
  cursor: pointer;
  width: 90%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  /* background: #5EAF5A; */
  span{
    width: 25px !important;
    height: 25px !important;
    color: white;
  }
`;
const Details = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 0px;
  @media all and (max-width: 640px) {
    flex-direction: column;
  }
  @media all and (max-width: 480px) {
    margin: 15px;
  }
`;
const LogoSection = styled.div`
  width: 240px;
  img {
    display: block;
    width: 100%;
  }
  @media(min-width:2350px){
    width:280px;
  }
`;
const Address = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @media all and (max-width: 480px) {
    align-items: baseline;
  }
`;
const Cover = styled.div`
  width: 50%;
  margin-bottom: 50px;
`;
const Icon = styled.div`
  font-size: 2.5rem;
  color: #dc3545;
  text-align: center;
  @media all and (max-width: 480px) {
    font-size: 35px;
    color: #dc3545;
    text-align: center;
  }
`;
const TitleAdd = styled.h3`
  color: #1c2951;
  font-size: 1rem;
  text-align: center;
  font-weight: 700;
  @media all and (max-width: 480px) {
    font-size: 14px;
  }
  @media all and (max-width: 375px) {
    ${(props) => props.last && `
      width:16ch;
      font-size:12px;
    `}
  }
`;
const AboutSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 85%;
    margin: 0 auto;
    @media(max-width:1300px){
      width:90%;
   }
`;

const TitleAb = styled.p`
  color: #818181 !important;
  text-align: start;
  font-family: "poppins-regular";
  font-size: 14px;
  margin: 0;
  position: relative;
  &::after{
    content: "";
    background-color: #818181;
    height: 1px;
    position: absolute;
    width:15%;
    left: 9%;
    top:50%;
    @media(max-width: 600px) {
        left:50px;
    }
  }
`;
const Descr = styled.p`
  margin: 0;
  color:#000000;
  font-size: 15px;
  line-height: 23px;
  margin: 0 auto;
  text-align: justify;
  font-family:'popins_regular';
  margin:16px 0px 25px  0px;
  font-weight: 500;
  @media(min-width:2350px){
    font-size: 18px;
  }
  @media all and (max-width: 850px) {
    margin-bottom: 10px;
  }
  @media all and (max-width: 768px) {
    text-align: justify;
  }
  @media all and (max-width: 480px) {
    width: 100%;
    font-size: 13px;
  }
`;

const DownloadBrochure = styled.button`
    background-color: #dc3545;
    border:none;
    color: #fff;
    height: 40px;
    cursor: pointer;
    width: 150px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline:  none;
    margin: 10px 0px 30px;
    font-family:'popins_regular';
    padding: 5px;
    &:hover{
      background-color: #f44253;
    }
`;

const BottomContainer = styled.div`
 width:100%;
 margin:35px auto;
 display:flex;
 flex-direction:column;
 @media(max-width: 850px) {
      margin:15px auto;
  }
`;

const FeatureList = styled.div`
 width:80%;
 margin:0 auto;
 display:flex;
 justify-content:space-between;
 @media(max-width:1400px){
   width:90%;
}
@media(max-width:1250px){
    flex-direction: column;
}
@media(max-width:1125px){
    width:90%;
}
@media(max-width:1000px){
    width:80%;
}
`;

const BrochureInfo = styled.div`
  background-color:#F2F2F2;
`;

const Card = styled.div`
border-radius: 22px;
border: 1px solid #DFDFDF;
background-color:rgb(255 255 255);
box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.03);
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
height: 185px;
padding:30px;
@media(min-width:2350px){
    padding: 45px;
}
@media(max-width:1480px){
  padding:20px;
}
@media(max-width:1125px){
   padding: 15px;
}
@media(max-width:1000px){
   width:45%;
}
@media(max-width:600px){
   width:75%;
}
@media(max-width:525px){
   width:80%;
}
@media(max-width:375px){
   width:95%;
}
.first-img{
  width:23%;
}
`;

const Img = styled.div`
width:30%;
height:65%;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
img{
  width:100%;
  display:block;
}
`;

const FeatureContainer = styled.div`
  background: linear-gradient(to bottom, #ffffff 50%, #F2F2F2 50%);
  @media(max-width:1000px){
     background: #F2F2F2;;
     padding: 10px 0px;
  }
`;

const FeatureTitle = styled.div`
  margin-top:10px;
  font-size:15px;
  color:#000;
  @media(max-width:1480px){
    font-size:14px;
  }

`;


const Cards = styled.div`
  display:flex;
  gap:20px;
  width:100%;
  justify-content:space-around;
  align-items: center;
  @media(max-width:1000px){
   flex-wrap: wrap;
   gap:25px;
  }
`;

const Heading = styled.p`
    writing-mode:vertical-rl;
    transform: rotate(180deg);
    position:relative;
    margin: 5px 15px;
    font-size: 14px;
    font-family: 'popins_medium';
    color : var(--secondary-cl);
    @media(max-width:1250px){
      writing-mode: horizontal-tb;
      transform: rotate(0deg);
      margin: 15px 5px;
    }
    &::before{
      content:'';
      position:absolute;
      height:50%;
      width:1px;
      background-color:#818181;
      left:50%;
      bottom:10px;
      @media(max-width:1250px){
        height: 1px;
        width:100px;
        left:80px;
      }
    }
`;

const BrochureContainer = styled.div`
   width:80%;
   margin:0 auto;
   display: flex;
   padding: 60px 0px;
   /* padding-left: 20px; */
   @media(max-width:1400px){
      width:90%;
   }
   @media(max-width:1125px){
      flex-direction: column;
      padding-bottom: 40px;
   }
   @media(max-width:375px){
      padding: 10px 0;
    }
`

const ContentArea = styled.div`
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    text-align: justify;
    font-family: "popins_regular";
    @media(min-width:2350px){
    font-size: 18px;
    }
    @media(max-width:1350px){
    width:85%;
    }
    @media(max-width:1125px){
      width:100%;
    }
    @media(max-width:375px){
      padding: 10px 0;
    }
`

const DownloadButton = styled.div`
    width:35%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media(min-width:2350px){
    padding-right: 70px;
    }
    @media(max-width:1125px){
      width:100%;
      justify-content: center;
      padding:20px 0px;
    }
    button{
      border: 1px solid var(--tertiary-cl);
      color:#fff;
      background-color: var(--tertiary-cl);
      padding:10px 30px;
      border-radius: 20px;
      display:flex;
      align-items:center;
      justify-content: center;
      gap: 5px;
    }
`

const EnquireButtonArea = styled.div`
       justify-content:center;
       align-items: center;
       width: 100%;
       display: none;
       @media(max-width:1000px){
           display: flex;
        }
`;
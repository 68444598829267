import React, { useEffect } from 'react'
import styled from 'styled-components';

import Footer from '../components/includes/Footer';
import TopBar from '../components/includes/TopBar';


  
const colors={
    primary:'#4BA647',
    secondary:'#A0A0A0',
    tertiary:'#E53228',
    black:'#1D1C1A',
  }
  

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);
  return (
    <>
  
     <TopBar/>
     <Main>
       <PrivacyPolicyArea>
          <SideHeading1>
            <h6>PRIVACY POLICY</h6>
          </SideHeading1>
          <Content>
            <h4>SKYLINE BUILDERS® ONLINE PRIVACY POLICY</h4>
          <p>
          Online Privacy is a constantly evolving landscape and Skyline Builders® plans to take all reasonable efforts to maintain up to date and effective online privacy standards and practices. 
          We have linked every page of our website to this page for your convenience and you may also contact us at marketing@skylinebuilders.com with any questions or concerns that you may have. 
          As of May 2003, Skyline Builders® has updated its Privacy Policy to give you a better understanding of our Privacy Policy and Practice.
          If you have any questions, please contact marketing@skylinebuilders.com
          </p>
          </Content>
       </PrivacyPolicyArea>
       <Introduction>
          <SideHeading>
            <h6>COPYRIGHT</h6>
          </SideHeading>
          <Content>
          <h4>INTRODUCTION</h4>
          <p>
          We at Skyline Builders® are committed to respecting your online privacy and recognize your need for appropriate protection and management of any personally identifiable information (“Personal Information”) you share with us. 
          Skyline Builders® has established this Online Privacy Policy so that you can understand the care with which we intend to treat your Personal Information. 
          Personal Information means any information that may be used to identify an individual, including, but not limited to, a first and last name, a home or other physical address and an email address or other contact information, 
          whether at work or at home. In general, you can visit Skyline Builders®’ pages without telling us who you are or revealing any Personal Information about yourself. If you choose to provide us with your Personal Information on the Web, 
          we may transfer that Information only within Skyline Builders® or only to Skyline Builders®’ third-party service providers, across borders, and from your country or jurisdiction to other countries or jurisdictions around the world. Skyline Builders® strives to comply with all applicable laws around the globe that are designed to protect your privacy. 
          Although legal requirements may vary from country to country, we intend to adhere to the principles set forth in this Online Privacy Policy even if, in connection with the above, we transfer your Personal Information from your country to countries that may not require an “adequate” level of protection for your Personal Information. In other words, our goal is to provide protection for your Personal Information no matter where that Personal Information is collected, transferred, or retained.
          </p>
          </Content>
       </Introduction>
       <Cookies>
          <SideHeading2>
            <h6>COOKIES INFO</h6>
          </SideHeading2>
          <Content>
          <h4>COOKIES AND OTHER TRACKING TECHNOLOGIES</h4>
          <p>
          Some of our Web pages utilize “cookies” and other tracking technologies. 
          A “cookie” is a small text file that may be used, for example, to collect information about Website activity. 
          Some cookies and other technologies may serve to recall Personal Information previously indicated by a Web user. 
          Most browsers allow you to control cookies, including whether or not to accept them and how to remove them. 
          You may set most browsers to notify you if you receive a cookie, or you may choose to block cookies with your browser, 
          but please note that if you choose to erase or block your cookies, you will need to re-enter your original user ID and password to gain access to certain parts of the Website. 
          Tracking technologies may record information such as Internet domain and host names; Internet protocol (IP) addresses; 
          browser software and operating system types; clickstream patterns; and dates and times that our site is accessed. Our use of cookies and other tracking technologies allows us to improve our Website and your Web experience. 
          We may also analyze information that does not contain Personal Information for trends and statistics. To protect your privacy, we have adopted the following principle:<br />
          &#8226; Notice<br />
          &#8226; Choice<br />
          &#8226; Security<br />
          &#8226; Access/Accuracy
          </p>
          </Content>
       </Cookies>
       <NOTICE>
          <SideHeading>
            <h6>NOTICE</h6>
          </SideHeading>
          <Content>
          <h4>NOTICE</h4>
          <p>
          Where Skyline Builders® collects Personal Information on the web, we intend to post a purpose statement that explains why Personal Information will be collected and whether we plan to share such Personal Information outside of Skyline Builders®’ or those working on Skyline Builders®’ behalf. 
          Skyline Builders® does not intend to transfer Personal Information without your consent to third parties who are not bound to act on Skyline Builders®’ behalf unless such transfer is legally required.
          </p>
          </Content>
       </NOTICE>
     </Main>

     <Footer/>
     </>
  )
}

const Main = styled.div`
    width:90%;
    font-family: "popins_regular";
    padding:25px 150px;
    margin: 25px auto;
   @media(max-width:1024px){
      padding:25px 0px;
    }
    @media(max-width:700px){
      padding:0px;
    }
    `

const PrivacyPolicyArea = styled.div`
    display: flex;
    margin: 20px auto;
    padding: 25px;
    border-radius:10px;
    box-shadow: 0 4px 54px rgb(173 187 225/30%);
    @media(min-width:2000px){
      padding:50px !important;
    }
    @media  (max-width:700px){
      flex-direction: column;
    }
    @media  (max-width:375px){
      padding: 8px !important;
    }
`
const Introduction = styled.div`
    padding: 25px;
    border-radius:10px;
    margin: 20px auto;
    box-shadow: 0 4px 54px rgb(173 187 225/30%);
    display: flex;
    @media(min-width:2000px){
      padding:50px !important;
    }
    @media  (max-width:700px){
      flex-direction: column;
    }
    @media  (max-width:375px){
      padding: 8px !important;
    }
    `

const Cookies = styled.div`
    padding: 25px;
    margin: 20px auto;
    border-radius:10px;
    box-shadow: 0 4px 54px rgb(173 187 225/30%);
    display: flex;
    @media(min-width:2000px){
      padding:50px !important;
    }
    @media  (max-width:700px){
      flex-direction: column;
    }
    @media  (max-width:375px){
      padding: 8px !important;
    }
    `

const NOTICE = styled.div`
    padding: 25px;
    margin: 20px auto;
    border-radius:10px;
    box-shadow: 0 4px 54px rgb(173 187 225/30%);
    display: flex;
    @media(min-width:2000px){
      padding:50px !important;
    }
    @media  (max-width:700px){
      flex-direction: column;
    }
    @media  (max-width:375px){
      padding: 8px !important;
    }
    `

const SideHeading = styled.div`
    display: flex;
    align-items: center;
    justify-content:center;
    width:15%;
    @media(min-width:2250px){
      width: 55% !important;
    }
    @media(min-width:1700px){
      width: 80%;
    }
    @media(max-width:700px){
      transform: rotate(0deg);
      width: 25%;
    }
    @media  (max-width:500px){
      text-decoration:underline;
    }
    h6{
      transform: rotate(270deg);
      font-size: 13px;
      font-family:'popins_regular';
      color: ${colors.secondary};
      width: 100%;
      text-align: end;
      position: relative;
      @media(min-width:2250px){
       width: 55% !important;
      }
      @media(min-width:1700px){
       width: 80%;
      }
      @media  (max-width:700px){
        transform: rotate(0deg);
      }
      @media  (max-width:500px){
        text-decoration:underline;
      }
      &::after{
        content: "";
        position: absolute;
        display: block;
        width: 50%;
        height: 1.5px;
        background: rgb(160, 160, 160);
        margin: 10px 0px;
        left: 0px;
        top: -4px;
        @media(max-width:800px){
        left: -30px;
        }
        @media(max-width:700px){
        width: 37%;
        left: 10px;
        }
        @media(max-width:620px){
        width: 23%;
        left: 10px;
        }
        @media(max-width:500px){
        display: none !important;
        }
      }
    }
    `
const SideHeading1 = styled.div`
display: flex;
align-items: center;
justify-content:center;
width:15%;
@media(min-width:2250px){
  width: 55% !important;
}
@media(min-width:1700px){
  width: 80%;
}
@media  (max-width:700px){
  transform: rotate(0deg);
  width: 30%;
}
@media  (max-width:500px){
  text-decoration:underline;
}
h6{
  transform: rotate(270deg);
  font-size: 13px;
  font-family:'popins_regular';
  color: ${colors.secondary};
  width: 100%;
  text-align: end;
  position: relative;
  @media(min-width:2250px){
    width: 55% !important;
  }
  @media(min-width:1700px){
    width: 80%;
  }
  @media  (max-width:700px){
    transform: rotate(0deg);
  }
  @media  (max-width:500px){
    text-decoration:underline;
  }
  &::after{
    content: "";
    position: absolute;
    display: block;
    width: 30%;
    height: 1.5px;
    background: rgb(160, 160, 160);
    margin: 10px 0px;
    left: 0px;
    top: -4px;
    @media(max-width:700px){
    width: 37%;
    left: 10px;
    }
    @media(max-width:620px){
    width: 23%;
    left: 10px;
    }
    @media(max-width:500px){
    display: none !important;
    }
  }
}
`
const SideHeading2 = styled.div`
    display: flex;
    align-items: center;
    justify-content:center;
    width:15%;
    @media(min-width:2250px){
        width: 55% !important;
      }
      @media(min-width:1700px){
        width: 80%;
      }
      @media  (max-width:700px){
        transform: rotate(0deg);
        width: 30%;
      }
      @media  (max-width:500px){
        text-decoration:underline;
      }
    h6{
      transform: rotate(270deg);
      font-size: 13px;
      font-family:'popins_regular';
      color: ${colors.secondary};
      width: 100%;
      text-align: end;
      position: relative;
      @media(min-width:2250px){
       width: 55% !important;
      }
      @media(min-width:1700px){
       width: 80%;
      }
      @media  (max-width:700px){
        transform: rotate(0deg);
      }
      @media  (max-width:500px){
        text-decoration:underline;
      }
      &::after{
        content: "";
        position: absolute;
        display: block;
        width: 40%;
        height: 1.5px;
        background: rgb(160, 160, 160);
        margin: 10px 0px;
        left: 0px;
        top: -4px;
        @media(max-width:800px){
        left: -40px;
        }
        @media(max-width:700px){
        width: 37%;
        left: 10px;
        }
        @media(max-width:620px){
        width: 23%;
        left: 10px;
        }
        @media(max-width:500px){
        display: none !important;
        }
      }
    }
    `
const Content = styled.p`
  width: 80%;
  @media(max-width:1024px){
    padding: 10px;
  }
  @media(max-width:700px){
    width: 100%;
  }
  @media(max-width:425px){
    padding: 4px 0;
  }
  h4{
    font-family: 'popins_semibold';
    color: ${colors.primary};
    margin: 25px 0px;
    @media(max-width:500px){
      font-size:18px;
    }
  }
  p{
    font-family: 'popins_light';
    color: ${colors.black};
    font-size: 16px;
    @media(max-width:500px){
    font-size:14px;
    }
  }

`


export default PrivacyPolicy
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CircularProgress from "@mui/material/CircularProgress";

import axios from 'axios';

import { downloadBrochureApi, downloadBrochureApi1, sendCrmData,sendEnquiryUrl } from "../../axios/apiFunctions";
import ReCAPTCHA from "react-google-recaptcha";

function EnquiryForm({ isModal, setModal, id, other = null, type,project ,slug}) {
  const currentUrl = window.location.href;

  const [name, setname] = useState('')
  const [email, setemail] = useState('')
  const [phone, setphone] = useState('')
  const [error, seterror] = useState('')
  const [loading, setloading] = useState(false);
  const [success, setsuccess] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  
  const navigate = useNavigate();

  useEffect(() => {
    seterror('');
  }, [name, email, phone])

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    const crmSentData = {
      name,
      email,
      phone,
    }
    formData.append('name', name);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('url', currentUrl);
    formData.append('id', id);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneNumberRegex = /^\d+$/;

    if (!formData.get('name') || formData.get('name').length < 3) {
      seterror('Enter a vaild name')
    } else if (!formData.get('email') || !emailRegex.test(formData.get('email'))) {
      seterror('Enter a vaild email')
    } else if (!formData.get('phone') || !phoneNumberRegex.test(formData.get('phone'))) {
      seterror('Enter a vaild phone')
    } else if (captchaToken==="" || captchaToken===null){
      seterror('Please verify your captcha')
    }
    else {
      setloading(true);
      if (type === "Enquiry") {
        sendCrmData(crmSentData)
        axios.post(sendEnquiryUrl, formData).then((response) => {
          const data = response.data.app_data.data;
          switch (data.title) {
            case 'Success':
              setsuccess("Enquire sent successfully !")
              setloading(false);
              setTimeout(() => {
                setsuccess(false);
                setModal(false);
              }, 3500);
              if (other) {
                navigate('/thank-you');
              }
              navigate('/' + other.replace(" ", "-") + '/thank-you', {
                state: {
                  access: true,
                  slug:slug
                }
              })
              break;
            case 'Failed':
              seterror("Something went wrong !! Try after some time");
              setTimeout(() => {
                seterror('')
              }, 3500);
              break;

          }
        })
      } else {
        sendCrmData(crmSentData)
       
        downloadBrochureApi(formData).then((response) => {
          if (response.status === 200) {
            setname('')
            setemail('')
            setphone('')
            seterror('')
            setloading(false);
            setsuccess("Enquire sent successfully !")

            setTimeout(() => {
              setsuccess(false);
              setModal(false);
            }, 3500);

          } else {
            seterror("Something went wrong !! Try after some time")
            setname('')
            setemail('')
            setphone('')
            seterror('')
            setloading(false);
            setTimeout(() => {
              seterror('')
            }, 3500);

          }
        })
        let projectName = project.replace(" ", "-")
        navigate('/' + projectName + '/thank-you', {
          state: {
            access: true,
            slug:slug
          }
        })
      }
    }
  }

  return (
    <MainContainer>
      {isModal ? <Overlay onClick={() => setModal(false)}></Overlay> : ""}

      <BackContainer style={{ transform: isModal && "scale(1,1)" }}>
        <Modal type="textarea">
          <Container>
            <FormSection>
              <CloseBtn onClick={() => setModal(false)}>
                <HighlightOffIcon />
              </CloseBtn>
              <Title>
                {type === "Enquiry" ? 'Enquire Now' : 'Brochure Download'}
              </Title>
              {
                success ? (
                  <Success>
                    {success}
                  </Success>

                ) : (
                  error && (
                    <Error>
                      {error}
                    </Error>
                  )
                )
              }
              <Input
                type="text"
                name="name"
                placeholder="Name"
                value={name ? name : ''}
                onChange={(e) => setname(e.target.value)}
              />
              <Input
                type="email"
                className="email"
                name="email"
                placeholder="Email"
                value={email ? email : ''}
                onChange={(e) => setemail(e.target.value)}
              />
              <Input
                type="number"
                name="phone"
                placeholder="Phone number"
                value={phone ? phone : ''}
                onChange={(e) => setphone(e.target.value)}
              />
              <ReCAPTCHA
                sitekey='6LeDwAIqAAAAAMjjFFFklc_7bXcUBPJIkIQEvUSM'
                onChange={handleCaptchaChange}
              />
              <Button onClick={() => handleSubmit()} itemType="button">{loading ? <CircularProgress /> : type === "Enquiry" ? 'Submit' : 'Download brochure'}</Button>
            </FormSection>
          </Container>
        </Modal>
      </BackContainer>

    </MainContainer>
  );
}
export default EnquiryForm;

const BackContainer = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  margin: 0 auto;
  right: 0;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
`;
const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0px;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 75%;
  /* max-width: 350px; */
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  transition: 0.5s;
  z-index: 101;
  border-radius: 5px;
  /* background: #fff; */
  @media(max-width:400px){
    width:95%;
  }
`;
const MainContainer = styled.div``;

const Container = styled.div`
  height: 100vh;
  width: 100%;
  /* background-color: var(--blue); */
  backdrop-filter: blur;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: 1px solid rgb(194, 190, 190);
  border-radius: 20px;
  padding: 1.5rem 5px;
  width: 50%;
  margin: 0 auto;
  background-color:#fff;
  gap:15px;
  position: relative;
  @media all and (max-width: 1280px) {
    padding: 20px;
    flex-wrap: wrap;
    width: 95%;
  }
  @media all and (max-width: 640px) {
    padding: 10px;
    width: 100%;
    margin-top: 30px;
  }
  @media all and (max-width: 480px) {
    border: none;
  }
`;
const Title = styled.div`
  color: rgb(86, 82, 82);
  text-transform: uppercase;
  font-weight: 400;
  font-size: 18px;
  font-family: 'popins_bold';
  width: 100%;
  display:flex;
  justify-content: center;
  align-items:center;
  @media all and (max-width: 980px) {
    font-size: 18px;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  @media all and (max-width: 640px) {
    /* margin-bottom: 20px; */
  }
  @media all and (max-width: 480px) {
    /* margin-right: 30px; */
  }
`;
const Input = styled.input`
  border-radius: 10px !important;
  border: 1px solid rgb(183, 179, 179);
  height: 40px;
  outline: none;
  width: 80%;
  padding-left: 20px;
  margin:0 auto;
`;
const Button = styled.div`
  background-color: #dc3545;
  border-color: #dc3545 #dc3545 #bd2130;
  color: #fff;
  height: 40px;
  cursor: pointer;
  width: 175px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    width: 25px !important;
    height: 25px !important;
    color: white;
  }
  @media all and (max-width: 1280px) {
    width: 100px;
  }
  @media all and (max-width: 980px) {
    margin-bottom: 10px;
    width: 46%;
    margin-right: 10px;
  }
  @media all and (max-width: 640px) {
    width: 70%;
  }
  @media all and (max-width: 480px) {
    margin-right: 0;
    height: 35px;
  }
`;

const CloseBtn = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    svg{
      color: #dc3545;
    }
`;

const Error = styled.p`
    color: #dc3545;
    font-family: 'popins_light';
    padding: 0;
    margin: 0;
`;

const Success = styled.p`
  color: #2da00a;
  font-family: 'popins_light';
  padding: 0;
  margin: 0;
`;
import React, { useEffect } from 'react';
import styled from 'styled-components';
import TopBar from '../components/includes/TopBar';
import Footer from '../components/includes/Footer';


const colors={
  primary:'#4BA647',
  secondary:'#A0A0A0',
  tertiary:'#E53228',
  black:'#1D1C1A',
}

function Disclaimer() {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);

  return (
    <>
     <TopBar/>

     <Main>
       <DisclaimerArea>
          <SideHeading>
            <h6>DISCLAIMER</h6>
          </SideHeading>
          <Content>
            <h4>DISCLAIMER</h4>
          <p>
            The information provided in this website is not in exhaustive, but inclusive. This will not constitute nor can be construed as a valid offer or any kind of invitation to an offer.
            This is purely for information only and is without warranties of any kind, either expressed or implied. Skyline Builders® disclaim all warranties, expressed or implied, including, but not
            limited to warranties of merchantability and fitness for a particular purpose. Skyline Builders® exclusively reserve the rights to make changes to this website and also for this legal disclaimer,
            in any manner, whatsoever, at their sole discretion.
          </p>
          </Content>
       </DisclaimerArea>
       <CopyRight>
          <SideHeading>
            <h6>COPYRIGHT</h6>
          </SideHeading>
          <Content>
          <h4>COPYRIGHT</h4>
          <p>
          The information provided in this website is not in exhaustive, but inclusive. This will not constitute nor can be construed as a valid offer or any kind of invitation to an offer. 
          This is purely for information only and is without warranties of any kind, either expressed or implied. Skyline Builders® disclaim all warranties, expressed or implied, including, but not
          limited to warranties of merchantability and fitness for a particular purpose. Skyline Builders® exclusively reserve the rights to make changes to this website and also for this legal disclaimer, in any manner, whatsoever, at their sole discretion.
          </p>
          </Content>
       </CopyRight>
     </Main>

     <Footer/>
    </>
  )
}

const Main = styled.div`
    width:90%;
    font-family: "popins_regular";
    padding:25px 150px;
    margin: 25px auto;
    @media  (max-width:1024px){
      padding:25px 0px;
    }
    @media  (max-width:700px){
      padding:0px;
    }
`

const DisclaimerArea = styled.div`
    display: flex;
    margin: 25px auto;
    padding: 25px;
    border-radius:10px;
    box-shadow: 0 4px 54px rgb(173 187 225/30%);
    @media(min-width:2000px){
       padding:50px !important;
    }
    @media  (max-width:700px){
      flex-direction: column;
    }
    @media  (max-width:375px){
      padding: 8px !important;
    }
`

const CopyRight = styled.div`
    padding: 25px;
    border-radius:10px;
    box-shadow: 0 4px 54px rgb(173 187 225/30%);
    display: flex;
    @media  (min-width:2000px){
      padding:50px
    }
    @media  (max-width:700px){
      flex-direction: column;
    }
    @media  (max-width:375px){
      padding: 8px !important;
    }
`

const SideHeading = styled.div`
    display: flex;
    align-items: center;
    justify-content:center;
    width:15%;
    @media screen and (max-width:1024px){
    width: 21%;
    }
    @media(max-width:768px){
    width: 26%;
    }
    h6{
      transform: rotate(270deg);
      font-size: 13px;
      font-family:'popins_regular';
      color: ${colors.secondary};
      width: 100%;
      text-align: end;
      position: relative;
      @media(min-width:2250px){
       width: 55% !important;
      }
      @media(min-width:1700px){
       width: 80%;
      }
      @media  (max-width:700px){
        transform: rotate(0deg);
      }
      @media  (max-width:500px){
        text-decoration:underline;
      }
      &::after{
        content: "";
        position: absolute;
        display: block;
        width: 50%;
        height: 1.5px;
        background: rgb(160, 160, 160);
        margin: 10px 0px;
        left: 0px;
        top: -4px;
        @media(max-width:700px){
        width: 37%;
        left: 10px;
        }
        @media(max-width:620px){
        width: 23%;
        left: 10px;
        }
        @media(max-width:500px){
        display: none !important;
        }
      }
    }
`

const Content = styled.p`
  width: 80%;
  @media(max-width:1024px){
    padding: 10px;
  }
  @media(max-width:700px){
    width: 100%;
  }
  @media(max-width:425px){
    padding: 4px 0;
  }
  h4{
    font-family: 'popins_semibold';
    color: ${colors.primary};
    @media(max-width:500px){
    font-size:18px;
    }
  }
  p{
    font-family: 'popins_light';
    color: ${colors.black};
    font-size: 16px;
    @media(max-width:500px){
    font-size:14px;
    }
  }
`

export default Disclaimer;
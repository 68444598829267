import React, { useEffect, useState } from "react";
import axios from "axios";
import { listProjectNames } from "../../axios/api";
import { Route, Routes } from "react-router-dom";
import ThankYou from "../../Pages/ThankYou";

function ThankYouRouter() {
  const [projects, setprojects] = useState([]);

  useEffect(() => {
    axios.get(listProjectNames).then((response) => {
      const { StatusCode, data } = response.data.app_data;

      if (StatusCode === 6000) {
        setprojects(data);
      } else if (StatusCode === 6001) {
        setprojects([]);
      }
    });
  }, []);
  return (
    <Routes>
      {projects?.map((obj) => {
        return (
          <Route
            path={"/" + obj?.project_name.replace(" ", "-") + "/thank-you"}
            element={<ThankYou />}
          />
        );
      })}
    </Routes>
  );
}

export default ThankYouRouter;

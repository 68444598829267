import React from 'react'
import styled from 'styled-components';

function BlogImage({img,slug}) {
  return (
    <Section>
      <ImageContainer bg={img}>
        <img src={img} alt="" />
      </ImageContainer>
      <BreadcrumbNav>
         <p>
         Home > Blog > {slug}
         </p>
      </BreadcrumbNav>
    </Section>
  )
}

export default BlogImage;


const ImageContainer = styled.div`
width: 100%;
height: 450px;
overflow: hidden;

@media(min-width:2250px){
  height: 650px;
}

@media(max-width:580px){
  height: 250px;
}

/* ${(props)=>props.bg && `background-image: url(${props.bg})`};
background-repeat: no-repeat;
background-size: cover;
background-position:center center; */


img{
    display: block;
    width: 100%;
    height:100%;
}

`;

const Section = styled.div`
display: flex;
flex-direction: column;
align-items: end;
`;

const BreadcrumbNav = styled.p`
display: flex;
width:100%;
padding: 20px;
color: var(--secondary-cl);
font-size:14px;
font-family: 'popins_regular';

@media(max-width:580px){
  padding: 15px 5px;
}

p{
  text-align: end;
  width: 100%;

  @media(max-width:375px){
    font-size: 13px;
  }
}

`;

import React, { useEffect } from "react";
import video from "../../../assets/videos/skyline-kalathipadi-walkthrough.mp4";
import styled from "styled-components";

function ProjectWalkThrough({data}) {
  useEffect(() => {
    if(data?.walkthrough_videos){
      document.getElementById("project").play();
    }
  }, [data]);
  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <Title>Project Walk Through</Title>
          {
            data?.walkthrough_videos &&
            <video id="project" autoplay loop muted controls playsInline>
            {/* <source src={video} type="video/mp4" />
            <source src={video} type="video/webm" />
            <source src={video} type="video/ogg" /> */}
            <source src={data?.walkthrough_videos} type="video/mp4" />
            <source src={data?.walkthrough_videos} type="video/webm" />
            <source src={data?.walkthrough_videos} type="video/ogg" />
          </video>
          }
        </Content>
      </Wrapper>
    </Container>
  );
}

export default ProjectWalkThrough;

const Container = styled.div`
  margin-bottom: 30px;
`;
const Wrapper = styled.div`
@media(max-width:1400px){
  width:90%;
}
`;
const Content = styled.div``;
const Title = styled.p`
  color: var(--secondary-cl) !important;
  font-family: "popins_semibold";
  text-align: center;
  font-size: 13px;
  width: 100%;
  text-align: start;
  margin: 0;
  padding: 0;
  position: relative;
  &::after{
    content:"";
    width:100px;
    height:1px;
    position: absolute;
    background-color:var(--secondary-cl);
    top:55%;
    left:150px;
  }
`;

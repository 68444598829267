import React from 'react'
import styled from 'styled-components';
import { Link, useLocation, useNavigate } from "react-router-dom";

import bg from '../assets/images/thankyou/thankyoubg.jpg';
import logo from '../assets/images/header/skyline-hectares-logo.png';

function ThankYou() {
  const location = useLocation();
  const access = location.state?.access
  const slug = location.state?.slug; 
  const navigate = useNavigate();
  if(access){
    return (
      <Section>
          <Container>
            <TopArea>
                <Logo>
                  <Link to={'/'}>
                      <img src={logo} alt="" />
                  </Link>
                </Logo>
            </TopArea>
            <BottomArea>
               <Heading>
               Thank You
               </Heading>
  
               <Heading1>
               for Reaching Out to Skyline Hectares!!
               </Heading1>
  
               <SubHeading>
               We Appreciate Your Interest! Your form submission has been received and is important to us.
               Our team will be in touch with you shortly to discuss your project needs and answer any questions you may have.
               In the meantime, explore some of our other projects that might inspire your vision
               </SubHeading>
  
               <CtcButton>
               <Link to={slug ? `/project/${slug}` : "/projects"}>
                  <button>
                  {slug? "BACK TO PROJECT":"EXPLORE PROJECTS"}
                  </button>
                  </Link>
               </CtcButton>
  
            </BottomArea>
          </Container>
      </Section>
    )
  }else{
     navigate('/');
  }
}

export default ThankYou;

const Section = styled.div`
background: url(${bg}) 50% 50% no-repeat;
height: 100vh;
width:100%;
display: flex;
align-items:start;
justify-content: center;
position: relative;
overflow: hidden;
&::after{
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: .9;
}
`;

const Container = styled.div`
 width:60%;
 display: flex;
 flex-direction:column;
 align-items: center;
 justify-content:center;
 margin:20px auto;
 z-index: 10;
 @media(max-width:768px){
    width:70%;
  }
  @media(max-width:575px){
    width:90%;
  }
`;

const TopArea = styled.div`
height: 30vh;
width: 100%;
display: flex;
align-items:flex-start;
justify-content:center;
padding: 15px 0px;
@media(max-width:575px){
    height: 25vh;
}
@media(max-width:375px){
    height: 25vh;
}
@media(max-width:385px){
    height: 20vh;
}
`;

const BottomArea = styled.div`
height: 70vh;
width: 100%;
`;

const Logo = styled.div`
  width:15%;
  display: flex;
  align-items:baseline;
  justify-content:center;
  @media(max-width:1440px){
    width:20%;
  }
  @media(max-width:1024px){
    width:25%;
  }
  @media(max-width:768px){
    width:30%;
  }
  @media(max-width:385px){
    width:40%
  }
img{
    width: 100%;
    display: block;
}
`;

const Heading = styled.h2`
  font-family: 'popins_bold';
  color:#fff;
  text-align: center;
`;

const Heading1 = styled.h5`
 font-family: 'popins_regular';
  color:#fff;
  text-align: center;
  margin-bottom: 30px;
  @media(max-width:385px){
    font-size: 17px;
  }
`;

const SubHeading = styled.p`
  font-family: 'popins_regular';
  color: rgb(196 196 196);
  text-align: center;
  font-size: 14px;
`;

const CtcButton = styled.div`
display: flex;
align-items: center;
justify-content:center;

button{
 background-color: var(--tertiary-cl);
 color: #fff;
 outline: none;
 border: none;
 border-radius:30px;
 padding: 10px 30px;
 margin: 20px 0px;
 font-size: 16px;
 font-family: 'popins_regular';
 &:hover{
    background-color: #b0342d;
 }
}

`;


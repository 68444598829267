import React, { useRef } from 'react'
import styled from 'styled-components';
import axios from 'axios';


import downArrow from '../../../assets/images/common/down-arrow.svg';
import { useState } from 'react';
import { useEffect } from 'react';
import { listProjectNames } from '../../../axios/api';
import { sendCrmData, sendEnquiryApi, sendEnquiryUrl } from '../../../axios/apiFunctions';
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from 'react-router-dom';

import bg from '../../../assets/images/banner/form-banner.png'
import ReCAPTCHA from 'react-google-recaptcha';




function EnquiryForm() {
  const currentUrl = window.location.href;

  const [projects, setprojects] = useState([]);
  const [formdata, setformdata] = useState({url:currentUrl})
  const [error, seterror] = useState(false);
  const [loader, setloader] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const navigate = useNavigate()
  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };
  useEffect(() => {
    axios.get(listProjectNames)
      .then((response)=>{
       const { StatusCode,data}  = response.data.app_data;
       
       if(StatusCode === 6000){
          setprojects(data)
       }else if(StatusCode === 6001){
          setprojects([])
       }
      })
   }, [])

   const handleChange = (e)=>{
    seterror(false)
    const { name , value} = e.target
    setformdata({
      ...formdata,
      [name]:value
    })
   };

  //  const callthirdApi = async (formdata) =>{
  //   console.log('woring');
  //   let srd = ''
  //   const Central_Avenue = '669504dd735dafb8fce9b194'
  //   const Sanctuary = '64a3acba398c9e09d6492ac9'
  //   if (formdata.id==='89dfb05c-62c6-4333-8547-956bf867d0a2'){
  //     srd = Central_Avenue
  //   }else if (formdata.id==='d9f1af87-52ce-4f71-9cb2-88a358a8f4bb'){
  //     srd = Sanctuary
  //   }
  //   try {
  //     const res = await axios.post(`https://app.sell.do/api/leads/create?api_key=76f911da3b901009b62fe87a40dc6619&sell
  //           _do[form][lead][name]=${formdata?.name}&sell_do[form][lead][email]=${formdata?.email}&sell_do[form]
  //           [lead][phone]=${formdata?.phone}&sell_do[campaign][srd]=${srd}&sell_do[form][note][content]=test
  //         &sell_do[analytics][utm_source]=Google&sell_do[analytics][utm_campaign]=Online Campaign&sell_do[analytics][campaign_name]=text&sell_do[analytics][utm_medium]=chatbot&
  //         sell_do[analytics][utm_term]=*&sell_do[analytics][utm_content]=*`)
  //     // const res = await axios.post(`https://app.sell.do/api/leads/create?api_key=669504dd735dafb8fce9b194&sell_do[form][lead][name]=${formdata?.name}&sell_do[form][lead][email]=${formdata?.email}&sell_do[form][lead][phone]=${formdata?.phone}&sell_do[campaign][srd]=${srd}&sell_do[form][note][content]=Dialog`);
  //     return
  //   } catch (error) {
  //       console.error('Error making sell.do API call:', error);
  //       return
  //   }
    
  //  } 
   const handleSubmit = ()=>{
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneNumberRegex = /^\d+$/;
    if(!formdata?.id){
        seterror("select a project")
      }else if(!formdata?.name || formdata?.name.length<3){
        seterror('Enter a vaild name')
      }else if(!formdata?.email || !emailRegex.test(formdata?.email)){
        seterror('Enter a vaild email')
      }else if(!formdata?.phone || !phoneNumberRegex.test(formdata?.phone)){
        seterror('Enter a vaild phone number')
      } else if (captchaToken==="" || captchaToken===null){
        seterror('Please verify your captcha')
      }
      else{
        setloader(true)
        sendCrmData(formdata)
        axios.post(sendEnquiryUrl,formdata)
        .then((response)=>{
          setloader(false);
          const { StatusCode,data} = response.data.app_data;
          if(StatusCode === 6000){
             let projectName = projects?.map((project)=>{
               if(formdata.id === project.project_id){
                return project.project_name.replace(" ","-")
               }
             })
              setformdata({}) 
              navigate('/'+projectName+'/thank-you',{
                state:{
                  access:true
                }
              })
          }else{
             seterror(data.message);
             setTimeout(() => {
              seterror(false)
             }, 2000);
          }
        })
      }
   };

  return (
    <Section>
        <ImageDiv>
           <img src={bg} alt="" />
        </ImageDiv>
        <Container>
          {
            error && <Error>{error}</Error>
          }
          <SelectDiv >
            <select name="id" id="" onChange={(e)=>handleChange(e)} required>
              <option selected disabled >Select Project</option>
                        {
                          projects?.map((obj)=>{
                            return(
                              <option value={obj.project_id}>{obj.project_name}</option>
                            )
                          })
                        }
            </select>
          </SelectDiv>

          <InputDiv>
            <input required name='name' type="text" placeholder='Name' value={formdata?.name} onChange={(e)=>handleChange(e)} />
          </InputDiv>

          <InputDiv>
             <input required name='phone' type="text" placeholder='Mobile' value={formdata?.phone} onChange={(e)=>handleChange(e)} />
          </InputDiv>

          <InputDiv>
              <input required name='email' type="email" placeholder='Email' value={formdata?.email} onChange={(e)=>handleChange(e)} />
          </InputDiv>
         <Capcha>
         <ReCAPTCHA className='recapha'
                sitekey='6LeDwAIqAAAAAMjjFFFklc_7bXcUBPJIkIQEvUSM'
                onChange={handleCaptchaChange}
              />
         </Capcha>
           <div className='buttonContainer'>
           <SubmitButton onClick={handleSubmit}>
              {loader ? <CircularProgress/> : "ENQUIRE NOW"}  
          </SubmitButton>
           </div>
        </Container>
    </Section>
  )
}

export default EnquiryForm;

const Section = styled.div`
    display: flex;
    background-color:#fff;
    padding: 0px;
    margin: 0px;
`

const Container = styled.div`
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    width: 50%;
    align-items: flex-start;
    justify-content:center;
    @media(max-width:950px){
      width:80%;
      padding:20px 0px;
      align-items: center;
      .buttonContainer{
        display:flex;
        justify-content:center;
      }
    }
    @media(max-width:580px){
      width:90%;
    }
  
    div{
      width: 80%;
      margin: 0 auto;
      @media(max-width:580px){
        width:90%;
      }
    }
`;

const SelectDiv = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 5px;
   position: relative;
   &::after{
    content: "";
    display: block;
    position: absolute;
    width: 12px;
    top: 9px;
    height: 100%;
    right: 14px;
    background-image: url(${downArrow});
    margin: 10px 0px;
    background-repeat: no-repeat;
    color: rgba(0, 0, 0, 0);
    transition: all .5 ease;
   }
   select:focus  &{
        background-color:red;
    }
   select{
    padding: 5px 10px;
    border: 1px solid var(--secondary-cl);
    border-radius: 15px !important;
    font-family: 'popins_light';
    font-size:14px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    font-family: 'popins_light';
    font-size:14px;
    color:#757c87;
   }
`

const InputDiv = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 5px;
   input{
    width: 100%;
    padding: 5px 10px;
    border: 1px solid var(--secondary-cl);
    border-radius: 15px !important;
    @media(max-width:580px){
    width: 100%;
    }
    &::placeholder{
        font-family: 'popins_light';
        font-size:14px;
    }
   }
`
const Capcha = styled.div`
   padding: 5px;
  @media (max-width: 425px){
    .recapha{
    margin: 0px -30px !important;
  }
}
  
  @media (max-width: 425px){
    .recapha{
    width: 5%;
    margin: 0px -30px !important;
  }
}
`
const SubmitButton = styled.button`
   width: 45%;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 2px;
   background-color: var(--tertiary-cl);
   border: none;
   color: #fff;
   border-radius: 15px !important;
   font-family: 'popins_light';
   font-size:13px;
   height: 36px;
   box-sizing: border-box;
   margin-left: 5px;
  span{
    width: 25px !important;
    height: 25px !important;
    color: white;
  }
   @media(max-width:580px){
    width: 50%;
   }
   @media(max-width:315px){
      width: 98%;
    }
`

const Error = styled.p`
margin:7px 0px;
width: 100%;
text-align: center;
color:red;
`;

const ImageDiv = styled.div`
    width:50%;
    img{
      width:100%;
      display: block;
    }
    @media(max-width:950px){
      display: none;
    }
`;
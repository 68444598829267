import React from 'react'
import styled from 'styled-components';


function Breadcrumb({bg,title,destination}) {
  return (
    <Section>
        <Container>
            <BreadcrumbArea bg={bg}>
              <BreadcrumbText>
                {title}
              </BreadcrumbText>
            </BreadcrumbArea>
            <BreadcrumbNav>
              Home > {destination}
            </BreadcrumbNav>
        </Container>
    </Section>
  )
}

export default Breadcrumb;


const Section = styled.div`
    width:100%;
`;

const Container = styled.div`
width: 100%;
margin:0 auto;
align-items: flex-end;
justify-content:center;
display:flex;
flex-direction: column;
`;

const BreadcrumbArea = styled.div`
height: 400px;
width:var(--section-width-lg);
margin:0px auto;
border-radius:10px;
background-image:url(${(props)=>(props.bg)});
background-repeat: no-repeat;
background-size: cover;
background-position: center center;
position: relative;
display: flex;
align-items: flex-end;
justify-content: end;
@media(min-width:2000px){
  height: 500px;
}
@media(max-width:1024px){
  height: 310px;
}
@media(max-width:580px){
  height: 225px;
}
@media(max-width:375px){
  height: 175px;
}
`;

const BreadcrumbText = styled.h1`
color: white;
font-family: 'popins_extralight';
font-size: 135px;
margin-right: 50px;
@media(max-width:1024px){
  font-size:125px;
}
@media(max-width:580px){
    font-size: 60px;
    margin-right: 30px;
}
@media(max-width:375px){
  font-size: 48px;
  margin-right: 5px;
}
`;

const BreadcrumbNav = styled.p`
display: block;
width:var(--section-width-lg);
padding: 15px;
color: var(--secondary-cl);
font-size:14px;
font-family: 'popins_regular';
text-align: right;
margin: 0 auto;
@media(max-width:580px){
  padding-bottom: 0;
}
`;
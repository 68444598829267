import React, { useState } from 'react'
import styled from 'styled-components';
import EnquiryForm from '../modals/EnquiryForm';

function EnquireNowButton({bgColor,id,name}) {
    const [isModal, setModal] = useState(false);
  return (
    <>
    <ButtonDiv>
        <Button cl={bgColor} onClick={()=>setModal(true)}>
            ENQUIRE NOW
        </Button>
    </ButtonDiv>
    <EnquiryForm isModal={isModal} other={name} id={id} setModal={setModal} type={"Enquiry"}/>
    </>
  )
}

export default EnquireNowButton;

const ButtonDiv = styled.div`
    display: flex;
    align-items:center;
    justify-content: center;
    width: 100%;
    margin: 20px 0px;
    @media(max-width:600px){
       margin: 10px 0px;
    }
`;

const Button = styled.button`
   border-radius : 20px;
   ${(props)=>props.cl && `background-color: var(${props.cl})`};
   padding: 15px 35px;
   text-transform: uppercase;
   font-family:'popins_regular';
   color:#fff;
   outline:none;
   border:none;
`;
import React from "react";
import route from "../../../assets/images/nearby/route.png";
import styled from "styled-components";

function Nearby({data}) {
  return (
    <Container>
      <Wrapper>
        <SubTitle>Easy Access</SubTitle>
        <Content> 
        <Title>Nearby Locations</Title>
          <ImageSection>
            <Route>
              <img src={data.nearby_location} alt="" />
            </Route>
            <Map class="google-maps" dangerouslySetInnerHTML={{__html:data?.map_location_url}}>
            </Map>
          </ImageSection>
        </Content>
      </Wrapper>
    </Container>
  );
}

export default Nearby;

const Container = styled.div`
  padding: 80px 0;
  background: var(--grey-cl);
`;
const Wrapper = styled.div`
      width:80%;
      margin: 0 auto;
      display: flex;
      @media(max-width:1400px){
        width:90%;
      }
      @media(max-width:375px){
        width:95%;
      }
`;
const MapMob = styled.div`
  display: none;
  overflow: hidden;
  width: 100%;
  @media all and (max-width: 768px) {
    display: block;
  }
  
`;
const Content = styled.div`
      display: flex;
      justify-content:center;
      flex-direction: column;
`;
const ImageSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 1080px) {
    flex-direction: column;
    gap:10px;
  }
  @media all and (max-width: 580px) {
    gap:25px;
  }
`;
const Map = styled.div`
  width: 50%;
  overflow: hidden;
  border: 1px solid  var(--secondary-cl);
  iframe{
    height:600px !important;
    width: 100%;
    @media(min-width:1700px){
      height:780px !important;
    }
   }

  @media all and (max-width: 1080px) {
    width: 100%;
  }
  @media all and (max-width: 580px) {
    iframe{
    height:400px !important;
   }
  }
`;
const Route = styled.div`
  width: 50%;
  margin-right: 50px;
  img {
    width: 100%;
    display: block;
  }
  @media all and (max-width: 1280px) {
    width: 50%;
  }
  @media all and (max-width: 1080px) {
    width: 80%;
    margin: 0 auto;
  }
  @media all and (max-width: 980px) {
   margin-right: 50px; 
  }
`;
const SubTitle = styled.p`
  color: var(--secondary-cl) !important;
  text-align: end;
  font-family: "popins_semibold";
  font-size: 13px;
  writing-mode: vertical-lr;
  position: relative;
  transform: rotate(180deg);
  letter-spacing:.5px;
  margin-left:0;
    &::after{
    content:"";
    width:1px;
    height:120px;
    position: absolute;
    bottom:100px;
    left:50%;
    background-color:var(--secondary-cl);
  }
`;
const Title = styled.p`
  color: var(--primary-cl) !important;
  font-family: "popins_semibold";
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 400;
  letter-spacing:.5px;
  text-align: start;
  box-sizing: border-box;
  padding-left: 85px;
  position: relative;
  width: 100%;

  @media(max-width:475px){
    padding-left:25px;
  }

  &::after{
    content: "";
    position: absolute;
    width:70px;
    height:1px;
    background-color:var(--secondary-cl);
    left: 270px;
    top: 50%;
    @media(max-width:475px){
        left:200px;
    }
  }
`;

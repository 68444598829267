import React from 'react'
import styled from 'styled-components';
import axios from 'axios';

import aboutPic from '../../../assets/images/banner/about_banner.png'
import { useState } from 'react';
import { coutersList, viewAbout } from '../../../axios/api';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


function WhoWeAre() {
  const [aboutData, setaboutData] = useState({});
  const [counters, setcounters] = useState({});
  const navigate = useNavigate();


  useEffect(() => {
    axios.get(viewAbout)
    .then((response)=>{
     const { StatusCode,data}  = response.data.app_data;
     
     if(StatusCode === 6000){
        setaboutData(data)
     }else if(StatusCode === 6001){
        setaboutData({})
     }
    })

    axios.get(coutersList)
    .then((response)=>{
     const { StatusCode,data}  = response.data.app_data;
     
     if(StatusCode === 6000){
      setcounters(data)
     }else if(StatusCode === 6001){
      setcounters({
      })
     }
    })
    
 }, [])
  return (
    <Section>
        <SideHeading>
           <h4>WHO WE ARE</h4>
        </SideHeading>
        <InfoSection>
          <Heading>
              {aboutData?.title}
          </Heading>
          <SubHeading>
              {
                aboutData?.content
              }
          </SubHeading>
          <ImgDiv>
               <img src={aboutData?.image} alt="" />
          </ImgDiv>
          <ReadMore>
             <p onClick={()=>navigate('/about')} >Read more</p>
          </ReadMore>
          {/* <CardContainer>
            <Card>
               <StatsCounter>{counters?.project_count}+</StatsCounter>
               <p>Projects</p>
            </Card>
            <Card>
               <StatsCounter>{counters?.plot}+</StatsCounter>
               <p>Plots</p>
            </Card>
            <Card>
               <StatsCounter>{counters?.location}+</StatsCounter>
               <p>Locations</p>
            </Card>
          </CardContainer> */}
        </InfoSection>
    </Section>
  )
}

export default WhoWeAre;
 
const Section = styled.div`
    display: flex;
    padding: 15px 0px;
    width: var(--section-width-lg);
    margin: 25px auto;
    gap:100px;
    @media(max-width:1024px){
      gap:50px;
    }
    @media(max-width:968px){
      flex-direction: column;
      gap:0;
    }
    @media(max-width:315px){
      padding: 5px;
    }
`;
const SideHeading = styled.div`
  width: 2%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  @media(min-width: 2250px){
    justify-content:unset;
  }
  @media(max-width:968px){
      width:100%;
      justify-content: flex-start;
  }
  h4{
    transform: rotate(180deg);
    font-size: 14px;
    color: var(--secondary-cl);
    font-family: 'popins_regular';
    width:25vh;
    writing-mode:vertical-lr;
    position: relative;
    top: 35px;
    margin-left: 0px;
    @media(max-width:968px){
      transform: rotate(0deg);
      writing-mode: horizontal-tb;
      position: unset;
      width: 50%;
    }
    &::after{
      content:'';
      position: absolute;
      width: 1px;
      background-color:var(--secondary-cl);
      height: 150px;
      bottom: 115px;
      left:8px;
      @media(max-width:968px){
        bottom:50%;
        width:150px;
        height:1px;
        left:100px;
    }
    @media(max-width:580px){
        width:100px;
    }
    }
  }
`;
const InfoSection = styled.div`
  padding: 20px 0px;
  width: 90%;
  height: min-content;
  @media(max-width:1024px){
    width: 98%;
  }
  @media(max-width:968px){
    width: 100%;
  }
  @media(max-width:580px){
    padding: 20px 0px 0px;
  }
`;

const Heading = styled.h3`
   font-family: 'popins_semibold';
   color: var(--primary-cl);
   @media(max-width:425px){
      width: 22ch;
    }
    @media(max-width:375px){
      width: 20ch;
    }
    @media(max-width:315px){
      width: 18ch;
      font-size: 21px;
    }

`;
const SubHeading = styled.p`
   font-family: 'popins_light';
   margin: 25px auto 14px auto;
   @media(max-width:580px){
      text-align:justify;
      font-size:14px;
    }
`;
const ImgDiv = styled.div`
    width: 100%;
    height: 50%;
    overflow: hidden;
    border-radius:10px;
    img{
      display: block;
      width: 100%;
    }
`;
const CardContainer = styled.div`
    margin: 15px auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
`;
const Card = styled.div`
    display: flex;
    flex-direction: column;
    width: 30%;
    border-radius: 30px;
    padding: 15px 25px;
    margin: 10px 15px;
    border: 1px solid var(--primary-cl);
    position: relative;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    @media(max-width:1350px){
      width: 27%;
    }
    @media(max-width:1200px){
      width: 40%;
      align-items: flex-start;
      padding-left: 50px;
    }
    @media(max-width:580px){
      width:80%;
      margin-left: auto;
      margin-right: auto;
    }
    &::after{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    border: 2px solid var(--primary-cl);
    border-radius: 26px;
    bottom: -70px;
    right: -200px;
    @media(max-width:1350px){
      width: 110%;
    }
    @media(max-width:1440px){
      right:-190px;
    }
    @media(max-width:1200px){
      right:-220px;
    }
    @media(max-width:968px){
      right:-180px;
    }
    @media(max-width:580px){
    right:-200px;
    }
    @media(max-width:425px){
      right: -180px;
    }
    @media(max-width:375px){
      right: -175px;
    }
    
    }
    p{
      padding: 0;
      margin: 0;
      font-family: 'popins_regular';
    }

`;

const StatsCounter = styled.h1`
    font-family: 'popins_bold';
    padding: 0;
    margin: 0;
    color: var(--primary-cl);
    font-size: 50px;
`;

const ReadMore = styled.div`
  margin: 15px 0px 5px;
  padding: 10px;
  display:flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  &::after{
    content: "";
    position: absolute;
    width: 90%;
    left: 7px;
    top: -10px;
    height: 80%;
    border-top: none;
    border-right: none;
    border-left: none;
    border-image: initial;
    border-bottom: 1px dashed var(--secondary-cl);
    @media(max-width:1200px){
      width:87%;
    }
    @media(max-width:968px){
      width:85%;
    }
    @media(max-width:580px){
    width:75%;
    }
    @media(max-width:425px){
      width: 70%;
    }
    @media(max-width:315px){
      width: 60%;
    }
  }
  p{
    padding: 0;
    margin: 0;
    color: var(--secondary-cl);
    font-size: 13px;
    font-family: 'popins_semibold';
    cursor: pointer;
  }
`
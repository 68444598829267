import React, { useState } from "react";
import { amentiesImages } from "../../../assets/js/collection";
import styled from "styled-components";
import ImageModal from "../../modals/ImageModal";
import Slider from "react-slick";
import { useEffect } from "react";
import axios from 'axios';
import { listAmenities } from "../../../axios/api";

function Amenities({id}) {
  const [isShow, setShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [amentiesImages, setamentiesImages] = useState([]);


  
  useEffect(() => {
    if(!id){
      return ;
    }
    axios.get(listAmenities+id)
      .then((response)=>{
       const { StatusCode,data}  = response.data.app_data;
       
       if(StatusCode === 6000){
          setamentiesImages(data)
       }else if(StatusCode === 6001){
          setamentiesImages([])
       }
      })
   }, [id])

  const handleImageModal = () => {
    setShow(!isShow);
  };
  // react slick slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay:true,
  };
  return (
    <Container id="amenities">
      <Wrapper className="wrapper">
        <Content>
          <Title>Amenities</Title>
          <Cards>

            {amentiesImages?.map((item) => (
              <Card>
                <ImageContainer
                  onClick={() => {
                    setShow(true);
                    setSelectedImage(item.picture);
                  }}
                >
                  <img src={item.picture} alt="image" />
                  <Text>{item.name} </Text>
                </ImageContainer>
              </Card>
            ))}

          </Cards>
          <SliderSection>
            <Slider {...settings}>
              {amentiesImages?.map((item) => (
                <Card>
                  <ImageContainer
                    onClick={() => {
                      setShow(true);
                      setSelectedImage(item.picture);
                    }}
                  >
                    <img src={item.picture} alt="image" />
                    <Text>{item.name} </Text>
                  </ImageContainer>
                </Card>
              ))}
            </Slider>
          </SliderSection>
          <ImageModal
            isShow={isShow}
            selectedImage={selectedImage}
            handleImageModal={handleImageModal}
          />
        </Content>
      </Wrapper>
    </Container>
  );
}

export default Amenities;

const Container = styled.div`
  padding: 35px 0;
`;
const Wrapper = styled.div`
    @media(max-width:1400px){
        width:90%;
    }
`;
const Content = styled.div``;
const Text = styled.div`
  background: rgb(0 0 0 / 38%);
  color: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 5px;
  text-align: center;
  border-radius: 0px 0px 15px 15px;
  @media all and (max-width: 768px) {
    width: 100%;
  }
`;
const Title = styled.p`
  color: var(--secondary-cl) !important;
  text-align: start;
  font-family: "popins_semibold";
  font-size: 13px;
  writing-mode: horizontal-tb;
  position: relative;
  transform: rotate(0deg);
  letter-spacing:.5px;
  margin-left:0;
  position: relative;
    &::after{
    content:"";
    width:80px;
    height:1px;
    position: absolute;
    bottom:0px;
    top:50%;
    left:85px;
    background-color:var(--secondary-cl);
  }
`;

const Cards = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap:13px;
  @media all and (max-width: 580px) {
    display: none;
  }
`;
const SliderSection = styled.div`
  display: none;
  @media all and (max-width: 580px) {
    display: block;
  }
   @media all and (max-width: 580px) {
    .slick-next{
      display: none !important;
    }
  }
`;
const ImageContainer = styled.div`
  position: relative;
  width:100%;
  img {
    display: block;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    @media all and (max-width: 768px) {
    width: 100%;
   }
  }
`;
const Card = styled.div`
  width: 30%;
  cursor: pointer;
  @media all and (max-width: 1080px) {
    width: 45%;
  } 
  @media all and (max-width: 680px) {
    width: 100%;
  } 
`;

import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import BlogImage from '../components/Pages/BlogDetail/BlogImage';
import BlogContent from '../components/Pages/BlogDetail/BlogContent';
import { useNavigate, useParams } from 'react-router-dom';
import { blogDatas } from '../assets/js/blogsData';
import TopBar from '../components/includes/TopBar';
import Footer from '../components/includes/Footer';
import axios from 'axios';

import { useDispatch } from 'react-redux';
import { updateActiveMenu } from '../redux/actions/general';
import { listSingleBlog } from '../axios/api';
import Helmet from '../components/common/Helmet';

function BlogDetail() {
  const { id } = useParams();
  const [selectedBlog, setselectedBlog] = useState(null);
  const navigate = useNavigate()
  const dispatch = useDispatch();

  useEffect(() => {
    if(id===undefined) {
        navigate('/blogs');
    }
    axios.get(listSingleBlog+id)
      .then((response)=>{
       const { StatusCode,data}  = response.data.app_data;
          
       if(StatusCode === 6000){
        setselectedBlog(data[0])
       }else if(StatusCode === 6001){
        setselectedBlog([])
       }
      })
  }, [])


  useEffect(() => {
    dispatch(updateActiveMenu("Blog"))
  }, [])

  return (
    <>
     <Helmet titleData={selectedBlog?.meta_tag} descriptionData={selectedBlog?.meta_description}  />
    <TopBar/>
    <Section>
        <Container>
           <BlogImage img={selectedBlog?.image}  title={selectedBlog?.title} slug={selectedBlog?.slug} />
           <BlogContent blog={selectedBlog}/>
        </Container>
    </Section> 
    <Footer/>
    </>
  )
}

export default BlogDetail;


const Section = styled.div``;

const Container = styled.div`
width:var(--section-width-lg);
margin: 0 auto;

@media(max-width:1400px){
  width:90%;
}

@media(max-width:580px){
  width:95%;
}

`;


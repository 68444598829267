import React from 'react'
import styled from 'styled-components';
import axios from 'axios';

import img from '../../../assets/images/about/img1-about.png';
import about_banner from '../../../assets/images/banner/about_banner.png';
import arrow1 from '../../../assets/images/icons/about/arrow1.svg';
import arrow2 from '../../../assets/images/icons/about/arrow2.svg';
import { useEffect } from 'react';
import { coutersList } from '../../../axios/api';
import { useState } from 'react';

function DirectorReview() {
  const [counters, setcounters] = useState({});
  useEffect(() => {
    axios.get(coutersList)
    .then((response)=>{
     const { StatusCode,data}  = response.data.app_data;
     
     if(StatusCode === 6000){
      setcounters(data)
     }else if(StatusCode === 6001){
      setcounters({
      })
     }
    })
  }, [])
  
  return (
    <Section>
       <FirstSection>
         <Container >
             <img src={img} alt="" />
             <Details>
              <Leftquotation src={arrow1}/>
               <CenterPortion>
                  <Heading>
                  About Skyline Hectares
                  </Heading>
                  <Body>
                  Choosing the path of unwavering commitment to excellence hasn't always been the easiest, but it's been the right one.
                  We believe in doing it right every day, a commitment that has defined our journey for 33 years, through thick and thin.
                  This resolve has been incredibly satisfying because we value the trust that’s been placed in us. Skyline Hectares is our latest venture into a new frontier.
                  As Kerala's first premium plot developer, we aim to reach beyond the skyline, extending our legacy of excellence to provide you with a unique opportunity to be part of a thriving community that's built on the same foundation of quality, innovation, and trust.
                  </Body>
                  <BottomContent>
                    <Name>
                        K V Abdul Azeez
                    </Name>
                    <Position>
                        Chairman and Managing Director
                    </Position>
                  </BottomContent>
               </CenterPortion>
              <Rightquotation src={arrow2}/>
             </Details>
         </Container>
       </FirstSection>
       <SecondSection src={about_banner}>
           <img src={about_banner} alt="" />
       </SecondSection>
       {/* <ThirdSection>
            <Card b="R">
            <StatsCounter>{counters?.project_count}+</StatsCounter>
            <p>Projects</p>
            </Card>

            <Card b="R" rm="rm">
            <StatsCounter>{counters?.plot}+</StatsCounter>
            <p>Plots</p>
            </Card>

            <Card>
            <StatsCounter>{counters?.location}+</StatsCounter>
            <p>Locations</p>
            </Card>
       </ThirdSection> */}
    </Section>
  )
}

export default DirectorReview;

const  Section = styled.div`
`;

const Container =  styled.div`
width: var(--section-width-lg);
margin: 0 auto;
padding-top: 35px;

@media(max-width:1400px){
    img{
      width:60%;
    }
}
@media(max-width:1200px){
    img{
      width:58%;
    }
}

@media(max-width:968px){
  padding-top: 0;
  img{
    width: 56%;
  }
}

@media(max-width:425px){
    img{
    width: 71%;
    }
}

@media(max-width:375px){
    img{
    width: 80%;
    }
}

`;

const FirstSection =  styled.div`
background-color: #f1f1f1;
width: 100%;
position: relative;

@media(max-width:1200px){
  height: 347px;
}


@media(max-width:1000px){
  height: 350px;
  display: flex;
  align-items: end;
}

@media(max-width:651px){
  height: 450px;
}

@media(max-width:425px){
    height: 541px;
}

@media(max-width:375px){
    height: 590px;
}

@media(max-width:319px){
    height:585px;
}


`;

const SecondSection =  styled.div`
    display: flex;
    width: var(--section-width-lg);
    margin: 0px auto;
    height: 360px;
    background-size: cover;
    background-repeat: no-repeat;
    background-size: center center;
    background-position: center center;
    ${(props)=>props.src && `background-image: url(${props.src})`};
    border-top: 4px solid var(--primary-cl);

    @media(min-width:2000px){
      height:460px;
    }

    @media(max-width:1400px){
      width:90%;
    }

    @media(max-width:651px){
    width:95%;
    }

    @media(max-width:375px){
        height:250px;
        width:100%;
    }

img{
    width: 100%;
    display: none;
}
`;

const ThirdSection =  styled.div`
 display: flex;
 width: var(--section-width-lg);
 margin:30px auto;

 @media(max-width:651px){
  width:90%;
}

@media(max-width:580px){
  width: 95%;
  flex-wrap: wrap;
  gap: 25px;
}

`;

const Details = styled.div`
display: flex;
position: absolute;
width: 55%;
height: 80%;
top: 20px;
right: 100px;

@media(min-width:2000px){
    width: 60%;
    top: 55px;
    right: 340px;
}

@media(max-width:1400px){
      width: 80%;
      justify-content: end;
      right: 0;
}

@media(max-width:968px){
      width: 80%;
      right: 0px;
      top:4px;
}

@media(max-width:651px){
  height: 50%;
  right: 0;
  top: 14px;
  width: 110%;
}

`;

const Leftquotation =  styled.div`
width:20%;
${(props)=>props.src && `background-image: url(${props.src})`};
height:100%;
background-repeat:no-repeat;
background-position:top center;
background-size: 100px;

@media(min-width:2000px){
    width: 20%;
    background-position: 80% top;
}

@media(max-width:1400px){
    background-size:85px;
    width:15%;
}

@media(max-width:968px){
    background-size:70px;
    background-position: 70% 5%;
}

@media(max-width:651px){
  background-size:85px;
}

@media(max-width:580px){
  background-size:60px;
  background-position: 50% 5%;
}

@media(max-width:425px){
    background-size:50px;
}

@media(max-width:375px){
    background-size:40px;
    width:15%;
}

`;

const  Rightquotation  =  styled.div`
width:20%;
${(props)=>props.src && `background-image: url(${props.src})`};
height:100%;
background-repeat:no-repeat;
background-position:center 80%;
background-size:100px;

@media(min-width:2000px){
    background-repeat: no-repeat;
    background-position: 20% 60%;
    background-size: 100px;
}

@media(max-width:1400px){
    background-size:85px;
    background-position: 25% 85%;
    width:15%;
}

@media(max-width:968px){
    background-position: 25% 93%;
    background-size:70px;
}

@media(max-width:651px){
  background-size:85px;
  background-position: 25% 85%;
}

@media(max-width:580px){
  background-size:60px;
  margin-top:110px;
}

@media(max-width:425px){
    background-size:50px;
}

@media(max-width:375px){
    background-size:40px;
    width:12%;
}

@media(max-width:375px){
    margin-top:180px;
}

`;

const CenterPortion = styled.div`
display: flex;
flex-direction: column;
width:100%;
padding-top: 50px;

@media(max-width:1400px){
    width:60%;
    padding-top: 35px;
}

@media(max-width:1200px) and (min-width:1000px){
    padding-top: 0px;
}

@media(max-width:375px){
    background-size:40px;
    width:65%;
}
`;

const Heading  = styled.h4`
font-family: 'popins_semibold';
color : var(--primary-cl);
`;

const Body = styled.p`
font-family: 'popins_regular';
font-size: 13.5px;
text-align: justify;

@media(max-width:1400px){
  font-size: 13px;
}

@media(max-width:968px){
  font-size: 10px;
  margin-bottom: 0;
};

@media(max-width:651px){
  font-size: 11px;
}

@media(max-width:375px){
    text-align: justify;
    padding: 3px;
}


`;


const BottomContent = styled.div`
width:100%;
margin:10px 0;

@media(max-width:968px){
  margin: 0;
}

`;

const Name  = styled.h6`
width: 100%;
text-align: right;
font-family: 'popins_semibold';
color : var(--primary-cl);
`;

const Position  = styled.p`
width: 100%;
text-align: right;
font-family: 'popins_regular';
font-size:13.5px;

@media(max-width:968px){
    font-size: 12px;
}

`;


const Card = styled.div`
display: flex;
flex: 1;
align-items: center;
justify-content: center;
flex-direction: column;
${(props)=>props.b && `border-right: 1px solid var(--secondary-cl)`};

@media(max-width:375px){
    ${(props)=>props.rm && `border: none`};
}

p{
      padding: 0;
      margin: 0;
      font-family: 'popins_regular';
}
`;

const StatsCounter = styled.h1`
    font-family: 'popins_semibold';
    padding: 0;
    margin: 0;
    color: var(--primary-cl);
    font-size: 50px;
`;




import React, { useEffect } from 'react'
import TopBar from '../components/includes/TopBar';
import axios from 'axios';

import contact_bg from '../assets/images/breadcrumb/contact-bg.jpg'

import Breadcrumb from '../components/common/Breadcrumb';
import Footer from '../components/includes/Footer';
import ContactInfo from '../components/Pages/ContactPage/ContactInfo';
import LocationInfo from '../components/Pages/ContactPage/LocationInfo';
import { useDispatch } from 'react-redux';
import { updateActiveMenu } from '../redux/actions/general';
import { viewContactDetails } from '../axios/api';
import { useState } from 'react';



function Contact() {
  const [data, setdata] = useState({})
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateActiveMenu("Contact"))
  }, [])

  useEffect(() => {
    axios.get(viewContactDetails)
      .then((response)=>{
       const { StatusCode,data}  = response.data.app_data;
       
       if(StatusCode === 6000){
          setdata(data) 
       }else if(StatusCode === 6001){
          setdata({})
       }
      })
   }, [])

  return (
    <>
    <TopBar/>
    <Breadcrumb bg={contact_bg} title={"Contact"} destination={"Contact"} />
    <ContactInfo data={data}/>
    <LocationInfo data={data}/>
    <Footer/>
    </>
  )
}

export default Contact;
import axios  from 'axios';


// export const BaseUrl="https://skylineadmin-dev.accoladesweb.com/api/";

export const BaseUrl="https://backend.skylinehectares.com/api/";
// export const BaseUrl="http://127.0.0.1:8000/api/";


let token;
if(localStorage.getItem('Tokens')){
  token=JSON.parse(localStorage.getItem('Tokens'))
  
}else{
  token=""
}
const instance = axios.create({
    baseURL: BaseUrl,
    headers:{
      Authorization:`Bearer ${token.access}`
    }
  });


  instance.interceptors.request.use(
    (config) => {
      const token=JSON.parse(localStorage.getItem('Tokens'))
      if (token) {
        config.headers.Authorization = `Bearer ${token.access}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  export default instance;
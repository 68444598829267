import React from 'react'
import styled from 'styled-components';

import downArrow from '../../../assets/images/common/down-arrow.svg';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendCrmData, sendEnquiryUrl } from '../../../axios/apiFunctions';
import CircularProgress from "@mui/material/CircularProgress";
import { listProjectNames } from '../../../axios/api';
import ReCAPTCHA from 'react-google-recaptcha';

function Enquiry() {
  const currentUrl = window.location.href;

  const [projects, setprojects] = useState([]);
  const [formdata, setformdata] = useState({url:currentUrl})
  const [error, seterror] = useState(false);
  const [loader, setloader] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const navigate = useNavigate()
  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };
  useEffect(() => {
    axios.get(listProjectNames)
      .then((response)=>{
       const { StatusCode,data}  = response.data.app_data;
       
       if(StatusCode === 6000){
          setprojects(data)
       }else if(StatusCode === 6001){
          setprojects([])
       }
      })
   }, [])

   const handleChange = (e)=>{
    seterror(false)
    const { name , value} = e.target
    setformdata({
      ...formdata,
      [name]:value
    })
   };

   const handleSubmit = ()=>{
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneNumberRegex = /^\d+$/;
      if(!formdata?.id){
        seterror("select a project")
      }else if(!formdata?.name || formdata?.name.length<3){
        seterror('Enter a vaild name')
      }else if(!formdata?.email || !emailRegex.test(formdata?.email)){
        seterror('Enter a vaild email')
      }else if(!formdata?.phone || !phoneNumberRegex.test(formdata?.phone)){
        seterror('Enter a vaild phone number')
      }else if (captchaToken==="" || captchaToken===null){
        seterror('Please verify your captcha')
      }
      else{
        setloader(true)
        sendCrmData(formdata)
        axios.post(sendEnquiryUrl,formdata)
        .then((response)=>{
          setloader(false);
          const { StatusCode,data} = response.data.app_data;
          if(StatusCode === 6000){
            let projectName = projects?.map((project)=>{
              if(formdata.id === project.project_id){
               return project.project_name.replace(" ","-")
              }else{
                return 
              }
            })
             setformdata({})
             navigate('/'+projectName+'/thank-you',{
               state:{
                 access:true
               }
             })
          }else{
             seterror(data.message);
             setTimeout(() => {
              seterror(false)
             }, 2000);
          }
        })
      }
   };


  return (
    <Section>
        {
          error && <Error>{error}</Error>
        }
        <Container>
          <SelectDiv >
            <select name="id" id="" onChange={(e)=>handleChange(e)} >
                <option value="" disabled selected>Select Project</option>
                  {
                          projects?.map((obj)=>{
                            return (
                              <option value={obj.project_id}>{obj.project_name}</option>
                            )
                          })
                  }
            </select>
          </SelectDiv>

          <InputDiv>
            <input value={formdata?.value} name="name" onChange={(e)=>handleChange(e)} type="text" placeholder='Name' />
          </InputDiv>

          <InputDiv>
             <input value={formdata?.phone} name="phone" type="text" placeholder='Mobile' onChange={(e)=>handleChange(e)} />
          </InputDiv>

          <InputDiv>
              <input value={formdata?.email} type="email" name="email" placeholder='Email' onChange={(e)=>handleChange(e)} />
          </InputDiv>
          <ReCAPTCHA className='recapha'
                sitekey='6LeDwAIqAAAAAMjjFFFklc_7bXcUBPJIkIQEvUSM'
                onChange={handleCaptchaChange}
              />
        </Container>
        <SubmitButton onClick={handleSubmit}>
          
            {loader ? <CircularProgress/> : "ENQUIRE NOW"}  
          </SubmitButton>
         
    </Section>
  )
}

export default Enquiry;


const Section = styled.div`
    display: flex;
    background-color:#fff;
    padding: 20px 0px;
    margin: 40px auto;
    flex-direction: column;
    width:var(--section-width-lg);
    @media(max-width:1200px){
      padding:20px 0px;
    }
    @media(max-width:580px){
    padding: 20px 0px;
    margin: 25px auto;
    }
`

const Container = styled.div`
    display: flex;
    margin: 0 auto;
    width: 100%;
    align-items: center;
    justify-content:center;
    @media(max-width:1440px){
      width:90%;
    }

    @media(max-width:1024px){
      width:100%;
    }

    @media(max-width:580px){
      margin: 0 auto;
    }

    @media(max-width:968px){
      flex-wrap: wrap;
    }
    
    div{
      width: 30%;
    @media(min-width:2000px){
      width: 100%;
    }
    @media(max-width:1440px){
      width:40%;
    }
    @media(max-width:1024px){
      width:50%;
    }
    @media(max-width:580px){
      width:50%;
      flex-wrap: nowrap;
      .recapha{
        width: 10% !important;
      }
    }
    @media(max-width:375px){
      width:50%;
      margin: 0 0px;
    }
    @media(max-width:315px){
      width: 98%;
    }
    }
`;

const SelectDiv = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 5px;
   position: relative;
   &::after{
    content: "";
    display: block;
    position: absolute;
    width: 12px;
    top: 9px;
    height: 100%;
    right: 14px;
    background-image: url(${downArrow});
    margin: 10px 0px;
    background-repeat: no-repeat;
    color: rgba(0, 0, 0, 0);
    transition: all .5 ease;
   }
   select:focus  &{
        background-color:red;
    }
   select{
    padding: 5px 10px;
    border: 1px solid var(--secondary-cl);
    border-radius: 15px !important;
    font-family: 'popins_light';
    font-size:14px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    font-family: 'popins_light';
    font-size:14px;
    color:#757c87;
   }
`

const InputDiv = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 5px;
   input{
    padding: 5px 10px;
    border: 1px solid var(--secondary-cl);
    border-radius: 15px !important;
    @media(min-width:2000px){
      width: 100%;
    }
    @media(max-width:1024px){
      width:100%;
    }
    @media(max-width:580px){
    width: 100%;
    }
    &::placeholder{
        font-family: 'popins_light';
        font-size:14px;
    }
   }
`

const SubmitButton = styled.button`
   width: 30%;
   margin: 0 auto;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 2px;
   background-color: var(--tertiary-cl);
   border: none;
   color: #fff;
   border-radius: 15px !important;
   font-family: 'popins_light';
   font-size:13px;
   height: 36px;
   span{
            width: 25px !important;
            height: 25px !important;
            color: white;
          }
   @media(min-width:2000px){
      width: 100%;
   }
   @media(max-width:1440px){
    
    gap: 20px;
    }
    @media(max-width:1024px){
      width:30%;
      margin-top:5px;
    }
   @media(max-width:580px){
    width: 60%;
   }
   @media(max-width:315px){
      width: 98%;
    }
`

const Error = styled.p`
    margin:7px 0px;
    width: 100%;
    text-align: center;
    color:red;
`;
import React from 'react'
import styled from 'styled-components';

function BreifDetail() {
  return (
    <Section>
        <Container>
        <SideHeading>
           <h4>PROJECTS</h4>
        </SideHeading>
        <InfoSection>
          <Heading>
          Embark on a Visual Journey Through Our Projects
          </Heading>
          <SubHeading>
          We invite you to explore a visual journey through all our finest projects, each a testament to our commitment to excellence.
          Dive in to discover the exceptional quality, innovation, and vision that defines Skyline Hectares. 
          </SubHeading>
        </InfoSection>
        </Container>
    </Section>
  )
}

export default BreifDetail;

const Section = styled.div`
    display: flex;
    padding: 15px 0px;
    width: var(--section-width-lg);
    margin: 0px auto 35px auto;
    @media(min-width:2000px){
      padding: 75px 0px;
    }
    @media(max-width:968px){
      flex-direction: column;
    }
    @media(max-width:580px){
      width:90%;
      padding:0px;
      margin: 25px auto;
    }
`;
const Container = styled.div`
 display: flex;
 gap:65px;
 @media(max-width:1024px){
  flex-direction: column;
  gap:0;
} 

`;

const SideHeading = styled.div`
  width: 1%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  height: 100%;
  @media(max-width:1024px){
      width:100%;
      justify-content: flex-start;
  }
  h4{
    transform: rotate(180deg);
    font-size: 14px;
    color: var(--secondary-cl);
    font-family: 'popins_regular';
    writing-mode: vertical-rl;
    width:25vh;
    position: relative;
    @media(min-width:2000px){
     width: 10vh;
    }
    @media(max-width:1024px){
        writing-mode: horizontal-tb;
        transform: rotate(0deg);
    }

  &::after{
    content: "";
    display: block;
    position: absolute;
    width: 1px;
    top: -60px;
    left: 50%;
    height: 60%;
    background-color: var(--secondary-cl);
    margin: 10px 0px;
    @media(max-width:1024px){
      width: 150px;
      height: 1px;
      top: -24%;
      left: 80px;
    }
    @media(max-width:580px){
      width: 100px;
    }
  }
  }
`;
const InfoSection = styled.div`
  padding:0px;
  width: 98%;
  @media(max-width:968px){
    width: 100%;
  }
  @media(max-width:580px){
    padding: 20px 0px;
  }
`;

const Heading = styled.h3`
   font-family: 'popins_semibold';
   color: var(--primary-cl);
   @media(max-width:425px){
      width: 22ch;
    }
    @media(max-width:375px){
      width: 24ch;
      font-size: 18px;
    }
    @media(max-width:315px){
      width: 18ch;
      font-size: 21px;
    }

`;
const SubHeading = styled.p`
   font-family: 'popins_regular';
   margin: 25px auto 14px auto;
   @media(max-width:580px){
      text-align: justify;
      font-size: 14px;
    }
`;
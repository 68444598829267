import { SET_ACTIVE_MENU, TOGGLE_3D, UPDATE_LOADER } from "../types"



export const updateActiveMenu =(menu)=>dispatch=>{
    dispatch({type:SET_ACTIVE_MENU,payload:menu})
};

export const toggle3DView =(state = null)=>dispatch=>{
    dispatch({type:TOGGLE_3D,payload:state})
};


export const updateLoader =(state)=>dispatch=>{
    
    dispatch({type:UPDATE_LOADER,payload:state})
};
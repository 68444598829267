import {SET_ACTIVE_MENU, TOGGLE_3D, UPDATE_LOADER} from '../types'

const initialState={
    active_menu: "Home",
    active_3D:false,
    loader:true,
}

function general(state=initialState,action){
    const {type,payload} = action;

    switch (type) {
        case SET_ACTIVE_MENU:
            return {...state,['active_menu']:payload}
        case TOGGLE_3D:
            if(payload!== null){
                return {...state,['active_3D']:payload}
            }else{
                return {...state,['active_3D']:!state.active_3D} 
            } 
        case UPDATE_LOADER:
            return {...state,['loader']:payload}
        default:
            return state;
    }
}

export default general;
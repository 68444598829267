import React from 'react'
import styled from 'styled-components';

function AboutContent({data}) {
  return (
    <Section>
    <Container>
     <SideHeading>
        <h4>
            ABOUT
        </h4>
     </SideHeading>
     <ContentArea>
      <Heading>
      {data?.title}
      </Heading>
      <Content>
        {
          data?.content
        }
      </Content>
     </ContentArea>
    </Container>
    </Section>
  )
}

export default AboutContent;


 
const Section = styled.div`
      display: flex;
      padding: 15px 0px;
      width: var(--section-width-lg);
      margin: 5px auto;
      padding-bottom: 25px;

    @media(min-width:2000px){
      margin: 35px auto;
    }

    @media(max-width:968px){
      flex-direction: column;
      margin: 20px auto 30px;
    }
     @media(max-width:580px){
      width:90%;
      margin: 5px auto 5px;
    }

    @media(max-width:315px){
      padding: 5px;
    }
`;
const Container = styled.div`
    display: flex;
    gap:80px;
     @media(max-width:768px){
      gap:40px;
    }
   @media(max-width:580px){
      flex-direction: column;
      margin: 0px auto;
      gap:10px;
    }


`;

const SideHeading = styled.div`
width:1%;
writing-mode: vertical-lr;
display: flex;
align-items: center;

@media(max-width:580px){
  writing-mode: unset;
  width: 100%;
}

h4{
    font-family: 'popins_regular';
    font-size:14px;
    color: var(--secondary-cl);
    transform:rotate(180deg);
    position:relative;

    @media(max-width:580px){
      transform:rotate(0deg);
      width: 100%;
    }   

    &:after{
        content: "";
        width: 1px;
        height: 55px;
        background-color: var(--secondary-cl);
        position: absolute;
        right: 50%;
        bottom: 125%;

        @media(max-width:580px){
           width: 100px;
           height: 1px;
           left: 60px;
           right:unset;
           bottom: 50%;
        }
    }
}
`;

const ContentArea = styled.div`
width:90%;

@media(max-width:580px){
  width: 98%;
  margin: 0 auto;
}
`;

const Heading = styled.h4`
color:var(--primary-cl);
font-family: 'popins_semibold';
margin-bottom: 20px;
`;

const Content = styled.p`
 font-family: 'popins_regular';
font-size: 13.5px;
text-align: justify;

@media(min-width:2000px){
  font-size: 21px;
}

@media(max-width:968px){
  font-size: 12px;
}

@media(max-width:375px){
  font-size: 14px;
  text-align: justify;
}

`;





import React from 'react'
import styled from 'styled-components';
import Slider from "react-slick";
import axios from 'axios';

import { useState } from 'react';
import { useEffect } from 'react';
import { listBlogs } from '../../../axios/api';
import { useNavigate} from 'react-router-dom'

function BlogDisplay() {

   const [blogs, setblogs] = useState([]);
   const naviagte = useNavigate()

   useEffect(() => {
    axios.get(listBlogs)
      .then((response)=>{
       const { StatusCode,data}  = response.data.app_data;
       
       if(StatusCode === 6000){
          setblogs(data.results)
       }else if(StatusCode === 6001){
          setblogs([]);
       }
      })
   }, [])

   const handleClick = (slug) => {
      naviagte('/blog/' + slug)
   };

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow:  1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500,
        pauseOnHover: true,
        responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },{
              breakpoint: 580,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots:true,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots:true,
              }
            },
            {
              breakpoint: 315,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots:true
              }
            }
          ]
      };
  return (
   <Section>
    <Container>
        <SideHeading>
           <h4>BLOGS</h4>
        </SideHeading>
        <RecentBlog>
              <ImgDiv>
                 <img src={blogs[0]?.image} alt="" />
              </ImgDiv>
              <ContentArea>
                  <Heading>
                      {blogs[0]?.title}
                  </Heading>
                  <Date>
                    {blogs[0]?.created.split('T')[0]}
                  </Date>
                      {
                        blogs[0]?.body.length > 300 ? (
                          <Description dangerouslySetInnerHTML={{__html:blogs[0]?.body.slice(0,299)+ "..."}}/>
                        ):(
                          <Description dangerouslySetInnerHTML={{__html:blogs[0]?.body}}></Description>
                        )
                        
                      }
                  <ReadMore onClick={()=>handleClick(blogs[0]?.slug)}>
                      Read More
                  </ReadMore>
              </ContentArea>
        </RecentBlog>
    </Container>
    {/* <SliderArea>
      <Slider {...settings}>
        {
          blogs?.map((obj)=>{
              return(
                <SingleBlog onClick={()=>handleClick(obj.slug)}>
                <BlogImg img={obj?.image}>
                  
                </BlogImg>
                <BlogContent>
                  <Title>
                       {obj?.title}
                  </Title>
                    {
                        obj?.body.length > 270 ? (
                          <BlogDescription dangerouslySetInnerHTML={{__html:obj?.body.slice(0,270)+ "..."}}/>
                        ):(
                          <BlogDescription dangerouslySetInnerHTML={{__html:obj?.body}}/>
                        )
                        
                    }
                  <BottomNav>
                  <div class="date">
                      {obj?.created.split('T')[0]}
                  </div>
                  <div class="read-more">
                      Read more
                  </div>
                </BottomNav>
                </BlogContent>
            </SingleBlog>
              )

          })
        }
      </Slider>
    </SliderArea> */}
   </Section>
  )
}

export default BlogDisplay;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px 0px;
    height: max-content;
    @media(max-width: 580px){
      padding: 25px 0px;
    }
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    width: var(--section-width-lg);
    margin: 0 auto;
    margin-bottom: 25px;
    @media(max-width:968px){
    flex-direction: column;
    }
    `
const SideHeading = styled.div`
    width: 5%;
    display: flex;
    align-items: start;
    height: 200px;
    @media(max-width:968px){
      width:100%;
      height: max-content;
    }
    h4{
        font-size: 14px;
        font-family: 'popins_regular';
        color:var(--secondary-cl);
        transform: rotate(180deg);
        writing-mode: vertical-lr;
        position: relative;
        top:10px;
        left:0px;
        text-align: center;
        margin-left: 0;
        @media(max-width:968px){
        position: unset;
        transform: rotate(0deg);
        writing-mode: horizontal-tb;
        }
        &::after{
            content: "";
            position: absolute;
            width: 1px;
            height: 100px;
            background-color: var(--secondary-cl);
            bottom:0;
            left: 9px;
            top:-120px;
            @media(max-width:968px){
                height: 1px;
                width: 100px;
                top:50%;
                left:60px;
            }
            }
    }
    `

const RecentBlog = styled.div`
    display: flex;
    width: 90%;
    align-items: center;
    @media(max-width:1200px){
      width:90%;
    }
    @media(max-width:968px){
      width:100%;
      flex-direction: column;
    }
    @media(max-width:580px){
      flex-direction: column;
      padding:15px;
    }
    `

const ImgDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0px 20px;
    margin-left:0px;
    height: 200px;
    /* ${(props)=>props.img && `background-image: url(${props.img});`}
    background-repeat:no-repeat;
    background-size:cover;
    width:183px;
    height:184px; */
    @media(min-width:1800px){
      width: 40%;
    }
    @media(max-width:968px){
      width: 95%;
      height:225px;
    }
    @media(max-width:568px){
      width: 100%;
      height:auto;
    }

    img{
        width: 100%;
        display: block;
        aspect-ratio:16/6;
        height:100%;
    }
    `

const ContentArea = styled.div`
    display: flex;
    flex-direction: column;
`
const Heading = styled.h3`
    font-family: 'popins_bold';
    font-size: 20px;
    color: var(--primary-cl);
    margin: 20px 0px;

    @media(max-width:568px){
      font-size: 16px;
    }
`

const Date = styled.p`
    font-family: 'popins_light';
    font-size: 14px;
    color: var(--secondary-cl);
    position: relative;
    &::after{
        content: "";
        position: absolute;
        width: 18%;
        height: 1px;
        background-color: var(--secondary-cl);
        bottom: 10px;
        left: 98px;
    }

`

const Description = styled.p`
    font-family: 'popins_regular';
    font-size: 16px;
    
    @media(max-width:968px){
      font-size:15px;
    }
    @media(max-width:568px){
      font-size: 14px;
    }
`
    
const ReadMore = styled.div`
   font-family: 'popins_regular';
    font-size: 18px;
    color: var(--secondary-cl);
    font-size: 14px;
    text-align: start;
    width: 100%;
    font-weight: 600;
    position: relative;
    &::after{
        content: "";
        position: absolute;
        width: 18%;
        height: 1px;
        background-color: var(--secondary-cl);
        bottom: 8px;
        left: 46px;
        left: 86px;
}
`

const SliderArea = styled.div`
    width: var(--section-width-lg);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin:45px auto;
    .slick-slider{
        width: 100%;
    }
    .slick-prev{
        /* border:1px solid var(--secondary-cl);
        padding:1px;
        border-radius:8px; */
      &::before{
        /* content: "";
        display: flex;
        border: 1px solid  var(--secondary-cl);
        width: 60%;
        height: 60%; */
        color: var(--secondary-cl) !important;
        /* transform: rotate(320deg);
        border-right: none;
        border-bottom: none;
        position:absolute; */
      }
    }
    .slick-next{
      &::before{
        color:var(--secondary-cl) !important;
      }
    } 
     @media(max-width:580px){
      .slick-next,.slick-prev{
        display: none !important;
      }
      margin: 25px auto;
    }
    @media(max-width:315px){
      padding: 40px 0px;
    }
`

const SingleBlog =styled.div`
    display: flex !important;
    padding: 15px;
    border: 1px solid var(--secondary-cl);
    width: 98% !important;
    gap:10px;
    @media(max-width:1200px){
       padding: 22px; 
    }
    @media(max-width:580px){
      flex-direction: column;
      width: 100% !important;
      padding: 15px; 
    }
`;

const BlogImg = styled.div`
    width: 42%;
    ${(props)=>props.img && `background-image: url(${props.img});`}
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center center;
    /* @media(max-width:1200px){
        background-size:275px;
    }
    @media(max-width:1000px){
      background-size:200px;
    }
    @media(max-width:850px){
      background-size:225px;
    } */

    @media(max-width:580px){
      width: 95%;
      margin: 10px auto;
      background-size:contain;
      height: 150px;
      img{
        display: block;
        width: 90%;
    }
    }
`

const BlogContent = styled.div`
    width: 60%;
    display: flex;
    flex-direction: column;
    @media(max-width:968px){
      justify-content:center;
    }
    @media(max-width:580px){
      width: 95%;
      margin: 10px auto;
    }
`

const Title = styled.h3`
    font-family: 'popins_bold';
    font-size: 15px;
    color: var(--primary-cl);`

const BlogDescription = styled.p`
    font-family: 'popins_light';
    font-size: 13px;
    margin:0;
`
const BottomNav = styled.div`
    margin: 10px 0px;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:space-between;
    font-family: "popins_light";
    font-size: 13px;
    color: var(--secondary-cl);
    font-weight: 550;
    .date{
        position: relative;
        &::after{
            content: '';
            display: block;
            width: 125%;
            height: 1px;
            background-color: var(--secondary-cl);
            position: absolute;
            left: 90px;
            bottom: 9px;
            @media(max-width:1440px){
            display: none;
            }
        }
    }
`
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import axios from 'axios';

import herobanner from '../../../assets/images/banner/banner.jpg';
import searchIcon from "../../../assets/images/topbar/search_icon.svg";
import { BannerImgs } from '../../../assets/js/collection';

import Slider from "react-slick";
import { useNavigate } from 'react-router-dom';
import { listBanners } from '../../../axios/api';






function HeroPortion() {
  const [banners, setbanners] = useState([]);
  const [search, setsearch] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(listBanners)
    .then((response)=>{
     const { StatusCode,data}  = response.data.app_data;
     
     if(StatusCode === 6000){
        setbanners(data)
     }else if(StatusCode === 6001){
        setbanners([])
     }
    })
    
 }, [])
   

  const settings = {
    dots: true,
    infinite: true,
    arrows:false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    cssEase: "linear"
  };


  const handleClick = (url) => {
        navigate(url);
  };

  const handleChange = (e)=>{
    setsearch(e.target.value);
  }

  const handleSearchSubmit = ()=>{
       if(search!==''){
          navigate('/projects/',{
            state:{
              search
            }
          })
       }
  };


  return (
    <Section>
     <Slider {...settings}>
      {
        banners?.map((obj)=>{
          
          return (
            <HeroBanner img={obj?.image} onClick={()=>handleClick(obj.mainbanner_url)}> 
            <img src={obj?.image} alt="" />
            <MainHeading>
            {
              obj?.title
            }
            </MainHeading>
            <SubHeading>
            {
              obj?.content
            }
            </SubHeading>
           </HeroBanner>
          )
        })
      }
     </Slider>
    <SearchBar>
        <SearchInput>
        <input value={search} onChange={handleChange} type="text" placeholder="Search by Project name or Location" /> 
        </SearchInput>
        <SearchSubmitButton onClick={handleSearchSubmit} >
          <span>Find Plots</span>
        </SearchSubmitButton>
    </SearchBar>
    </Section>
  )
}

export default HeroPortion;

const Section = styled.div`
    margin: 10px auto 50px auto;
    display: flex;
    flex-direction: column;
    width: var(--section-width-lg);
    position: relative;
    .slick-dots{
      bottom:-55px;
    }
    .slick-dots li.slick-active button:before{
      color:var(--primary-cl) !important;
    }
    @media(max-width:580px){
      margin: 10px auto 70px auto;
    }
`
const HeroBanner = styled.div`
    width: 95%;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    @media(max-width:1024px){
      width:100%;
    }
    @media(max-width:580px){
    ${(props)=>props.img && `background-image: url(${props.img})`};
    height:75vh;
    }
   img{
    display: block;
    width: 100%;
    @media(max-width:580px){
    display: none;
   }
   }
`
const MainHeading = styled.h2`
    position: absolute;
    color: rgb(255, 255, 255);
    font-family: "popins_semibold";
    bottom: 110px;
    width: 16ch;
    left: 185px;
    @media(min-width: 2250px){
    font-size: 3rem;
    }
    @media(max-width: 1440px){
    left: 125px;
    }
    @media(max-width: 1024px){
    left: 31px;
    font-size: 23px;
    }
    @media(max-width: 768px){
    bottom:85px;
    }
    @media(max-width:580px){
    bottom:65px;
    }
    @media(max-width:315px){
      left: 11px;
    }
`
const SubHeading = styled.p`
    position: absolute;
    color: rgb(255, 255, 255);
    font-family: "popins_light";
    bottom: 45px;
    width: 35ch;
    left: 185px;
    @media(max-width:1440px){
      left: 125px;
    }
    @media(max-width: 1024px){
    left: 31px;
    font-size: 14px;
    }
    @media(max-width: 768px){
        bottom:25px;
    }
    @media(max-width:580px){
    display: none;
    }
`
const SearchBar = styled.div`
    background-color: rgb(255, 255, 255);
    bottom: -30px;
    position: absolute;
    width: 80%;
    margin: 0px auto;
    padding: 13px 20px;
    border: 1px solid var(--secondary-cl);
    display: flex;
    border-radius: 12px;
    left: 130px;

    @media(min-width:2000px){
    padding: 20px;
    }

    @media(max-width: 1024px){
    left: 65px;
    width:85%;
    }

    @media(max-width:580px){
    padding:10px 0;
    flex-wrap: wrap;
    height: 100px;
    width:90% !important;
    left: 20px !important;
    bottom: -50px !important;
    }
    @media(max-width:425px){
      width: 95% !important;
      left: 10px !important;
    }
    @media(max-width:315px){
      width: 98% !important;
      left: 3px !important;
    }
    `
const SearchInput = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media(max-width:580px){
    width: 100%;
    margin: 5px 0px;
    margin-left: 2px;
  }
  /* &::after{
    content: "";
    display: block;
    position: absolute;
    width: 17px;
    top: 12px;
    left: 25px;
    height: 100%;
    background-image: url(${searchIcon});
    margin: 10px 0px;
    background-repeat: no-repeat;
    color: var(--secondary-cl);
    @media(max-width:580px){
      width: 15px;
      top: 11px;
      left: 12px;
    }
  } */
  input{
    border: none;
    outline: none;
    width: 100%;
    background-image: url(${searchIcon});
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: 8px center ;
    padding-left: 30px;
    &::placeholder{
      font-family: 'popins_regular';
      opacity: .8;
      font-size: 14px;
      @media(max-width:580px){
       font-size: 12px;
      }
    }
    @media(max-width:425px){
      background-position: 10px center;
      margin-left: 10px;
    }
  }
`
const LocationDropdown = styled.div`
    width: 20%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width:580px){
    width:45%;
    }
    select{
        width: max-content;
        outline: none;
        border: none;
        color: var(--secondary-cl);
        width: 100%;
    }
`
const SearchSubmitButton = styled.button`
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  background-color: var(--primary-cl);
  color: #fff;
  border: none;
  font-family: 'popins_regular';
  @media(max-width:580px){
    width:90%;
    margin:0 auto;
    padding: 2px;
  }
  @media(max-width:425px){
     margin:10px auto;
  }
`
